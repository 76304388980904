<template>
  <div>
    <!-- ---------------------- Mission Progress ---------------------- -->
    <!-- <v-snackbar v-model="missionInProgress.active" v-if="!missionComplete.active" class="mission--progress" timeout="-1" top right width="400" style="top: 80px" :multi-line="false" transition="scroll-x-transition" color="white" light>
      <div class="d-flex flex-row align-center">
        <v-btn icon dark color="blue" x-large class="mr-4">
          <v-icon color="blue" x-large>mdi-progress-check</v-icon>
        </v-btn>
        <div class="flex-1 d-flex flex-column">
          <div class="subtitle-1 blue--text font-weight-bold">Current Mission</div>
          <div class="body-2">
            {{  missionInProgress.description }}
          </div>
          <div class="caption grey--text" v-if="missionInProgress.target && missionInProgress.multi">
            ({{  missionInProgress.progress }} / {{  missionInProgress.target }})
          </div>
        </div>
        <v-btn icon @click="missionInProgress.active=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar> -->
    <!-- ---------------------- Mission Complete ---------------------- -->
    <v-snackbar v-model="missionComplete.active" class="mission--complete" timeout="5000" top right width="400" :multi-line="false" transition="scroll-x-transition" color="white" light>
      <div class="d-flex flex-row align-center">
        <v-btn icon dark color="green" x-large class="mr-4">
          <v-icon color="green" x-large>mdi-medal-outline</v-icon>
        </v-btn>
        <div class="flex-1 d-flex flex-column">
          <div class="subtitle-1 green--text font-weight-bold">Completed Mission</div>
          <div class="body-2">{{ missionComplete.description }}</div>
        </div>
        <v-btn icon @click="progressState=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { getFirestore, collection, getDocs } from 'firebase/firestore'
export default {
  name: 'MissionProgress',
  props: ['user'],
  data: () => ({
    missions: [],
    missionInProgress: { active: false },
    missionComplete: { active: false },
  }),
  created() {
    this.checkMissions()
  },
  methods: {
    checkMissions() {
      // if( !this.user || !this.user.blueprint || !this.user.blueprint.length) return 
      console.log('Checking missions...')
      if (this.missions.length == 0) this.loadMissions()
      // Compare user missions to mission list

      // ------------ Initialize completed user missions ------------
      // The user profile mission array is a list of completed mission IDs
      if (this.user.profile.missions === undefined) this.user.profile.missions = []
      // Reset the value of the missionInProgress array
      this.missionInProgress = { active: false }
      // ------------ Check for any new missions ------------
      for (let missionIndex = 0; missionIndex < this.missions.length; missionIndex++) {
        if (this.missions[missionIndex].level <= this.user.profile.level) { // Check if mission is available
          // ------ Valid mission for this user ------
          let mission = this.missions[missionIndex]
          // ------ Check if mission already completed ------
          // search for mission id in user profile mission array
          if (this.user.profile.missions.indexOf(mission.id) == -1) {
            // ------ Mission not completed ------
            // We found a valid mission that has not been completed
            // ------ Calculate Progress ------
            var progress = 0
            switch (mission.trigger) {
              case 'get-started': // Mission 1
                progress = this.user.profile.level
                break
              case 'category-count': // Mission 4
                progress = this.user.blueprint.length
                break
              case 'one-priority-per-category': // Mission 5
                this.user.blueprint.forEach( category => {
                  if (category.priorities.length > 0 && category.priorities[0].edit == false) progress++
                })
                break
              case 'one-goal-per-priority': // Mission 6
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    if (priority.goals.length > 0) progress++
                  })
                })
                break
              case 'focus-count': // Mission 7
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      if (goal.focus) progress++
                    })
                  })
                })
                break
              case 'goal-action-count': // Mission 8
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      if (goal.actions == undefined) console.log(goal)
                      goal.actions.forEach( action => {
                        if (action.edit == false) progress++
                      })
                    })
                  })
                })
                break
              case 'complete-action': // Mission 9
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      goal.actions.forEach( action => {
                        if (action.complete == true) progress++
                      })
                    })
                  })
                })
                break
              case 'complete-goal': // Mission 10
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      if (goal.completed == true) progress++
                    })
                  })
                })
                break
              case 'heroic-action': // Mission 11
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      goal.actions.forEach( action => {
                        if (action.edit == false && action.difficulty == 'heroic' && action.target && action.reward) progress++
                      })
                    })
                  })
                })
                break
              case 'progress-heroic-action': // Mission 12
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      goal.actions.forEach( action => {
                        if (action.edit == false && action.difficulty == 'heroic' && action.target && action.reward) progress = action.progress.current
                      })
                    })
                  })
                })
                break
              case 'progress-designer': // Mission 13
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      if (goal.focus && goal.actions.length) {
                        if(goal.actions[0].progress.selected) progress++
                      }
                    })
                  })
                })
                break
              case 'progress-complete': // Mission 14
                this.user.blueprint.forEach( category => {
                  category.priorities.forEach( priority => {
                    priority.goals.forEach( goal => {
                      if (goal.focus && goal.actions.length) {
                        if(goal.actions[0].progress.selected == goal.actions[0].progress.finish) progress++
                        if(goal.actions[0].progress.selected == goal.actions[0].progress.step) progress++
                      }
                    })
                  })
                })
                console.log('progress-complete', progress)
                break
              case 'progress-configuration': // Mission 16
                
                break
            }
            console.log('Mission Progress', mission, progress)
            // ------ Check if mission is complete ------
            if (progress >= mission.target) {
              console.log('Mission complete!!', mission)
              // ------ Mission Complete! ------
              this.$emit('missionComplete', mission)
              // ------ Display a notification ------
              this.missionComplete = {
                active: true,
                description: mission.description,
              }
              // after 5 seconds go to dashboard
              // setTimeout( () => {
                this.go('Dashboard')
              // }, 5000)
            } else {
              // ------ Mission In Progress ------
              // Display mission progress if on the correct page
              if (mission.route == this.$router.currentRoute.name) {
                this.missionInProgress = {
                  active: true,
                  description: mission.description,
                  progress: progress,
                  target: mission.target,
                }
              } else {
                // Mission in progress, but not on the correct page
              }
            }
          }
        }
      }
    },
    loadMissions() {
      let db = getFirestore()
      let missionRef = collection(db, 'missions')
      getDocs(missionRef).then( querySnapshot => {
        querySnapshot.forEach( doc => {
          let mission = doc.data()
          mission.id = doc.id
          this.missions.push(mission)
        })
        console.log('Loaded missions', this.missions)
        this.checkMissions()
      })
    },
  },
  watch: {
    user() {
      this.checkMissions()
    },
    '$route'() {
      this.checkMissions()
    }
  }

}
</script>
<style>
  .mission--progress .v-snack__content {
    border-left: 6px solid #2296f3;
  }
  .mission--complete .v-snack__content {
    border-left: 6px solid green;
  }
</style>
