import Vue from 'vue'
import Vuex from 'vuex'
// import { getFirestore, doc, onSnapshot, setDoc, updateDoc, collection, getDocs } from 'firebase/firestore'

Vue.use(Vuex)

// const db = getFirestore()

export default new Vuex.Store({
  state: {
    user: {},
    colours: {
      'white': {
        name: 'White',
        class: 'white black--text',
        text: {
          'light': 'black',
          'medium': '#607D8B',
          'dark': 'white',
          'class': 'white--text'
        },
        hex: {
          'medium': '#607D8B',
          'lighten-5': '#ECEFF1',
          'lighten-4': '#CFD8DC',
          'lighten-3': '#B0BEC5',
          'lighten-2': '#90A4AE',
          'lighten-1': '#78909C',
          'darken-1': '#546E7A',
          'darken-2': '#455A64',
          'darken-3': '#37474F',
          'darken-4': '#263238',
          'accent-1': '#718792',
          'accent-2': '#4E5A65',
          'accent-3': '#414A4C',
          'accent-4': '#373E42',
        },
        //   { name: 'White',  medium: '#ECEFF1', light: '#B0BEC5', dark: '#263238', icon: '', mediumClass: 'white', lightClass: 'white-light', darkClass: 'black', lightText: 'black', darkText: 'black', colourText: 'blue-grey--text'},
      },
      'yellow': {
        name: 'Yellow',
        class: 'amber black--text',
        text: {
         'light': 'black',
         'medium': '#FFC107',
         'dark': 'white',
         'class': 'amber--text'
        },
        hex: {
          'medium': '#FFC107',
          'lighten-5': '#FFF8E1',
          'lighten-4': '#FFECB3',
          'lighten-3': '#FFE082',
          'lighten-2': '#FFD54F',
          'lighten-1': '#FFCA28',
          'darken-1': '#FFB300',
          'darken-2': '#FFA000',
          'darken-3': '#FF8F00',
          'darken-4': '#FF6F00',
          'accent-1': '#FFE57F',
          'accent-2': '#FFD740',
          'accent-3': '#FFC400',
          'accent-4': '#FFAB00',
        },
      },
      // 'yellow': {
      //   name: 'Yellow',
      //   class: 'yellow black--text',
      //   text: {
      //     'light': 'black',
      //     'medium': '#FFEB3B',
      //     'dark': 'white',
      //     'class': 'yellow--text'
      //   },
      //   hex: {
      //     'medium': '#FFEB3B',
      //     'lighten-5': '#FFFDE7',
      //     'lighten-4': '#FFF9C4',
      //     'lighten-3': '#FFF59D',
      //     'lighten-2': '#FFF176',
      //     'lighten-1': '#FFEE58',
      //     'darken-1': '#FDD835',
      //     'darken-2': '#FBC02D',
      //     'darken-3': '#F9A825',
      //     'darken-4': '#F57F17',
      //     'accent-1': '#FFFF8D',
      //     'accent-2': '#FFFF00',
      //     'accent-3': '#FFEA00',
      //     'accent-4': '#FFD600',
      //   },
      //   //   { name: 'Yellow', medium: '#FFEB3B', light: '#FFF59D', dark: '#F9A825', icon: '&#x1F7E8;', mediumClass: 'yellow', lightClass: 'yellow lighten-3', darkClass: 'yellow darken-3', lightText: 'black', darkText: 'black  ', colourText: 'yellow--text text--darken-3'},
      // },
      'orange': {
        name: 'Orange',
        class: 'orange black--text',
        text: {
          'light': 'black',
          'medium': '#FF9800',
          'dark': 'white',
          'class': 'orange--text'
        },
        hex: {
          'medium': '#FF9800',
          'lighten-5': '#FFF3E0',
          'lighten-4': '#FFE0B2',
          'lighten-3': '#FFCC80',
          'lighten-2': '#FFB74D',
          'lighten-1': '#FFA726',
          'darken-1': '#FB8C00',
          'darken-2': '#F57C00',
          'darken-3': '#EF6C00',
          'darken-4': '#E65100',
          'accent-1': '#FFD180',
          'accent-2': '#FFAB40',
          'accent-3': '#FF9100',
          'accent-4': '#FF6D00',
        },
        //   { name: 'Orange', medium: '#FF9800', light: '#FFCC80', dark: '#EF6C00', icon: '&#x1F7E7;', mediumClass: 'orange', lightClass: 'orange lighten-3', darkClass: 'orange darken-3', lightText: 'black', darkText: 'white', colourText: 'orange--text'},
      },
      'green': {
        name: 'Green',
        class: 'green black--text',
        text: {
          'light': 'black',
          'medium': '#4CAF50',
          'dark': 'white',
          'class': 'green--text'
        },
        hex: {
          'medium': '#4CAF50',
          'lighten-5': '#E8F5E9',
          'lighten-4': '#C8E6C9',
          'lighten-3': '#A5D6A7',
          'lighten-2': '#81C784',
          'lighten-1': '#66BB6A',
          'darken-1': '#43A047',
          'darken-2': '#388E3C',
          'darken-3': '#2E7D32',
          'darken-4': '#1B5E20',
          'accent-1': '#B9F6CA',
          'accent-2': '#69F0AE',
          'accent-3': '#00E676',
          'accent-4': '#00C853',
        },
        //   { name: 'Green',  medium: '#4CAF50', light: '#A5D6A7', dark: '#2E7D32', icon: '&#x1F7E9;', mediumClass: 'green', lightClass: 'green lighten-3', darkClass: 'green darken-3', lightText: 'black', darkText: 'white', colourText: 'green--text'},
      },
      'blue': {
        name: 'Blue',
        class: 'blue black--text',
        text: {
          'light': 'black',
          'medium': '#2196F3',
          'dark': 'white',
          'class': 'blue--text'
        },
        hex: {
          'medium': '#2196F3',
          'lighten-5': '#E3F2FD',
          'lighten-4': '#BBDEFB',
          'lighten-3': '#90CAF9',
          'lighten-2': '#64B5F6',
          'lighten-1': '#42A5F5',
          'darken-1': '#1E88E5',
          'darken-2': '#1976D2',
          'darken-3': '#1565C0',
          'darken-4': '#0D47A1',
          'accent-1': '#82B1FF',
          'accent-2': '#448AFF',
          'accent-3': '#2979FF',
          'accent-4': '#2962FF',
        },
        //   { name: 'Blue',   medium: '#2196F3', light: '#90CAF9', dark: '#1565C0', icon: '&#x1F7E6;', mediumClass: 'blue', lightClass: 'blue lighten-3', darkClass: 'blue darken-3', lightText: 'black', darkText: 'white', colourText: 'blue--text'},
      },
      'purple': {
        name: 'Purple',
        class: 'purple black--text',
        text: {
          'light': 'black',
          'medium': '#9C27B0',
          'dark': 'white',
          'class': 'purple--text'
        },
        hex: {
          'medium': '#9C27B0',
          'lighten-5': '#F3E5F5',
          'lighten-4': '#E1BEE7',
          'lighten-3': '#CE93D8',
          'lighten-2': '#BA68C8',
          'lighten-1': '#AB47BC',
          'darken-1': '#8E24AA',
          'darken-2': '#7B1FA2',
          'darken-3': '#6A1B9A',
          'darken-4': '#4A148C',
          'accent-1': '#EA80FC',
          'accent-2': '#E040FB',
          'accent-3': '#D500F9',
          'accent-4': '#AA00FF',
        },
        //   { name: 'Purple', medium: '#9C27B0', light: '#CE93D8', dark: '#6A1B9A', icon: '&#x1F7EA;', mediumClass: 'purple', lightClass: 'purple lighten-3', darkClass: 'purple darken-3', lightText: 'black', darkText: 'white', colourText: 'purple--text'},  
      },
      'brown': {
        name: 'Brown',
        class: 'brown black--text',
        text: {
          'light': 'black',
          'medium': '#795548',
          'dark': 'white',
          'class': 'brown--text'
        },
        hex: {
          'medium': '#795548',
          'lighten-5': '#EFEBE9',
          'lighten-4': '#D7CCC8',
          'lighten-3': '#BCAAA4',
          'lighten-2': '#A1887F',
          'lighten-1': '#8D6E63',
          'darken-1': '#6D4C41',
          'darken-2': '#5D4037',
          'darken-3': '#4E342E',
          'darken-4': '#3E2723',
        },
        //   { name: 'Brown',  medium: '#795548', light: '#BCAAA4', dark: '#4E342E', icon: '&#x1F7EB;', mediumClass: 'brown', lightClass: 'brown lighten-3', darkClass: 'brown darken-3', lightText: 'black', darkText: 'white', colourText: 'brown--text'},  
      },
      'black': {
        name: 'Black',
        class: 'blue-grey darken-4 white--text', // class: 'black white--text',
        text: {
          'light': 'black',
          'medium': 'black',
          'dark': 'white',
          'class': 'blue-grey--text'
        },
        hex: {
          'medium': '#263238',
          'lighten-4': '#d9d9d9', // '#757575',
          'lighten-3': '#616161',
          'lighten-2': '#424242',
          'lighten-1': '#212121',
          'darken-1': '#000000',
          'darken-2': '#000000',
          'darken-3': '#000000',
          'darken-4': '#000000',
      //     'darken-1': '#546E7A',
      //     'darken-2': '#455A64',
      //     'darken-3': '#37474F',
      //     'darken-4': '#263238',
          'accent-1': '#718792',
          'accent-2': '#4E5A65',
          'accent-3': '#414A4C',
          'accent-4': '#373E42',
        },
        //   { name: 'Black',  medium: '#000000', light: '#263238', dark: '#000000', icon: '&#x2B1B;',  mediumClass: 'black dark', lightClass: 'blue-grey darken-4 dark', darkClass: 'black dark', lightText: 'black', darkText: 'white', colourText: 'blue-grey--text'},
      },
      'red': {
        name: 'Red',
        class: 'red black--text',
        text: {
          'medium': 'black',
          'light': 'black',
          'medium': '#F44336',
          'dark': 'white',
          'class': 'red--text'
        },
        hex: {
          'medium': '#F44336',
          'lighten-5': '#FFEBEE',
          'lighten-4': '#FFCDD2',
          'lighten-3': '#EF9A9A',
          'lighten-2': '#E57373',
          'lighten-1': '#EF5350',
          'darken-1': '#E53935',
          'darken-2': '#D32F2F',
          'darken-3': '#C62828',
          'darken-4': '#B71C1C',
          'accent-1': '#FF8A80',
          'accent-2': '#FF5252',
          'accent-3': '#FF1744',
          'accent-4': '#D50000',
        },
        //   { name: 'Red',    medium: '#F44336', light: '#EF9A9A', dark: '#C62828', icon: '&#x1F7E5;', mediumClass: 'red', lightClass: 'red lighten-3', darkClass: 'red darken-3', lightText: 'black', darkText: 'white', colourText: 'red--text'},
      },
      // ==========================================================
      // 'pink': {
      //   name: 'Pink',
      //   class: 'pink black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#E91E63',
      //    'dark': 'white',
      //    'class': 'pink--text'
      //   },
      //   hex: {
      //     'medium': '#E91E63',
      //     'lighten-5': '#FCE4EC',
      //     'lighten-4': '#F8BBD0',
      //     'lighten-3': '#F48FB1',
      //     'lighten-2': '#F06292',
      //     'lighten-1': '#EC407A',
      //     'darken-1': '#D81B60',
      //     'darken-2': '#C2185B',
      //     'darken-3': '#AD1457',
      //     'darken-4': '#880E4F',
      //     'accent-1': '#FF80AB',
      //     'accent-2': '#FF4081',
      //     'accent-3': '#F50057',
      //     'accent-4': '#C51162',
      //   },
      // },
      // 'deep-purple': {
      //   name: 'Deep Purple',
      //   class: 'deep-purple black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#673AB7',
      //    'dark': 'white',
      //    'class': 'deep-purple--text'
      //   },
      //   hex: {
      //     'medium': '#673AB7',
      //     'lighten-5': '#EDE7F6',
      //     'lighten-4': '#D1C4E9',
      //     'lighten-3': '#B39DDB',
      //     'lighten-2': '#9575CD',
      //     'lighten-1': '#7E57C2',
      //     'darken-1': '#5E35B1',
      //     'darken-2': '#512DA8',
      //     'darken-3': '#4527A0',
      //     'darken-4': '#311B92',
      //     'accent-1': '#B388FF',
      //     'accent-2': '#7C4DFF',
      //     'accent-3': '#651FFF',
      //     'accent-4': '#6200EA',
      //   },
      // },
      // 'indigo': {
      //   name: 'Indigo',
      //   class: 'indigo black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#3F51B5',
      //    'dark': 'white',
      //    'class': 'indigo--text'
      //   },
      //   hex: {
      //     'medium': '#3F51B5',
      //     'lighten-5': '#E8EAF6',
      //     'lighten-4': '#C5CAE9',
      //     'lighten-3': '#9FA8DA',
      //     'lighten-2': '#7986CB',
      //     'lighten-1': '#5C6BC0',
      //     'darken-1': '#3949AB',
      //     'darken-2': '#303F9F',
      //     'darken-3': '#283593',
      //     'darken-4': '#1A237E',
      //     'accent-1': '#8C9EFF',
      //     'accent-2': '#536DFE',
      //     'accent-3': '#3D5AFE',
      //     'accent-4': '#304FFE',
      //   },
      // },
      // 'light-blue': {
      //   name: 'Light Blue',
      //   class: 'light-blue black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#03A9F4',
      //    'dark': 'white',
      //    'class': 'light-blue--text'
      //   },
      //   hex: {
      //     'medium': '#03A9F4',
      //     'lighten-5': '#E1F5FE',
      //     'lighten-4': '#B3E5FC',
      //     'lighten-3': '#81D4FA',
      //     'lighten-2': '#4FC3F7',
      //     'lighten-1': '#29B6F6',
      //     'darken-1': '#039BE5',
      //     'darken-2': '#0288D1',
      //     'darken-3': '#0277BD',
      //     'darken-4': '#01579B',
      //     'accent-1': '#80D8FF',
      //     'accent-2': '#40C4FF',
      //     'accent-3': '#00B0FF',
      //     'accent-4': '#0091EA',
      //   },
      // },
      // 'cyan': {
      //   name: 'Cyan',
      //   class: 'cyan black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#00BCD4',
      //    'dark': 'white',
      //    'class': 'cyan--text'
      //   },
      //   hex: {
      //     'medium': '#00BCD4',
      //     'lighten-5': '#E0F7FA',
      //     'lighten-4': '#B2EBF2',
      //     'lighten-3': '#80DEEA',
      //     'lighten-2': '#4DD0E1',
      //     'lighten-1': '#26C6DA',
      //     'darken-1': '#00ACC1',
      //     'darken-2': '#0097A7',
      //     'darken-3': '#00838F',
      //     'darken-4': '#006064',
      //     'accent-1': '#84FFFF',
      //     'accent-2': '#18FFFF',
      //     'accent-3': '#00E5FF',
      //     'accent-4': '#00B8D4',
      //   },
      // },
      // 'teal': {
      //   name: 'Teal',
      //   class: 'teal black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#009688',
      //    'dark': 'white',
      //    'class': 'teal--text'
      //   },
      //   hex: {
      //     'medium': '#009688',
      //     'lighten-5': '#E0F2F1',
      //     'lighten-4': '#B2DFDB',
      //     'lighten-3': '#80CBC4',
      //     'lighten-2': '#4DB6AC',
      //     'lighten-1': '#26A69A',
      //     'darken-1': '#00897B',
      //     'darken-2': '#00796B',
      //     'darken-3': '#00695C',
      //     'darken-4': '#004D40',
      //     'accent-1': '#A7FFEB',
      //     'accent-2': '#64FFDA',
      //     'accent-3': '#1DE9B6',
      //     'accent-4': '#00BFA5',
      //   },
      // },
      // 'light-green': {
      //   name: 'Light Green',
      //   class: 'light-green black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#8BC34A',
      //    'dark': 'white',
      //    'class': 'light-green--text'
      //   },
      //   hex: {
      //     'medium': '#8BC34A',
      //     'lighten-5': '#F1F8E9',
      //     'lighten-4': '#DCEDC8',
      //     'lighten-3': '#C5E1A5',
      //     'lighten-2': '#AED581',
      //     'lighten-1': '#9CCC65',
      //     'darken-1': '#7CB342',
      //     'darken-2': '#689F38',
      //     'darken-3': '#558B2F',
      //     'darken-4': '#33691E',
      //     'accent-1': '#CCFF90',
      //     'accent-2': '#B2FF59',
      //     'accent-3': '#76FF03',
      //     'accent-4': '#64DD17',
      //   },
      // },
      // 'lime': {
      //   name: 'Lime',
      //   class: 'lime black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#CDDC39',
      //    'dark': 'white',
      //    'class': 'lime--text'
      //   },
      //   hex: {
      //     'medium': '#CDDC39',
      //     'lighten-5': '#F9FBE7',
      //     'lighten-4': '#F0F4C3',
      //     'lighten-3': '#E6EE9C',
      //     'lighten-2': '#DCE775',
      //     'lighten-1': '#D4E157',
      //     'darken-1': '#C0CA33',
      //     'darken-2': '#AFB42B',
      //     'darken-3': '#9E9D24',
      //     'darken-4': '#827717',
      //     'accent-1': '#F4FF81',
      //     'accent-2': '#EEFF41',
      //     'accent-3': '#C6FF00',
      //     'accent-4': '#AEEA00',
      //   },
      // },
      // 'amber': {
      //   name: 'Amber',
      //   class: 'amber black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#FFC107',
      //    'dark': 'white',
      //    'class': 'amber--text'
      //   },
      //   hex: {
      //     'medium': '#FFC107',
      //     'lighten-5': '#FFF8E1',
      //     'lighten-4': '#FFECB3',
      //     'lighten-3': '#FFE082',
      //     'lighten-2': '#FFD54F',
      //     'lighten-1': '#FFCA28',
      //     'darken-1': '#FFB300',
      //     'darken-2': '#FFA000',
      //     'darken-3': '#FF8F00',
      //     'darken-4': '#FF6F00',
      //     'accent-1': '#FFE57F',
      //     'accent-2': '#FFD740',
      //     'accent-3': '#FFC400',
      //     'accent-4': '#FFAB00',
      //   },
      // },
      // 'deep-orange': {
      //   name: 'Deep Orange',
      //   class: 'deep-orange black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#FF5722',
      //    'dark': 'white',
      //    'class': 'deep-orange--text'
      //   },
      //   hex: {
      //     'medium': '#FF5722',
      //     'lighten-5': '#FBE9E7',
      //     'lighten-4': '#FFCCBC',
      //     'lighten-3': '#FFAB91',
      //     'lighten-2': '#FF8A65',
      //     'lighten-1': '#FF7043',
      //     'darken-1': '#F4511E',
      //     'darken-2': '#E64A19',
      //     'darken-3': '#D84315',
      //     'darken-4': '#BF360C',
      //     'accent-1': '#FF9E80',
      //     'accent-2': '#FF6E40',
      //     'accent-3': '#FF3D00',
      //     'accent-4': '#DD2C00',
      //   },
      // },
      // 'blue-grey': {
      //   name: 'Blue Grey',
      //   class: 'blue-grey black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#607D8B',
      //    'dark': 'white',
      //    'class': 'blue-grey--text'
      //   },
      //   hex: {
      //     'medium': '#607D8B',
      //     'lighten-5': '#ECEFF1',
      //     'lighten-4': '#CFD8DC',
      //     'lighten-3': '#B0BEC5',
      //     'lighten-2': '#90A4AE',
      //     'lighten-1': '#78909C',
      //     'darken-1': '#546E7A',
      //     'darken-2': '#455A64',
      //     'darken-3': '#37474F',
      //     'darken-4': '#263238',
      //     'accent-1': '#718792',
      //     'accent-2': '#4E5A65',
      //     'accent-3': '#414A4C',
      //     'accent-4': '#373E42',
      //   },
      // },
      // 'grey': {
      //   name: 'Grey',
      //   class: 'grey black--text',
      //   text: {
      //    'light': 'black',
      //    'medium': '#9E9E9E',
      //    'dark': 'white',
      //    'class': 'grey--text'
      //   },
      //   hex: {
      //     'medium': '#9E9E9E',
      //     'lighten-5': '#FAFAFA',
      //     'lighten-4': '#F5F5F5',
      //     'lighten-3': '#EEEEEE',
      //     'lighten-2': '#E0E0E0',
      //     'lighten-1': '#BDBDBD',
      //     'darken-1': '#757575',
      //     'darken-2': '#616161',
      //     'darken-3': '#424242',
      //     'darken-4': '#212121',
      //   },
      // },
    },
  },
  getters: {
    colours: state => {
      return state.colours
    },
    colour: (state) => (colour) => {
      return state.colours[colour]
    },
    border: (state) => (colour) => {
      return state.colours[colour]
    },
  },
  mutations: {
    // setUser(state, { uid, user }) {
    //   state.users[uid] = user;
    // }
  },
  actions: {
    // async saveUser({ commit }, { uid, user }) {
    //   const usersCollection = collection(db, 'users')
    //   const userRef = doc(usersCollection, uid);
    //   await setDoc(userRef, user, { merge: true })
    //   commit('setUser', { uid, user });
    // }
  },
  modules: {
  }
})
