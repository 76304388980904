<template>
  <v-app-bar dense fixed color="secondary">
    <v-app-bar-nav-icon class="d-flex d-md-none" @click="$emit('open')"></v-app-bar-nav-icon>
    <!-- ------ Logo ------ -->
    <img class="logo" src="@/resources/images/logos/logo@2x.png" @click="user ? go('Dashboard') : go('Landing')" />
    <!-- ------ Main Menu ------ -->
    <v-tabs align-with-title v-model="selectedTab" v-if="user" class="d-none d-md-flex flex-1">
      <v-tab class="ghost-tab"></v-tab>
      <v-tab v-for="section in sections" :key="section.index" @click="section.route ? go(section.route) : null">
        {{ section.title }}
      </v-tab>
    </v-tabs>
    <!-- ------ Spacer ------ -->
    <v-spacer></v-spacer>
    <!-- ------ Actions ------ -->
    <div class="d-flex flex-row align-center">
      <!-- ------ Unauthenticated Actions ------ -->
      <div v-if="!user">
        <v-btn text @click="go('Login')">Login</v-btn>
        <v-btn class="ml-4" color="primary" @click="go('Signup')">Get started</v-btn>
      </div>
      <!-- ------ Authenticated Actions ------ -->
      <v-avatar v-if="user && user.profile" size="25">
        <img :src="user.profile.avatar" alt="User profile picture">
      </v-avatar>
      <div v-if="user && user.profile" class="username flex-1 d-none d-sm-flex">
        {{ user.profile.name }}
      </div>
      <v-menu v-if="user && user.profile" offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img referrerpolicy="no-referrer" :src="user.profile.avatar" alt="Profile Image">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user.profile.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.profile.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="go({name: 'Settings'})">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- ------ Sub Menu ------ -->
    <template v-slot:extension v-if="user && sections && selectedTab && sections[selectedTab-1] && sections[selectedTab-1].subsections.length">
      <v-tabs align-with-title dark style="background: black" v-model="selectedSubTab">
        <v-tab class="ghost-tab"></v-tab>
        <v-tab v-for="(section,index) in sections[selectedTab-1].subsections" :key="index" @click="go(section.route)" :class="user.profile.level >= section.level ? 'hide' : 'd-none'">
          {{ section.title }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Header',
  props: ['user','sections'],
  data() {
    return {
      selectedTab: null,
      selectedSubTab: null,
    }
  },
  created() {
    this.routeChange()
  },
  methods: {
    logout() {
      this.$emit('logout')
    },
    routeChange() {      
      this.sections.forEach((section,index) => {
        // console.log('checking', section.route)
        if (section.route == this.$route.name) {
          // Section Matched
          this.selectedTab = index+1
          this.selectedSubTab = null
        } else {
          section.subsections.forEach((subsection,subindex) => {
            // console.log('checking', subsection.route)
            if (subsection.route == this.$route.name) {
              // Sub Section Matched
              this.selectedTab = index+1
              this.selectedSubTab = subindex+1
            }
          })
        }
      })
    }
  },
  watch: {
    $route() {
      this.routeChange()
    },
    selectedTab() {
      if( this.user && this.sections && this.selectedTab && this.sections[this.selectedTab-1] && this.sections[this.selectedTab-1].subsections.length) {
        this.$emit('offset', true)
      } else {
        this.$emit('offset', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    height: 48px;
    cursor: pointer;
  }
  .ghost-tab {
    background: red !important; 
    min-width: 0;
    padding: 0;
  }
  .username {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    font-size: 0.875rem;
    font-weight: 500;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: normal;
    min-width: 90px;
    max-width: 360px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: none;
    transition: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
</style>
