<template>
  <div class="tour">
    <!-- ---------------------- Level 1: Getting Started ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 1">
      <v-card-title class="text-h5 mb-0">Welcome</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions>
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Dear {{ user.profile.name }},
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Welcome to Life OS! My name is Aaron and I'm an engineer at Life OS. We're so excited to have you on board.
          Seriously. Our community is made from like-minded individuals who want to live a life of purpose and meaning just like you.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Our goal is simple: build tools which will help you make daily progress on that which is important to you.
          To help you get started we've put together a guided tour that will unlock new tools as you progress. 
          This will take about an hour so get comfortable and let me know when you are ready.
        </div>
        <div><v-btn dark class="mt-4" @click="startTour()">Start the tour</v-btn></div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 2: Footer Player ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 2">
      <v-card-title class="text-h5 mb-0">Getting Started</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions>
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Awesome.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          You've now unlocked the footer toolbar. This handy set of tools sits at the bottom of every page for easy access.
          On the bar you'll find a few tools to help you get started. You are currently seeing the Mission Player. 
          More will be added as we go along. To switch between tools, simply select the icon.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          For the next mission you'll have to find and play the first lesson video
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 3: Feedback ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 3">
      <v-card-title class="text-h5 mb-0">Feedback</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions>
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Perfect! Now there is just one more housekeeping item I'd like to show you before we dig into the life tools.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          The feedback player. We are constantly striving to improve the platform and cannot do it without your support.
          If you have any comments, ideas, bug reports, or questions please let us know! Together we can make this the best platform for personal growth.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          For this mission you just have to locate the feedback player
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 4: Blueprint ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 4">
      <v-card-title class="text-h5 mb-0">Blueprint</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions>
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Excellent! Now we're ready to dig into the life tools.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          At its core, Life OS is a platform to help you make daily progress on your goals. 
          It sounds simple but could you list the goals you want to achieve in the next few years?
          What about the goals you've achieved in the last few years? Are you clear on your life purpose?
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          These are difficult questions to answer. If you can't then you would be in good company.
          In my own life, I was always busy and working hard but had little progress to show for it.
          I would try to set goals but would eventually lose track. I would jump from one thing to the next without a clear objective. 
          I was too busy to celebrate victories. Can you relate?
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          After years of experimenting, I realized the problem was that I was trying to start with goals.
          It doesn't help that the term "goal" has become ubiquitous and lost its meaning.
          In Life OS, <strong>a goal is a measurable outcome that we wish to achieve through hard work</strong>.
          Therefore, a goal is simply a means to an end and not a means in itself. The pursuit of goals must tie into a higher ideal.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Some people call this higher ideal: purpose, meaning, ikigai, or eudaimonia. 
          In Life OS, we simply call this <strong>your blueprint</strong>. You will start your blueprint by creating categories.
          There is no right or wrong way to create categories as long as they are meaningful to you. Here are some ideas to get you started:
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          <div><strong>Areas:</strong> each category represents an area of your life</div>
          <div class="caption">For example: family, health, career, hobby, etc.</div>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          <div><strong>Virtues:</strong> ideals you seek to embody</div>
          <div class="caption">For example: courage, love, responsibility, wisdom, integrity, etc.</div>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          <div><strong>Roles:</strong> each category represents a role you play in life</div>
          <div class="caption">For example: father, son, friend, athlete, leader, etc.</div>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Personally, I use a combination of all of the above. So experiment and see what works for you.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          For this next mission, you will create two categories (for example: health, virtues)
          <div><v-btn @click="go('Blueprint')" color="primary" class="mt-4">Go to Blueprint</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 5: Priorities ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 5">
      <v-card-title class="text-h5 mb-0">Priorities</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions>
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Now that we have some basic categories in your blueprint, we can start to set priorities.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          <strong>Priorities are the most important aspects of each category.</strong> They are not goals or outcomes that you can achieve.
          So if you find your mind going to an outcome reflect on where that outcome would fit into at a higher level.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          For example, say you love to write and you really wanted to write a book. 
          You might have a category called Ikigai, Passions, Hobbies, Author, etc. 
          You could then list Writing as a priority under that category.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Here are some more examples:<br>
          <div><strong>Health Category</strong> Priorities: nutrition, exercise, mind</div>
          <div><strong>Family Category</strong> Priorities: Tawfik, Taym</div>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          I would recommend trying to limit the number of priorities per category to 2-3. 
          If you end up with more than that consider splitting them into new categories.
          This will help with tracking.
          So, take your time with this step to reflect on these important aspects of your life. 
          But remember, this doesn't have to be perfect as you can always change it later. 
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          For this next mission, you will create at least one priority per category
          <div><v-btn @click="go('Blueprint')" color="primary" class="mt-4">Go to Blueprint</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 6: Goals ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 6">
      <v-card-title class="text-h5 mb-0">Goals</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions>
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          We are now ready to start adding goals to our priorities
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          As I mentioned earlier a goal is a measurable outcome that we wish to achieve through hard work.
          So we need to be careful to ensure that they are measurable. Some classic examples would be: "lose weight" vs "lose 10 pounds", "read more" vs "read 3 books".
          Some people also like to add deadlines to their goals, but I prefer to keep them open-ended. That is up to you.
          However, I would highly recommend setting goals which can be completed in a few months or less so that we are celebrating victories throughout the year.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Feel free to add multiple goals if you can think of them. It is a great way to keep ideas and plans safe while reducing cognitive load.
          Any time I have a new idea I go straight to my blueprint and add it to the appropriate priority.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To continue our previous examples:<br>
          Category: <strong>Health</strong> > Priority: <strong>Strength</strong> > Goal: <strong>20 pull-ups</strong>, Goal: <strong>50 push-ups</strong><br>
          Category: <strong>Passion</strong> > Priority: <strong>Writing</strong> > Goal: <strong>Draft chapter one</strong><br>
          Category: <strong>Family</strong> > Priority: <strong>Tawfik</strong> > Goal: <strong>"A" in Mathematics</strong>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          You will now be able to open each priority and add goals. Add at least one goal per priority to complete this mission.
          <div><v-btn @click="go('Blueprint')" color="primary" class="mt-4">Go to Blueprint</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 7: Focus ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 7">
      <v-card-title class="text-h5 mb-0">Focus</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions>
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          This is a great start, but we need to make sure that we are focusing on the right things.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Our priorities are likely to include many different goals. Trying to pursue multiple goals within the same priority tends to leads to stress and imbalance.
          The objective here is to make consistent and balanced progress across all of our priorities.
          The focus feature allows us to select a single goal for each priority at a time.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          In our previous example we were writing a book. Our goals might look like this:<br>
          Category: <strong>Passion</strong> > Priority: <strong>Writing</strong> > Goal: <strong>Draft chapter 1</strong> *focus*<br>
          Category: <strong>Passion</strong> > Priority: <strong>Writing</strong> > Goal: <strong>Draft chapter 2</strong><br>
          Category: <strong>Passion</strong> > Priority: <strong>Writing</strong> > Goal: <strong>Draft chapter 3</strong>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Clearly, we are going to focus on the first chapter. While it is possible to pursue multiple goals at once, you'll find a measured approach much more sustainable.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To complete this mission, select a single goal for each priority to focus on.
          <div><v-btn @click="go('Blueprint')" color="primary" class="mt-4">Go to Blueprint</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 8: Actions ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 8">
      <v-card-title class="text-h5 mb-0">Actions</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Congratualtions! Let's take a moment to appreciate what you've been able to accomplish here.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          You now have a simple blueprint for your life. 
          I hope you can see how expanding on this foundation will provide clarity not only on what is important but also what you ought to be doing.
          That leads into the next step - taking action.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Now that we have a list of focused goals, the next step is to break them down into actionable steps.
          For this you have just unlocked the "Goals" tool. Here we remove all of the clutter so that you can see a snapshot of what you are working towards.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          In order to make progress on our goals it is important to break them down into actionable steps.
          An action can be whatever you wish, but it is recommended to keeping each action as small as possible.
          Personally, I make sure any action can be done is less than a few hours.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Going back to our previous examples:<br>
          Category: <strong>Health</strong><br>
          Priority: <strong>Strength</strong><br>
          Focus Goal: <strong>20 pull-ups</strong><br>
          Actions: <strong>Buy a bar</strong>, <strong>Install bar</strong>, <strong>Do pullups</strong>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          You can see in our example, that we aren't listing every single action but just enough to get us started.
          This comes down to personal preferene, personally, I adjust my actions regularly as I progress so I try not to overthink it initially.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          So checkout the new Goals page, select a goal, and add some actions.
          <div><v-btn @click="go('Goals')" color="primary" class="mt-4">Go to Goals</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 9: Complete Action ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 9">
      <v-card-title class="text-h5 mb-0">Actions</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Excellent.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          For this next mission, let's pretend that we have completed the work by setting an action to complete.
          This can be easily undone later if you would like to keep them.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          If you had multiple actions, you will see that as you complete one the next will automatically be selected.
          A record of all of your completed actions is kept so you can always go back and see what you have accomplished.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">  
          On the goals page, mark an action as complete
          <div><v-btn @click="go('Goals')" color="primary" class="mt-4">Go to Goals</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
  <!-- ---------------------- Level 10: Complete Goal ---------------------- -->
  <v-card v-if="user && user.profile && user.profile.level == 10">
      <v-card-title class="text-h5 mb-0">Actions</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Great!
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Once you have completed all of your actions for a particular goal, you can then mark the goal itself as complete.
          You can do this from the Blueprint page. 
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Note that completing all of the actions of a goal will not automatically complete the goal.
          The only way to complete a goal is to decide that you have achieved it.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          On the Blueprint page, mark a goal as complete
          <div><v-btn @click="go('Blueprint')" color="primary" class="mt-4">Go to Blueprint</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 11: Heroic Action ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 11">
      <v-card-title class="text-h5 mb-0">Actions</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Can you see the potential for keeping your life organized?
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          You might notice that sometimes the next action towards a goal is not so clearly defined.
          Sometimes you might not know exactly how to proceed. Sometimes it just unavoidably large.
          Sometimes we just get stuck or procrastinate.
          When this happens we change it to a "heroic action".
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          A heroic action allows you to make incremental progress towards a difficult action. 
          You can even set rewards, target effort, and mimimum effort.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          If we look at our previous example of doing pull-ups we had three actions:<br>
          1. Buy a pull-up bar<br>
          2. Install the pull-up bar<br>
          3. Do pull-ups<br>
          The first two items are clearly defined. But the third is way too abstract. 
          That is the kind of action that will get stuck on our to-do list, and is likely to eventually fall off.
          The solution is to convert it to a heroic action. By default, this will split it into 10 smaller actions.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          We can optionally define what each smaller action represents, the minimum target, and even set a reward.
          In our example it might look like this:<br>
          Target effort: <strong>1 set to failure</strong><br>
          Minimum effort: <strong>1 pull-up</strong><br>
          Reward: <strong>chocolate milkshake</strong>
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Visit the Goals page, select an action and convert it to a heroic action.
          <div><v-btn @click="go('Goals')" color="primary" class="mt-4">Go to Goals</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 12: Progress Heroic Action ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 12">
      <v-card-title class="text-h5 mb-0">Progress Heroic</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Excellent! By making use of that feature we can feel great about making progress on even our most troublesome actions.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          If you notice on the Goals page you now have a progress bar associated with the action.
          You can manually advance the progress bar by pressing the + button.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          As mentioned earlier, a heroic action has 10 steps by default. You can easily change this on the Goal configuration page to suit your needs.
          For example, if your goal was to save $5000 you could set each step to be $100 and change the total steps to 50.
          Then you'll have a beautiful progress bar to see exactly where you are at.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To pass this level, advance a heroic action bar by at least 5 steps.
          <div><v-btn @click="go('Goals')" color="primary" class="mt-4">Go to Goals</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 13: Progress ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 13">
      <v-card-title class="text-h5 mb-0">Progress Tool</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Nicely done! Things are starting to come together. 
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          However, there is one important thing missing.
          Humans operate on lists. We have tried to develop alternatives but always come to the same conclusion. Enter the Progress tool.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          The Progress tool is designed to be used every day as a replacement for your to-do list. 
          It is split up into three parts to help you win the day: designer, list, and debriefing.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To design your masterpiece day, you simply commit to actions you wish to achieve that day from the list of priority actions and todos.
          This is done by selecting the checkbox next to an action and pressing the "commit" button.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          This is much faster than attempting to write out a list and ensures you are always working on the most important things.
          Each item in your list can be traced back to a goal, a prority, and a purpose. How cool is that?
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Of course, you can also add to-do items which are tracked separately from your goals.
          This is useful for things like "buy groceries" or "call mom".
          This way you never lose track of the little things but the focus is on progressing the big picture.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          On this page you will also notice an energy bar. This provides a visual reminder not to overload your day. 
          What is more demoralizing than a todo list you can never finish? Our objective is to win the day.
          We do this by setting outselves up by matching our capacity with our commitments.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To complete this mission, commit to at least 2 priority actions.
          <div><v-btn @click="go('Progress')" color="primary" class="mt-4">Go to Progress</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 14: Complete Actions ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 14">
      <v-card-title class="text-h5 mb-0">Progress List</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Perfect. You have made the commitment to do those two actions towards your purpose.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          When you return to the Progress tool, you will notice that you are now on a different screen.
          This is the progression list where you will track your work for the day. 
          All of the noise is hidden so you will only see those actions that you have committed to.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          This screen works just like any other todo list. You simply check off items as you complete them.
          There is a progress bar to help you visualize your progress. 
          The feeling of winning the day is important as there will always be more things to do.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          If you need to make revisions you can use the "previous" button to return to the designer.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          This screen features customizable widgets which you may find useful. 
          Personally, when I am working from home I use the balance timer to ensure I am getting 1 hour of movement for every 2 hours of computer use. 
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To complete this mission, mark at least 2 priority actions as complete.
          <div><v-btn @click="go('Progress')" color="primary" class="mt-4">Go to Progress</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 15: Complete Actions ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 15">
      <v-card-title class="text-h5 mb-0">Reflection</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Useful right? Well, this next part is my favourite!
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          In order to win the day we much end the day with a celebration of our victories.
          All too often, we go to bed thinking about what still needs to get done despite having an objectively productive day.
          Sound familiar?
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          This is where the Reflection screen comes in. 
          By pressing the Reflection button you are signalling an end to the day. This is a powerful idea in itself.
          You will be presented with your score screen so that you can feel good about your accomplishments.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To help you reflect on your day you can chat with Socrates. 
          He will be glad to share ancient wisdom with you and help you reflect on what matters most.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          You may also leave a note to yourself for the following day. This is a great way to inspire yourself.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To complete this mission, chat with Socrates. Yes, chat with Socrates :)
          <div><v-btn @click="go('Progress')" color="primary" class="mt-4">Go to Progress</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 16: Notifications ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 16">
      <v-card-title class="text-h5 mb-0">Notifications</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          One last thing.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Getting into the habit of using a new tool can be challenging.
          To help keep the momentum going I highly recommend setting up text message notifications.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To do this, press the "Gear" button in the top right corner of the screen.
          From this window you can modify your target settings and manage text message notifications.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Personally, I use the morning and evening notifications to receive 2 messages per day.
          Feel free to change the times to suit your schedule.
          When you are ready enter and verify your phone number.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          To complete this mission, add your phone number for notifications
          <div><v-btn @click="go('Progress')" color="primary" class="mt-4">Go to Progress</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
    <!-- ---------------------- Level 17: Complete ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level == 17">
      <v-card-title class="text-h5 mb-0">Mission Complete</v-card-title>
      <v-card-actions class="d-flex flex-row align-center mt-0 px-3">
        <v-avatar class="mr-3"><img src="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fcoaches%2Faaron.jpg?alt=media&token=99aab83c-20ee-4572-9cc9-0718b613d9c9"></v-avatar>
        <div class="subtitle-1 lifeos-font-sans d-flex flex-column">
          <div class="font-weight-bold">Aaron F.</div>
          <div class="font-italic caption grey--text">Lead Engineer, Life OS</div>
        </div>
      </v-card-actions> 
      <v-card-text>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Congratulations!
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          You've made a tremendous effort to living your life with more purpose, direction, and intention.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          There is still a long journey ahead of you. 
          While you are solely responsible for doing the hard work in your life you don't have to walk alone.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          I highly encourage you to partner up with a friend using our buddy system based of the US Navy Seals.
          If your friends aren't ready to join you, you can always find a buddy in our community.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Lastly, please consider inviting one of our inspiring coaches to your journey.
          Too often we try to do everything on our own. I was one of those fiercely independent people.
          Once I started surrounding myself with inspiring people I realized how much I was missing.
          It is not about finding someone to tell you what to do. It is about finding someone you respect to provide another perspective.
          A coach can be anything from a daily support to simply helping you setup your blueprint.
        </div>
        <div class="subtitle-1 mb-4 lifeos-font-sans">
          Ready to unlock the full experience?
          <div><v-btn @click="endTour()" color="primary" class="mt-4">Mission Accomplished</v-btn></div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'Tour',
  props: ['user'],
  data() {
    return {
      
    }
  },
  methods: {
    startTour() {
      this.user.profile.level = 2
      this.$emit('save')
    },
    endTour() {
      this.user.profile.level = 20
      delete this.user.profile.missions
      this.$emit('save')
    }
  }
}
</script>