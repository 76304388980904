<template>
  <div class="footer d-flex flex-column">
    <div v-intersect="onIntersect"></div>
    <Player v-if="user && user.profile.level > 1" :render="onScreen" :user="user"></Player>
    <v-bottom-navigation class="d-flex flex-column flex-md-row">
      <div class="attribution">
        Life OS &copy; {{ getYear() }} — <strong>Farr Industries Corp.</strong> 
        <!-- <router-link class="pl-2" to="/changelog">({{ codename }}: {{ version }} develop)</router-link> -->
        &nbsp;({{ codename }}: {{ version }})
      </div>
      <div class="attribution">
        <router-link class="pl-4" to="/privacy">Privacy Policy</router-link>
        <router-link class="pl-4" to="/terms">Terms of Use</router-link>
      </div>
    </v-bottom-navigation>
  </div>
</template>
<script>
  import Player from './Player.vue'
  import { DateTime } from 'luxon'
  export default {
    components: {
      Player,
    },
    props: ['user'],
    data: () => ({
      links: [
        'Home',
        'About Us',
        'Team',
        'Services',
        'Contact Us',
      ],
      codename: '',
      version: '',
      onScreen: false,
    }),
    created() {
      this.codename = process.env.VUE_APP_CODENAME
      this.version = process.env.VUE_APP_VERSION
    },
    methods: {
      onIntersect (entries) {
        const [entry] = entries;
        console.log('onIntersect')
        this.onScreen = entry.isIntersecting
      },
      getYear() {
        return DateTime.now().toFormat('yyyy')
      },
    }
  }
</script>

<style scoped>
  .attribution {
    height: inherit;
    opacity: 0.7;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    line-height: normal;
    position: relative;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
  }
</style>