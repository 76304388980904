<template>
  <v-app>
    <NavigationDrawer :drawer="drawer" :user="user" :sections="sections" @close="closeDrawer" @logout='logout'/>
    <Header :user="user" :sections="sections" @open="openDrawer" @logout="logout" @offset="deltaOffset"/>
    <v-main :class="offset">
      <!-- ------ Main View ------ -->
      <router-view :user="user" @save="saveUser" />
    </v-main>
    <Footer :user="user" />
    <!-- <Notification :display="notificationDisplay" :message="notificationMessage" @close="notificationDisplay = false" /> -->
    <MissionProgress v-if="user && user.profile.level < 20" :user="user" @missionComplete="missionComplete"></MissionProgress>
  </v-app>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, doc, onSnapshot, setDoc, updateDoc, collection, getDocs } from 'firebase/firestore'
import { DateTime } from "luxon"
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Notification from './components/Notification.vue'
import MissionProgress from './components/MissionProgress.vue'
import NavigationDrawer from './components/NavigationDrawer.vue'
export default {
  name: 'App',
  components: { Header, Footer, MissionProgress, Notification, NavigationDrawer },
  data: () => ({
    user: null,
    notificationDisplay: false,
    notificationMessage: '',
    drawer: false,
    userListener: null,
    sections: [
      {title: 'Dashboard', route: 'Dashboard', icon: 'mdi-home', path: '/dashboard', subsections: []},
      {title: 'Tools', icon: 'mdi-tools', path: '/tools', subsections: [
        {title: 'Blueprint', route: 'Blueprint', path: '/tools/blueprint', icon: 'mdi-chevron-right', level: 3},
        {title: 'Goals', route: 'Goals', path: '/tools/goals', icon: 'mdi-chevron-right', level: 8},
        {title: 'Progress', route: 'Progress', path: '/tools/progress', icon: 'mdi-chevron-right', level: 13},
      ]},
      // {title: 'Optimize', route: 'Optimize', icon: 'trip_origin', path: '/optimize', disabled: true, subsections: [
      //   {title: 'Wisdom', route: 'OptimizeWisdom', path: '/optimize/wisdom', },
      // ]},
      // {title: 'Buddy', route: 'Buddy', icon: 'person', path: '/buddy', disabled: true, subsections: [
      //   {title: 'Find a Buddy', route: 'BuddyFind', path: '/buddy/find', condition: "!buddy"},
      //   {title: 'Fundamentals', route: 'BuddyFundamentals', path: '/buddy/fundamentals', condition: "buddy"},
      //   {title: 'Daily Report', route: 'BuddyDaily', path: '/buddy/daily', condition: "buddy"},
      // ]},
      // {disabled: true, title: 'Coach', route: 'Coach', icon: 'mdi-whistle-outline', path: '/coach',  subsections: [
      //   {title: 'Find a Coach', route: 'CoachMatch', path: '/coach/match'},
      //   {title: 'Become a Coach', route: 'CoachJoin', path: '/coach/join'},
      // ]},
      // {disabled: true, title: 'Friends', route: 'Friends', icon: 'groups', path: '/friends',  subsections: [
      //   {title: 'Dashboard', route: 'Friends', path: '/friends/'},
      //   {title: 'Challenge', route: 'Challenge', path: '/friends/challenge'},
      // ]},
    ],
    offset: 'header-offset-small'
  }),
  created() {
    // ------------ Header Offset ------------
    this.deltaOffset()
    // ------------ Auth Listener ------------
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      // ------------ Firestore User Listener ----------
      if (user) {
        console.log('USERUD', user.uid)
        console.log('Auth State Changed', user)
        const uid = user.uid
        const db = getFirestore()
        this.userListener = onSnapshot(doc(db, 'users', uid), (document) => {
          let data = document.data()
          console.log('User Delta', data, this.$route.path == '/')
          if (data && data.profile) {
            // ------ Standard Accounts ------
            this.user = data
            // ------ User Data Migrations ------
            if (this.user.refresh !== undefined) {
              delete(this.user.refresh)
              this.user.activity = Date.now()
              this.saveUser()
            }
            if (this.user.profile.missions === undefined) {
              this.user.profile.missions = []
              this.updateProfile()
            }
            if (this.user.profile.timezone === undefined) {
              this.user.profile.timezone = DateTime.local().zoneName
              this.updateProfile()
            }
            if (this.user.profile.missions === undefined) {
              this.user.profile.missions = []
              this.updateProfile()
            }
            if (this.user.profile.roles === undefined) {
              this.user.profile.roles = {
                founder: false,
                pioneer: false,
                coach: false
              }
              this.updateProfile()
            }
            // ------ Redirects ------
            if (this.$route.path == '/' && user) this.go('Dashboard')
          } else {
            // ------ Legacy Accounts ------
            // We need to migrate this account to the standard format
            console.log('LEGACY ACCOUNT', uid)
            if (this.userListener != null) this.userListener() // Unsubscribe from the user listener
          }
        })
      } else {
        console.log('Auth State Changed', 'Logged Out')
        this.user = null
        if (this.userListener != null) this.userListener() // Unsubscribe from the user listener
      }
    })
  },
  methods: {
    updateProfile() {
      if (this.user && this.user.profile) {
        console.log('Updating profile', this.user.uid, this.user.profile, '...')
        const db = getFirestore()
        const userRef = doc(db, 'users', this.user.uid)
        updateDoc(userRef, {
          profile: this.user.profile
        }).then(() => {
          console.log('Updated user profile')
        }).catch(err => {
          console.log('Error updating profile', err)
        })
      }
    },
    saveUser() {
      if (this.user && this.user.uid) {
        console.log('Saving user', this.user, '...')
        const db = getFirestore()
        const userRef = doc(db, 'users', this.user.uid)
        setDoc(userRef, this.user).then(() => {
          console.log('Saved user')
        }).catch(err => {
          console.log('Error saving user', err)
        })
      }
    },
    openDrawer() {
      this.drawer = false
      this.drawer = true
    },
    closeDrawer() {
      this.drawer = true
      this.drawer = false
    },
    logout() {
      const auth = getAuth()
      signOut(auth).then(() => {
        // Sign-out successful.
        this.user = null
        this.go('Login')
      }).catch((error) => {
        // An error happened.
        console.log('Something went wrong. Could not signout.')
      });
    },
    deltaOffset(status) {
      if (status === true || this.$route.path.split('/').length > 2) this.offset = 'header-offset-large'
      else this.offset = 'header-offset-small'
    },
    missionComplete(mission) {
      this.user.profile.missions.push(mission.id)
      this.user.profile.level = this.user.profile.missions.length + 1
      this.updateProfile()
    }
  },
};
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
  }
  .v-main {
    background: url('./resources/images/japanese.gif');
  }
  .header-offset-small {
    padding-top: 48px !important;
  }
  .header-offset-large {
    padding-top: 96px !important;
  }
  .v-card__title {
    // border-bottom: 1px solid #ecf0f1;
    border-bottom: 1px solid #efefef;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .v-card__actions {
    margin-top: 20px;
    background: #ecf0f1;
  }
  .w-100 {
    width: 100% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .flex-1 {
    flex: 1 !important;
  }
  .flex-2 {
    flex: 2 !important;
  }
  .flex-3 {
    flex: 3 !important;
  }
  .grid-row {
    display: grid; grid-auto-flow: column;

  }
  .grid-column {
    display: grid; grid-auto-flow: row;
  }
  .lifeos-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;
    .lifeos-heading {
      margin-bottom: 15px;
    }
  }
  .lifeos-heading {
    color: rgb(55, 53, 47) !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
    font-size: 30px !important;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
    cursor: text !important;
    flex: 1;
  }
  .lifeos-heading-actions {
    display: flex;
    flex-direction: row;
  }
  .lifeos-subheading {
    color: rgb(55, 53, 47) !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
    font-size: 30px !important;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
    cursor: text !important;
  }
  .lifeos-transparent-button {
    border-radius: 4px;
  }
  .lifeos-transparent-button:hover {
    // background: #64646440;
    background: rgb(193 193 193 / 18%);
    cursor: pointer;
  }
  .lifeos-dialog-title {
    color: rgb(55, 53, 47) !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
    font-size: 40px !important;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
    cursor: text !important;
    flex: 1;
  }
  .lifeos-font-serif {
    font-family: 'Quattrocento' !important;
    font-weight: bold;
  }
  .lifeos-font-sans {
    font-family: 'Quattrocento Sans' !important;
    font-weight: bold;
  }
  .heroic-title {
    font-size: 45px;
  }
  .bold-title {
    font-size: 30px !important;
    font-weight: bold !important;
  }
  $breakpoints: (
    xs: 600px,
    sm: 600px,
    md: 960px,
    lg: 1264px,
    xl: 1904px,
  );
  .lifeos-flex {
    display: flex;
    flex-wrap: wrap;
    order: 1;
  }
  .w100 {
    width: 100%;
  }
  .wip {
    border: 1px dotted red;
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: #{map-get($breakpoints, 'xs')}) {
    .lifeos-flex {
      // background: red;
      flex-direction: column;
    }
    .lifeos-flex .lifeos-flex-item {
      // border: 1px solid red;
      flex: 1;
    }
    .lifeos-dialog-title {
      font-size: 22px !important;
    }
    .lifeos-box {
      flex-basis: calc(100% / 1);
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: #{map-get($breakpoints, 'sm')}) {
    .lifeos-flex {
      // background: yellow;
      flex-direction: row;
    }
    .lifeos-flex .lifeos-flex-item {
      // border: 1px solid red;
      width: 50%;
    }
    .lifeos-dialog-title {
      font-size: 26px !important;
    }
    .lifeos-heading {
      font-size: 40px !important;
    }
    .lifeos-box {
      flex-basis: calc(100% / 2);
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: #{map-get($breakpoints, 'md')}) {
    .lifeos-flex {
      // background: blue;
      flex-direction: row;
    }
    .lifeos-flex .lifeos-flex-item {
      // border: 1px solid red;
      width: 33.33%;
    }
    .lifeos-header {
      flex-direction: row;
      .lifeos-heading {
        margin-bottom: 0;
      }
    }
    .lifeos-dialog-title {
      font-size: 32px !important;
    }
    .goal .actions {
      visibility: visible !important;
    }
    .lifeos-box {
      flex-basis: calc(100% / 3);
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: #{map-get($breakpoints, 'lg')}) {
    .lifeos-flex {
      // background: orange;
      flex-direction: row;
    }
    .lifeos-flex .lifeos-flex-item {
      // border: 1px solid red;
      width: 25%;
    }
    .lifeos-dialog-title {
      font-size: 40px !important;
    }
    .lifeos-box {
      flex-basis: calc(100% / 4);
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: #{map-get($breakpoints, 'xl')}) {
    .lifeos-flex {
      // background: red;
      flex-direction: row;
    }
    .lifeos-flex .lifeos-flex-item {
      // border: 1px solid red;
      width: 25%;
    }
  }
  .white-light {
    // border: 1px solid #607D8B !important;
    border: 1px solid black !important;
    background: white !important;
  }
  .text-disable-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
</style>