<template>
  <div class="authenticate">
    <v-alert type="error" v-if="authFeedback">
      {{ authFeedback }}
    </v-alert>
    <div class="caption grey--text mb-2 lifeos-font-sans text-subtitle-1">We are currently only accepting Google accounts</div>
    <v-btn
      color="red darken-3"
      class="white--text mb-2 overline"
      @click="googleAuth()"
      block
    >
      <v-icon left dark>mdi-google</v-icon> Continue with Google
    </v-btn>
    <div class="my-2 caption grey--text">or</div>
    <v-text-field label="Email" placeholder="Enter your email" outlined hide-details disabled="true"></v-text-field>
    <v-text-field label="Password" placeholder="Password" class="mt-3" outlined hide-details disabled="true"></v-text-field>
    <v-btn color="primary" class="mt-4" :disabled="true">Continue with email</v-btn>
    <!-- <div class="caption grey--text mb-2">Forgot your password?</div> -->
    <v-overlay :value="overlay" :absolute="true" z-index="2"></v-overlay>
  </div>
</template>
<script>
import { getAuth, signOut, signInWithPopup, getAdditionalUserInfo, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore, doc, setDoc } from 'firebase/firestore'
import {DateTime} from 'luxon'
export default {
  name: 'Authenticate',
  data() {
    return {
      authFeedback: '',
      overlay: false,
    }
  },
  methods: {
    signOut() {
      let auth = getAuth()
      auth.signOut()
    },
    googleAuth() {
      this.overlayStyle = 'red darken-3'
      this.overlay = true
      this.showEmailPasswordForm = false
      this.authFeedback = ''
      let provider = new GoogleAuthProvider()
      let auth = getAuth()
      provider.addScope('email')
      provider.addScope('profile')
      // provider.addScope('https://www.googleapis.com/auth/user.birthday.read')
      // provider.addScope('https://www.googleapis.com/auth/user.gender.read')
      // provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      // provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      signInWithPopup(auth, provider).then( credentials => {
        if (getAdditionalUserInfo(credentials).isNewUser) {
          // ---------------------- New User Registration ----------------------
          const db = getFirestore()
          const uid = credentials.user.uid
          setDoc(doc(db, "users", uid), {
            uid: credentials.user.uid,
            activity: Date.now(),
            profile: {
              name: credentials.user.displayName,
              email: credentials.user.email,
              photo: credentials.user.photoURL,
              avatar: credentials.user.photoURL,
              phone: {
                number: credentials.user.phoneNumber,
                verified: false
              },
              roles: {
                founder: false,
                pioneer: false,
                coach: false,
              },
              timezone: DateTime.local().zoneName,
              verified: credentials.user.emailVerified,
              level: 1,
              missions: []
            },
            blueprint: [],
          }).then(() => {
            console.log('User created successfully')
            console.log('User profile created successfully', uid)
            this.go('Dashboard')
          });
        } else {
          // Existing User. This just becomes a standard login.
          this.go('Dashboard')
        }
      }).catch( error => {
        if (error.code == 'auth/popup-closed-by-user') {
          this.authFeedback = 'Google authentication popup closed.'
        } else {
          this.authFeedback = error.message
        }
        this.overlay = false
      })
    }
  }
}
</script>