<template>
  <div class="missions-container">
    <div class="mission" v-for="mission in missions" :key="mission.id">
      <v-alert class="mission-in-progress" @click="go(mission.route)" v-if="mission.status == 1" dense text color="#2A3B4D" dark :icon="mission.icon" border="left">
        <div class="d-flex flex-row">
          <div class="flex-1 mission-description">{{ mission.description }}</div>
          <div class="font-italic">In Progress</div>
        </div>
      </v-alert>
      <v-alert v-if="mission.status == 2" dense text type="success" dark :icon="mission.icon" border="left">
        <div class="d-flex flex-row">
          <div class="flex-1 mission-description">{{ mission.description }}</div>
          <div class="font-italic">Complete</div>
        </div>
      </v-alert>
    </div>
  </div>
</template>
<script>
import { getFirestore, collection, getDocs, getDoc, setDoc, doc, updateDoc, addDoc, ref } from 'firebase/firestore'
export default {
  name: 'Missions',
  props: ['user'],
  data() {
    return {
      missions: [],
      loading: false
    }
  },
  created() {
    if (this.missions.length == 0) {
      console.log('created, no missions, load missions')
      this.loadMissions()
    } else {
      console.log('created, missions, compare missions')
      this.compareMissions()
    }
  },
  methods: {
    compareMissions() {
      this.missions.forEach( mission => {
        // ------ Default status ------
        mission.status = 0
        // ------ Check if mission is valid ------
        if (mission.level <= this.user.profile.level) mission.status = 1
        // ------ Check if mission is complete ------  
        if (this.user.profile.missions.indexOf(mission.id) > -1) mission.status = 2
        // ------ Check if mission is old ------  
        // if (mission.status == 2 && this.user.profile.level > mission.level + 1) mission.status = 0
      })
      this.$forceUpdate()
    },
    loadMissions() {
      if (this.loading) return
      this.loading = true
      let db = getFirestore()
      let missionRef = collection(db, 'missions')
      getDocs(missionRef).then( querySnapshot => {
        querySnapshot.forEach( doc => {
          let mission = doc.data()
          mission.id = doc.id
          this.missions.push(mission)
        })
        this.loading = false
      }).then(() => {
        this.compareMissions()
      })
    }
  },
  watch: {
    user() {
      if (this.missions.length == 0) {
        console.log('created, no missions, load missions')
        this.loadMissions()
      } else {
        console.log('created, missions, compare missions')
        this.compareMissions()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .mission-in-progress:hover {
    cursor: pointer;
  }
  .mission-go {
    background: white !important;
    color: black !important;
  }
  .mission-complete {
    background: #f4f4f4 !important;
    color: darkgray !important;
    .mission-description {
      text-decoration: line-through;
    }
  }
</style>