<template>
  <div :class="render ? 'player' : 'player fixed'" :style="{'border': user.profile.level < 4 ? '3px solid #ffec3b' : ''}">
    <!-- ------------ Lesson Player ------------ -->
    <MissionPlayer v-if="playerIndex == 0 && user.profile.level < 20" :class="playerClass" :user="user"></MissionPlayer>
    <!-- ------------ Lesson Player ------------ -->
    <LessonPlayer v-if="playerIndex == 1" :class="playerClass" :user="user"></LessonPlayer>
    <!-- ------------ Lofi Player ------------ -->
    <LofiPlayer v-if="playerIndex == 2" :class="playerClass"></LofiPlayer>
    <!-- ------------ Feedback Player ------------ -->
    <FeedbackPlayer v-if="playerIndex == 3" :class="playerClass" :email="user.profile.email"></FeedbackPlayer>
    <!-- ------------ Atmospheric Player ------------ -->
    <!-- ------------ Music Player ------------ -->
    <!-- ------------ Meditation Player ------------ -->
    <!-- ------------ Video Player ------------ -->
    <!-- ------------ Podcast Player ------------ -->
    <!-- ------------ Audiobook Player ------------ -->
    <!-- ====================== Player Selection ====================== -->
    <v-sheet dark class="d-flex flex-row px-4 align-center player-toolbar">
      <div class="overline grey--text flex-1">{{ playerTypes[playerIndex].name }}</div>
      <div v-for="(player, index) in playerTypes" :key="player.name">
        <v-badge
          v-if="player.enabled && player.badge && player.level == user.profile.level"
          bordered
          top
          color="yellow accent-4"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-btn icon v-if="player.enabled" @click="playerIndex = index">
            <v-icon :color="playerIndex == index ? 'white': 'grey'">{{ player.icon }}</v-icon>
          </v-btn>
        </v-badge>
        <v-btn icon v-else-if="player.enabled && player.level <= user.profile.level && player.hide != user.profile.level" @click="playerIndex = index">
          <v-icon :color="playerIndex == index ? 'white': 'grey'">{{ player.icon }}</v-icon>
        </v-btn>  
      </div>
    </v-sheet>
  </div>
</template>
<script>
import MissionPlayer from './players/MissionPlayer.vue'
import LessonPlayer from './players/LessonPlayer.vue'
import LofiPlayer from './players/LofiPlayer.vue'
import FeedbackPlayer from './players/FeedbackPlayer.vue'
import { getFirestore, doc, setDoc, collection } from 'firebase/firestore'
export default {
  name: 'Player',
  components: {
    MissionPlayer,
    LessonPlayer,
    LofiPlayer,
    FeedbackPlayer    
  },
  props: ['render', 'user'],
  data () {
    return {
      playerIndex: 0,
      playerClass: 'player',
      playerTypes: [
        { name: 'Mission Player', icon: 'mdi-trophy-outline', type: 'mission', enabled: true, badge: false, level: 2, hide: 20 },
        { name: 'Lesson Player', icon: 'mdi-school-outline', type: 'lesson', enabled: true, badge: false, level: 2},  
        { name: 'Lofi Player', icon: 'mdi-album', type: 'lofi', enabled: true, badge: false, level: 20},
        { name: 'Feedback Player', icon: 'mdi-message-alert-outline', type: 'feedback', enabled: true, badge: false, level: 3 },
        // { name: 'Lofi Player', icon: 'mdi-album', type: 'lofi', enabled: true, badge: false, level: 3 },
        // { name: 'Atmospheric Player', icon: 'mdi-leaf', type: 'atmospheric', enabled: false, badge: false },
        // { name: 'Music Player', icon: 'mdi-music', type: 'music', enabled: false, badge: false },
        // { name: 'Meditation Player', icon: 'mdi-meditation', type: 'meditation', enabled: false, badge: false },
        // { name: 'Video Player', icon: 'mdi-video-outline', type: 'video', enabled: false, badge: false },
        // { name: 'Podcast Player', icon: 'mdi-podcast', type: 'podcast', enabled: false, badge: false },
        // { name: 'Audiobook Player', icon: 'mdi-book-outline', type: 'audiobook', enabled: false, badge: false },
      ],
      expanded: false,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.playerTypes.forEach(player => player.badge = false)
      if (this.user.profile.level == 2) this.playerTypes[1].badge = true
      if (this.user.profile.level == 3) this.playerTypes[3].badge = true
      if (this.user.profile.level == 4) {
        // after 5 seconds, reset to mission player
        setTimeout(() => {
          this.playerIndex = 0
        }, 5000)
      }
    }
  },
  watch: {
    user() {
      this.initialize()
    },
    playerIndex() {
      console.log('PI', this.playerIndex)
      // Mission 3
      if (this.user.profile.level == 3 && this.playerIndex == 3) {
          this.user.profile.missions.push('mission-3')
          this.user.profile.level = 4
          // 
          const db = getFirestore()
          const usersCollection = collection(db, 'users')
          const userRef = doc(usersCollection, this.user.uid);
          setDoc(userRef, this.user, { merge: true }).then(response => {
            // console.log(response, 'SAVED!')
          }).catch(error => {
            console.log(error)
          })
        }
    }
  }
}
</script>
<style scoped>
  .player.fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
  .player {
    position: relative;
  }
  .player-toolbar {
    background: black;
  }
  .overflow-hidden {
    overflow: hidden;
  }
</style>