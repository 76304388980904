<template>
  <v-snackbar :class="message.type" v-model="state" :timeout="message.type == 'complete' ? 10000 : -1" bottom right width="400" :multi-line="true" transition="scroll-x-transition" color="white" light>
    <div class="d-flex flex-row align-center">
      <v-btn icon dark :color="message.type == 'complete' ? 'green' : 'blue'" x-large class="mr-4"><v-icon x-large>{{ message.type == 'complete' ? 'mdi-medal-outline' : 'mdi-progress-check' }}</v-icon></v-btn>
      <div class="flex-1 d-flex flex-column">
        <div class="subtitle-1">{{ message.type == 'complete' ? "Mission Complete" : "Current Mission" }}</div>
        <div class="body-2">{{ message.text }}</div>
      </div>
      <v-btn icon @click="state=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>
<script>
export default {
  name: 'Notification',
  props: ['display', 'message'],
  data: () => ({
    state: false
  }),
  watch: {
    display() {
      if (this.display) {
        this.state = true 
      }
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss">
  .progress .v-snack__content {
    border-left: 6px solid #2296f3;
  }
  .complete .v-snack__content {
    border-left: 6px solid green;
  }
  .v-snack__content {
    border-left: 6px solid #2296f3;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
  }

</style>