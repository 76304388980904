import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        accent: colors.red,
        error: colors.red.accent3,
      }
    },
  },
});