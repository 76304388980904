<template>
  <div class="welcome container">
    <v-card dark>
      <!-- <v-img height="400" class="white--text align-center text-center flex-1" src="@/resources/images/heroes/hero.jpg" light> -->
      <v-img height="300" class="white--text align-center text-center flex-1" src="https://source.unsplash.com/random/?nature" light>
        <img width="100" src="@/resources/images/logos/icon-white.png"><br>
        Let's Go.
      </v-img>
      <!-- <v-card-title dark>Intuitive tools to help you along your heroic journey.</v-card-title> -->
    </v-card>
    <v-card class="mt-3" v-if="user && user.profile.roles.founder" dark>
      <v-card-title>Founding Member</v-card-title>
      <v-card-text>
        <p>Like you, Life OS is also on a journey. Our mission is to help our fellow humans be the best versions of themselves by providing tools to help them along their heroic journey.</p>
        <p>Our goal is to serve 1 million active users daily. To achieve this goal we need your feedback. As a founding member you will be given exclusive access to our development team and the latest features.</p>
        <p>To show our appreciation, you will be given a special badge and sent an exclusive welcome package.</p>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-card-title>Welcome to Life OS</v-card-title>
      <v-card-text>
        <p>It is so great to meet you! You are now part of a community of like-minded optimizers who want to see you flourish.</p>
        <p>While you have full access to all of our tools it can be overwhelming. We recommend following along with our curated tutorials below.</p>
      </v-card-text>
    </v-card>
    <v-expansion-panels class="mt-3" v-if="false">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h6">
            <v-btn icon><v-icon color="grey"></v-icon></v-btn> Priorities</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          *video player*
          <v-alert type="info">
            Mission: Add at least 2 priorities to your profile
          </v-alert>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h6"><v-btn icon><v-icon color="grey">mdi-lock</v-icon></v-btn> Identity</div>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h6"><v-btn icon><v-icon color="grey">mdi-lock</v-icon></v-btn> Focus</div>
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  name: 'Welcome',
  props: ['user'],
  created() {

  },
}
</script>
<style lang="scss" scoped>

</style>