<template>
  <div class="debrief-phase">
    <!-- ---------------------- Banner Bar ---------------------- -->
    <div class="banner mb-2">
      We either win or we learn. No matter what, we are making progress.
    </div>
    <!-- ---------------------- Main Section ---------------------- -->
    <div class="d-flex flex-column flex-sm-row mb-3">
      <div class="target card flex-1 d-flex flex-column align-center pa-3 mr-0 mr-sm-3 mb-3 mb-sm-0 grey lighten-2">
        <div class="overline grey--text text--darken-2">Result Summary</div>
        <h1>{{ numberOfCompletedActionsToday }} / {{ numberOfCommittedActionsToday }}</h1>
        <div class="caption d-flex align-center flex-row grey--text">
          <v-icon small left color="grey">mdi-target</v-icon>
          {{ summary }}
        </div>
      </div>
      <div class="dps card flex-1 d-flex flex-column align-center pa-3" :class="targetClass('background')">
        <div class="overline" :class="targetClass('title')">Daily Productivity Score</div>
        <h1 :class="targetClass('highlight')">{{ dps }}</h1>
        <div class="caption" :class="targetClass('caption')">{{ breakdown }}</div>
      </div>
    </div>
    <div class="socrates-container mb-3">
      <div class="socrates-portrait"></div>
      <div v-if="discussion" class="socrates-forum">
        <div v-if="thinking">Thinking...</div>
        <!-- <div v-if="speaking">Speaking...</div> -->
        <div class="socrates-chat d-flex flex-1">
          {{ socratesResponse }}
        </div>
        <div class="mt-2">
          <v-text-field v-model="say" outlined dense dark hide-details :disabled="thinking || speaking" append-icon="mdi-keyboard-return" maxlength="144" @click:append="discussWithSocrates()" @keydown.enter="discussWithSocrates()"></v-text-field>
        </div>
      </div>
      <div v-else class="socrates-forum">
        <div class="quote">
          {{ quotes[Math.floor(Math.random() * quotes.length)].quote }}
        </div>
        <div class="flex-1 font-italic" style="text-align: right;">
          -Socrates
        </div>
        <div>
          <v-btn block dark outlined @click="discussWithSocrates()" :style="{'border': user.profile.level == 15 ? '3px solid #ffec3b':''}">Discuss with Socrates</v-btn>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-sm-row mb-3">
      <div class="history card elevation-1 flex-1 d-flex flex-column pa-3 mr-0 mr-sm-3 mb-3 mb-sm-0">
        <div class="overline d-flex justify-center mb-2">Progress History</div>
        <div class="graph d-flex flex-row flex-1 mb-2">
          <div class="scale d-flex ml-2 flex-column" :style="{'height': '100%'}">
            <div v-for="(scale, index) in history.scale" :key="'scale' + index" class="label flex-1 d-flex caption justify-end pr-2 grey--text text--darken-2">
              {{ scale }}
            </div>
          </div>
          <div v-for="(result, index) in history.results" :key="'result' + index" class="bar d-flex flex-1 px-2 flex-column justify-end">
            <div class="fill w100" :style="{'height': result + '%'}" :class="history.colours[index]"></div>
          </div>
        </div>
        <div class="caption d-flex justify-center flex-row grey--text">
          <v-icon small left color="grey">mdi-calendar-month</v-icon>
          Over the last 7 days
        </div>
      </div>
      <div class="balance card elevation-1 flex-1 d-flex flex-column pa-3" color="white">
        <div class="overline d-flex justify-center mb-2">Priority Balance</div>
        <div v-for="(priority, index) in balance" :key="index" class="d-flex flex-row align-center mb-1">
          <div class="flex-2 caption" :class="priority.count > 0 ? 'grey--text text--darken-2' : 'red--text'">{{ priority.name }}</div>
          <div class="ml-1" style="flex: 4">
            <div :style="{'background': colour(priority.colour).hex.medium, 'width': 100 * (priority.count/balanceMax) + '%', 'height': '10px'}"></div>
          </div>
        </div>
        <div class="caption d-flex justify-center flex-row grey--text">
          <v-icon small left color="grey">mdi-calendar-month</v-icon>
          Over the last 30 days
        </div>
      </div>
    </div>
    <!-- <div class="history card elevation-1 flex-1 d-flex flex-column pa-3 mr-0 mr-sm-3 mb-3 mb-sm-0"></div> -->
    <div class="d-flex card flex-column elevation-1 pa-3">
      <div class="overline d-flex justify-center mb-2">Self Reflection Notes</div>
      <v-textarea v-model="notes" :disabled="saving" outlined hide-details rows="3" maxlength="1028"></v-textarea>
      <div class="d-flex flex-column-reverse flex-sm-row mt-3">
        <div class="d-flex flex-1 grey--text align-center caption mt-2 mt-sm-0">
          <v-icon small left color="grey">mdi-note-text-outline</v-icon>
          Your note will be showed to you tomorrow
        </div>
        <v-btn :loading="saving" color="black" dark @click="saveNote()">Save Note</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { getFirestore, doc, query, orderBy, limit, collection, getDocs } from 'firebase/firestore'
import { DateTime } from "luxon"
import axios from 'axios'
export default {
  name: 'DailyDebrief',
  props: ['user'],
  data() {
    return {
      thinking: false,
      speaking: false,
      say: null,
      socratesResponse: '',
      discussion: false,
      chat: [],
      history: {
        results: [],
        scale: []
      },
      notes: '',
      saving: false,
      quotes: [
        {"quote": "The unexamined life is not worth living.", "author": "Socrates"},
        {"quote": "There is only one good, knowledge, and one evil, ignorance.", "author": "Socrates"},
        {"quote": "I cannot teach anybody anything, I can only make them think.", "author": "Socrates"},
        {"quote": "To be is to do.", "author": "Socrates"},
        {"quote": "I know that I am intelligent, because I know that I know nothing.", "author": "Socrates"},
        {"quote": "Education is the kindling of a flame, not the filling of a vessel.", "author": "Socrates"},
        {"quote": "True wisdom comes to each of us when we realize how little we understand about life, ourselves, and the world around us.", "author": "Socrates"},
        {"quote": "To find yourself, think for yourself.", "author": "Socrates"},
        {"quote": "The only true wisdom is in knowing you know nothing.", "author": "Socrates"},
        {"quote": "Wonder is the beginning of wisdom.", "author": "Socrates"},
        {"quote": "Be slow to fall into friendship; but when thou art in, continue firm and constant.", "author": "Socrates"},
        {"quote": "Contentment is natural wealth, luxury is artificial poverty.", "author": "Socrates"}
      ]
    }
  },
  created() {
    // Load the history
    this.getHistory()
    // Load the note if it's from today
    if (this.user.daily.notes && this.calendarDate() == this.user.daily.notes.date)
      this.notes = this.user.daily.notes.text
  },
  methods: {
    calendarDate() {
      return DateTime.now().toFormat('yyyy-LL-dd')
    },
    discussWithSocrates() {
      if (this.user.profile.level == 15) {
        this.user.profile.level = 16
        this.user.profile.missions.push('mission-f')
        this.$emit('update')
      }
      this.discussion = true
      if (this.say == null) {
        this.askSocrates('I am trying to live a good life. Today, I committed to completing ' + this.numberOfCommittedActionsToday + ' actions towards my goals but only achieved ' + this.numberOfCompletedActionsToday + ' of them. Do you have any advice and wisdom to share?')
      } else {
        if (this.say.length < 4) return false
        this.askSocrates(this.say)
      }
    },
    askSocrates(prompt) {
      this.thinking = true
      this.socratesResponse = ''
      this.say = ''
      this.chat.push({"role": "user", "content": prompt})
      axios.post('https://us-central1-life-os-app.cloudfunctions.net/socrates', {
        prompt: prompt,
        history: this.chat
      }).then( response => {
        this.thinking = false
        if (response.data.content == 'END') {
          this.speak('Let us keep the conversation focused on philosophy.')
          this.chat.push({"role": "assistant", "content": 'Let us keep the conversation focused on philosophy.'})
        } else {
          this.speak(response.data.content)
          this.chat.push({"role": "assistant", "content": response.data.content})
        }
      }).catch( error => {
        this.thinking = false
      })
    },
    speak(text) {
      this.speaking = true
      const words = text.split(' ')
      words.forEach((word, index) => {
        setTimeout(() => {
          this.socratesResponse += word + ' '
        }, 200 * index)
        if (index === words.length - 1) {
          setTimeout(() => {
            this.speaking = false
          }, 200 * index)
        }
      })
    },
    targetClass(type) {
      if (type == 'highlight') return 'white--text'
      // Determine base colour
      let colour = 'purple'
      let completed = this.numberOfCompletedActionsToday
      let committed = this.numberOfCommittedActionsToday
      if (completed < committed) colour = 'green'
      if (completed < this.user.daily.target.dps) colour = 'blue'
      if (completed < this.user.daily.target.minimum) colour = 'orange'
      if (completed == 0) colour = 'grey'
      // 
      if (colour == 'grey') {
        if (type == 'background') return 'black'
        if (type == 'title') return colour + '--text'
        if (type == 'caption') return colour + '--text text--darken-2'
        if (type == 'bar') return 'black'
      } else {
        if (type == 'background') return colour + ' lighten-2'
        if (type == 'title') return colour + '--text text--darken-4'
        if (type == 'caption') return colour + '--text text--darken-2'
        if (type == 'bar') return colour
      }
      return colour
    },
    colour(index) {
      return this.$store.getters.colour(index)
    },
    saveNote() {
      this.saving = true
      this.user.daily.notes = {
        date: this.calendarDate(),
        text: this.notes
      }
      this.$emit('update')
      this.saving = false
    },
    getHistory() {
      // get the user doc
      const db = getFirestore()
      var userDoc = doc(db, 'users', this.user.uid)
      // take the 7 last docs from the history collection
      var historyCollection = collection(userDoc, 'history')
      const historyQuery = query(historyCollection, orderBy("timestamp", "desc"), limit(7))
      getDocs(historyQuery).then((docs) => {
        // Find the global max value
        let globalMax = 0
        docs.forEach(doc => {
          let data = doc.data()
          let localMax = Math.max(data.target, data.minimum, data.completed, data.committed)
          if (localMax > globalMax) globalMax = localMax
        })
        // Return relative history
        let history = []
        let colours = []
        // limit scale to 1 decimal place
        let scale = [globalMax, (3 * (globalMax / 4)).toFixed(1), (globalMax/2).toFixed(1), (globalMax/4).toFixed(1)]
        docs.forEach(doc => {
          let data = doc.data()
          history.push(data.completed / globalMax * 100)
          //
          let colour = 'grey'
          if (data.completed > 0) colour = 'orange'
          if (data.completed >= data.minimum) colour = 'blue'
          if (data.completed >= data.target) colour = 'green'
          if (data.completed >= data.committed) colour = 'purple'
          colours.push(colour)
        })
        history.push(this.numberOfCompletedActionsToday / globalMax * 100)
        colours.push(this.targetClass('bar'))
        this.history = {
          results: history,
          scale: scale,
          colours: colours
        }
        this.$forceUpdate()
      })
    }
  },
  computed: {
    breakdown() {
      // DPS score breakdown
      let completed = this.numberOfCompletedActionsToday
      let breakdown = '+' + this.numberOfCompletedActionsToday + ' completed actions'
      if (completed >= this.user.daily.target.minimum) breakdown += ', +1 minimum'
      if (completed >= this.user.daily.target.dps) breakdown += ', +1 target'
      if (completed >= this.numberOfCommittedActionsToday) breakdown += ', +1 committed'
      return breakdown
    },
    summary() {
      if (this.numberOfCompletedActionsToday >= this.numberOfCommittedActionsToday) {
        // User completed all committed actions for the day
        if (this.numberOfCompletedActionsToday >= this.user.daily.target.dps) {
          return 'You achieved your daily committment and target'
        } else if (this.numberOfCompletedActionsToday >= this.user.daily.target.minimum) {
          return 'You achieved your daily committment and minimum'
        } else {
          return 'You achieved your committment target'
        }
      } else {
        if (this.numberOfCompletedActionsToday >= this.user.daily.target.dps) {
          return 'You achieved your daily target'
        } else if (this.numberOfCompletedActionsToday >= this.user.daily.target.minimum) {
          return 'You achieved your minimum daily target'
        } else {
          return 'You did not meet your minimum target'
        }
      }
    },
    dps() {
      let completed = this.numberOfCompletedActionsToday
      let score = completed
      if (completed >= this.user.daily.target.minimum) score++
      if (completed >= this.user.daily.target.dps) score++
      if (completed >= this.numberOfCommittedActionsToday) score++
      return score
    },
    numberOfCompletedActionsToday() {
      if (!this.user) return false
      let count = 0
      this.user.blueprint.forEach(group=>{
        group.priorities.forEach(priority=>{
          priority.goals.forEach(goal=>{
            goal.actions.forEach(action=>{
              if (action.progress.selected == this.calendarDate() && (action.progress.finish == this.calendarDate() || action.progress.step == this.calendarDate())) {
                count++
              }
            })
          })
        })
      })
      this.user.daily.todos.forEach(todo=>{
        if (todo.progress.selected == this.calendarDate() && todo.progress.finish) count++
      })
      return count
    },
    numberOfCommittedActionsToday() {
      if (!this.user) return false
      let count = 0
      this.user.blueprint.forEach(group=>{
        group.priorities.forEach(priority=>{
          priority.goals.forEach(goal=>{
            goal.actions.forEach(action=>{
              if (action.progress.selected == this.calendarDate()) {
                count++
              }
            })
          })
        })
      })
      this.user.daily.todos.forEach(todo=>{
        if (todo.progress.selected == this.calendarDate()) count++
      })
      return count
    },
    balance() {
      let graph = []
      this.user.blueprint.forEach(group=>{
        let count = 0
        group.priorities.forEach(priority=>{
          priority.goals.forEach(goal=>{
            goal.actions.forEach(action=>{
              if (
                (action.progress.finish && DateTime.fromFormat(action.progress.finish, 'yyyy-LL-dd').diffNow('days').days > -30) ||
                (action.progress.step && DateTime.fromFormat(action.progress.step, 'yyyy-LL-dd').diffNow('days').days > -30) 
              ) {
                count++
              }
            })
          })
        })
        graph.push({
          name: group.title,
          colour: group.colour,
          count: count
        })
      })
      return graph
    },
    balanceMax() {
      // return the largest count from the balance graph
      let max = 0
      this.balance.forEach(item=>{
        if (item.count > max) max = item.count
      })
      return max == 0 ? 100 : max
    },
  },
  watch: {
    user() {
      // Load the history
      this.getHistory()
      // Load the note if it's from today
      if (this.user.daily.notes && this.calendarDate() == this.user.daily.notes.date)
        this.notes = this.user.daily.notes.text
    }
  }
}
</script>
<style scoped>
  .card {
    background: white;
    border-radius: 5px;
  }
  .balance {
    background: white;
  }
  .quote {
    max-width: 100%;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    caret-color: rgb(55, 53, 47);
    padding: 3px 2px;
    font-family: Lyon-Text, Georgia, ui-serif, serif;
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1.3;
    margin-bottom: 12px;
    z-index: 1;
  }
  .socrates-container {
    display: flex;
    flex-direction: row;
    background: black;
    color: white;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  .socrates-portrait {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2Fsocrates-300.png?alt=media&token=c119fc04-d6df-4de3-bab0-b977535679c2');
    background-repeat: no-repeat;
    min-height: 285px;
    max-height: 285px;
    min-width: 300px;
    position: absolute;
    opacity: 0.25;
    z-index: 0;
  }
  .socrates-forum {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;
    min-height: 285px;
    max-height: 285px;
    z-index: 1;
  }
  .socrates-chat {
    font-family: Lyon-Text, Georgia, ui-serif, serif;
    overflow: scroll;
  }
  .scale {
    border-right: 1px solid #616161;
    min-height: 200px;
  }
  .scale .label {
      margin-top: -8px;
    }
  .bar {
    border-bottom: 1px solid #616161;
    min-height: 200px;
  }
  .bar .fill {
    background: #e0e0e0;
  }
  @media only screen and (min-width: 600px) {
    .socrates-forum {
      margin-left: 285px;
    }
    .socrates-portrait {
      opacity: 0.80;
    }
  }
</style>