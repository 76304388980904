<template>
  <div class="priorities container">
    <!-- ------ Heading ------ -->
    <div class="d-flex flex-row  align-center mb-4" v-if="user">
      <div class="lifeos-heading lifeos-font-serif">Blueprint</div>
      <v-spacer></v-spacer>
      <!-- ------ Tutorial Player ------ -->
      <!-- <Player video="priorities" /> -->
      <!-- <v-btn icon @click="summaryDialog = true"><v-icon>mdi-format-list-checks</v-icon></v-btn> -->
      <v-btn icon @click="summaryDialog = true"><v-icon>mdi-bullseye-arrow</v-icon></v-btn>
      <v-btn icon @click="helpDialog = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
      <!-- ------ Create Group ------ -->
      <v-menu v-model="newGroupMenu" transition="slide-x-transition" :close-on-content-click="false" max-width="300" :nudge-width="300" :nudge-top="-40" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" class="ml-4" :style="{'border': user.profile.level == 4 ? '3px solid #ffec3b':''}">
            <v-icon left>mdi-plus</v-icon>
            Add Category
          </v-btn>
        </template>
        <v-card class="pa-2">
          <v-text-field v-model="newGroupTitle" autofocus filled hide-details dense append-outer-icon="mdi-keyboard-return" @click:append-outer="createGroup()" @keydown.enter="createGroup()" maxlength="36"></v-text-field>
        </v-card>
      </v-menu>
    </div>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <!-- ------ Blueprint Groups ------ -->
    <div class="lifeos-flex priorities-container" v-if="user && user.blueprint">
      <div v-for="(group,groupIndex) in user.blueprint" :key="groupIndex" class="lifeos-flex-item priority-group mb-2">
        <draggable group="headers" :list="user.blueprint" :move="groupMoving" @end="groupMoved" :class="'group=' + groupIndex" delay='400' delay-on-touch-only='true'>
          <div class="group-header d-flex flex-row align-center">
            <!-- ------ Chip ------ -->
            <v-menu v-model="group.menu" transition="slide-x-transition" :close-on-content-click="false" :nudge-width="200" offset-x top right>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="ma-2 lighten-2" :color="colour(group.colour).class" v-bind="attrs" v-on="on">
                  {{ group.title }}
                </v-chip>
              </template>
              <v-card class="pa-2">
                <v-text-field v-model="group.title" autofocus filled hide-details dense append-outer-icon="mdi-keyboard-return" @click:append-outer="group.menu = false; saveBlueprint()" @keydown.enter="group.menu = false; saveBlueprint()" maxlength="36"></v-text-field>
              </v-card>
            </v-menu>
            <!-- ------ Spacer ------ -->
            <v-spacer></v-spacer>
            <!-- ------ Options ------ -->
            <v-menu transition="slide-x-transition" bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="options">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item @click="deleteGroupIndex = groupIndex; deleteGroup(false)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    Delete
                  </v-list-item>
                </v-list-item-group>
                <v-divider class="my-2"></v-divider>
                <v-list-item-group>
                  <v-list-item v-for="(colour, colourIndex) in colours" :key="colourIndex" @click="changeColour(groupIndex, colourIndex)">
                    <v-chip :color="colour.class" class="mr-2 lighten-2">&nbsp;&nbsp;</v-chip>
                    {{ colour.name }}
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-btn icon @click="addPriority(group)" class="d-sm-none d-flex" :style="group.options ? 'display: flex !important' : ''">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </draggable>
        <!-- ------ Priority Cards ------ -->
        <draggable group="group" animation="200" @end="dragging=false; saveBlueprint()" @start="dragging=true" :list="group.priorities" delay='400' delay-on-touch-only='true'>
          <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
            <v-card v-for="(priority,priorityIndex) in group.priorities" :key="priorityIndex" dense class="priority d-flex flex-row mx-2 mb-1 px-2 py-1 draggable" elevation="1" @click="openPriority(groupIndex,priorityIndex)" :ripple="false" :style="border(group.colour)">
              <v-text-field v-if="priority.edit" v-model="priority.title" autofocus hide-details dense append-outer-icon="mdi-keyboard-return" @click:append-outer="renamePriority(priority)" @keydown.enter="renamePriority(priority)" @blur="renamePriority(priority)" placeholder="Name your priority" maxlength="36"></v-text-field>
              <div v-else class="d-flex flex-1 flex-column align-left py-1 overflow-hidden">
                <div>{{ priority.title }}</div>
                <div v-if="priority.goals.length && priority.goals[0].focus" :class="colour(group.colour).text" class="overline d-flex flex-1 align-center">
                  <v-icon small :color="colour(group.colour).hex['darken-2']" class="mr-1">mdi-eye</v-icon><div class="d-block text-truncate" :style="{color: colour(group.colour).hex['medium']}">{{ priority.goals[0].title }}</div>
                </div>
              </div>
              <v-menu transition="slide-x-transition" bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on" class="options">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group>
                    <v-list-item @click="priority.edit = true">
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      Rename
                    </v-list-item>
                    <v-list-item @click="deleteGroupIndex = groupIndex; deletePriorityIndex = priorityIndex; deletePriority(false)">
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      Delete
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card>
          </transition-group>
        </draggable>
        <!-- ------ New Priority Card ------ -->
        <div class="lifeos-transparent-button mx-2 mb-1 px-2 py-1 text-overline" @click="addPriority(group)" :style="{'border': user.profile.level == 5 ? '3px solid #ffec3b':''}">
          <v-icon small>mdi-plus</v-icon> New
        </div>
      </div>
    </div>
    
    <!-- ------------ Priority Modal ------------ -->
    <Priority v-if="priorityDialog && user.blueprint" :priority="priority" :level="(user.profile.level)" :group="user.blueprint[selectedPriorityIndex.group]" :category="user.blueprint[selectedPriorityIndex.group].title" @close="priorityDialog = false; saveBlueprint()"></Priority>
    <!-- ------ Delete Priority Group Dialog ------ -->
    <v-dialog v-model="deleteGroupDialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Delete Priority Group
        </v-card-title>
        <v-card-text>
          <v-alert type="error" border="left" colored-border elevation="1">
            Deleting a group will delete all of the priorities in that group. This cannot be undone. Are you sure you want to delete this group?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deleteGroupDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="deleteGroup(true)">Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------ Delete Priority Dialog ------ -->
    <v-dialog v-model="deletePriorityDialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Delete Priority
        </v-card-title>
        <v-card-text>
          <v-alert type="error" border="left" colored-border elevation="1">
            Deleting a group will delete all of the priorities in that group. This cannot be undone. Are you sure you want to delete this group?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deletePriorityDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="deletePriority(true)">Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------ Summary Dialog ------ -->
    <v-dialog v-model="summaryDialog" v-if="user && user.blueprint" max-width="700">
      <v-card>
        <v-card-title>
          {{ getYear() }}: Year in Review
          <v-spacer></v-spacer>
          <v-btn @click="summaryDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <!-- ------------ Target Goals in Progress ------------ -->
          <div class="target-container">
            {{ targetGoalsInProgress.length }} goals remaining for {{ getYear() }}:
            <div v-for="(targetGoal, targetGoalIndex) in targetGoalsInProgress" :key="targetGoalIndex">
              <div class="targetGoal" :class="(targetGoalIndex == 0 ? 'first ' : '') + (targetGoalIndex == targetGoalsInProgress.length-1 ? 'last' : '')">
                <v-chip small label :color="targetGoal.group.colour.medium" :style="{'color': targetGoal.group.colour.darkText}" class="mr-2">{{ targetGoal.priority.title }}</v-chip>
                <span class="overline">{{ targetGoal.title }}</span> <!-- {{ targetGoal.group.title }} -->
              </div>
            </div>
          </div>
          <!-- ------------ Completed Goals ------------ -->
          <div class="mt-3">
            {{ targetGoalsCompleted.length }} Completed Goals in {{ getYear() }}:
            <div v-for="(targetGoal, targetGoalIndex) in targetGoalsCompleted" :key="targetGoalIndex">
              <div class="targetGoal completed d-flex flex-row align-center" :class="(targetGoalIndex == 0 ? 'first ' : '') + (targetGoalIndex == targetGoalsCompleted.length-1 ? 'last' : '')">
                <div class="flex-1">
                  <v-btn icon><v-icon :color="targetGoal.group.colour.dark">mdi-trophy</v-icon></v-btn>
                  <v-chip small label :color="targetGoal.group.colour.medium" :style="{'color': targetGoal.group.colour.darkText}" class="mr-2">{{ targetGoal.priority.title }}</v-chip>
                  <span class="overline" style="color: #48804b">{{ targetGoal.title }}</span> <!-- {{ targetGoal.group.title }} -->
                </div>
                <div class="caption" style="color: #77ab7a">{{ convertToLongDateFormat(targetGoal.progress.finish) }}</div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="summaryDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------ Help Dialog ------ -->
    <v-dialog v-model="helpDialog" max-width="560">
      <v-card>
        <v-card-title>Help</v-card-title>
        <v-card-text>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="helpDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------ Debugging ------ -->
    <!-- <v-alert v-if="development && user && user.blueprint" type="info" colored-border border="left">
      <strong>Blueprint: </strong><br>
      <pre>
        {{ user }}
      </pre>
    </v-alert> -->
  </div>
</template>
<script>
import { getFirestore, collection, getDocs, doc, updateDoc, addDoc, ref } from 'firebase/firestore'
import { DateTime } from "luxon"
import Priority from '@/components/Priority.vue'
import Player from '@/components/Player.vue'
import Draggable from "vuedraggable"
export default {
  name: 'Blueprint',
  props: ['user'],
  components: {
    Draggable,
    Priority,
    Player
  },
  data() {
    return {
      loading: true,
      dialog: false,
      dragging: false,
      development: window.location.host.includes('localhost'),
      helpDialog: false,
      summaryDialog: false,
      // ------ Priority Group Variables ------
      createGroupDialog: false,
      newGroupMenu: false,
      newGroupTitle: '',
      deleteGroupDialog: false,
      deleteGroupIndex: null,
      // ------ Priority Variables ------
      priority: null,
      priorityDialog: false,
      selectedPriorityIndex: { group: null, priority: null },
      deletePriorityDialog: false,
      deletePriorityIndex: null,
      // ------ System Constants ------
      movingGroup: {
        to: null,
        from: null
      }
    }
  },
  created() {
    if (this.user) this.loadBlueprint()
  },
  methods: {
    saveBlueprint() {
      // ---------------------- Save Changes ----------------------
      if (this.user && this.user.uid) {
        const db = getFirestore()
        const userRef = doc(db, 'users', this.user.uid)
        updateDoc(userRef, {
          // refresh: Date.now(),
          blueprint: this.user.blueprint
        }).then(() => {
          console.log('Saved blueprint')
        }).catch(error => {
          console.log('Error saving blueprint', error)
        })
      }
    },
    loadBlueprint() {
      this.loading = true
      // ---------------------- Load Blueprint ----------------------
      // The user object contains three data structures to handle priorities:
      // 1. (array) user.blueprint - contains an array of priority groups
      // 2. (array) user.blueprint.priorities - contains an array of priorities
      // 3. (collection) priorities - contains a collection of individual priorities
      if (this.user && this.user.blueprint) {
        console.log('Loaded life blueprint.')
      } else {
        // ------ No priority groups found, create default groups ------
        console.log('No blueprint found.')
        // this.user.blueprint = this.defaultGroups
        // this.saveBlueprint()
      }
      console.log('LOAD BLUEPRINT')
      this.loading = false
    },
    createGroup() {
      if (this.newGroupTitle) {
        this.newGroupMenu = false
        this.user.blueprint.push({
          title: this.newGroupTitle,
          colour: 'black',
          menu: false,
          priorities: [],
        })
        this.newGroupTitle = ''
        this.saveBlueprint()
      }
    },
    addPriority(group) {
      if (group.priorities == undefined) group.priorities = []
      group.priorities.push({
        title: '',
        icon: '',
        cover: '',
        quote: '',
        goals: [],
        edit: true,
      })
      this.saveBlueprint()
    },
    renamePriority(priority) {
      priority.edit = false
      if (priority.title == '') priority.title = 'Untitled'
      this.saveBlueprint()
    },
    deleteGroup(confirmed) {
      if (this.deleteGroupIndex >= 0) {
        if (!confirmed) {
          // ------ Confirm Delete ------
          this.deleteGroupDialog = true
        } else {
          // ------ Delete Group ------
          this.deleteGroupDialog = false
          this.user.blueprint.splice(this.deleteGroupIndex, 1)
          this.deleteGroupIndex = null
          this.saveBlueprint()
        }
      }
    },
    changeColour(index, colour) {
      console.log('CHANGE COLOUR', index, colour)
      this.user.blueprint[index].colour = colour
      this.saveBlueprint()
    },
    openPriority(groupIndex,priorityIndex) {
      console.log('OPEN', groupIndex, priorityIndex)
      this.selectedPriorityIndex.group = groupIndex
      this.selectedPriorityIndex.priority = priorityIndex
      this.priority = this.user.blueprint[groupIndex].priorities[priorityIndex]
      // this.groupIndex = groupIndex
      // this.priorityIndex = priorityIndex
      // this.selectedGroup = group
      // this.selectedPriority = priority
      this.priorityDialog = true
    },
    deletePriority(confirmed) {
      if (this.deleteGroupIndex >= 0 && this.deletePriorityIndex >= 0) {
        if (!confirmed) {
          // ------ Confirm Delete ------
          this.deletePriorityDialog = true
        } else {
          // ------ Delete Priority ------
          this.deletePriorityDialog = false
          this.user.blueprint[this.deleteGroupIndex].priorities.splice(this.deletePriorityIndex,1)
          this.deleteGroupIndex = null
          this.deletePriorityIndex = null
          this.saveBlueprint()
        }
      }
    },
    groupMoving(event) {
      const from = event.from.classList.value.replace('group=','')
      const to = event.to.classList.value.replace('group=','')
      if (this.movingGroup.from == null) {  
        this.movingGroup.from = from
      }
      if (this.movingGroup.to == null && from != to) {
        // ------------ Target Group Set ------------
        document.getElementsByClassName('v-chip').item(to).classList.add('highlight')
        this.movingGroup.to = to
      } else if (this.movingGroup.to != to) {
        if (from != to) {
          // ------------ Target Group Changed ------------
          document.getElementsByClassName('v-chip').item(this.movingGroup.to).classList.remove('highlight')
          document.getElementsByClassName('v-chip').item(to).classList.add('highlight')
          this.movingGroup.to = to
        }
      }
      return false
    },
    groupMoved() {
      if (this.movingGroup.from && this.movingGroup.to) {
        let blueprint = this.user.blueprint
        let element = blueprint[this.movingGroup.from]
        blueprint.splice(this.movingGroup.from, 1)
        blueprint.splice(this.movingGroup.to, 0, element)
        this.user.blueprint = blueprint
        this.movingGroup.to = null
        this.movingGroup.from  = null
        this.saveBlueprint() // Save changes
      }
    },
    convertToLongDateFormat(date) {
      // converts YYYY-MM-DD to January 12th, 2023
      let luxon = DateTime.fromFormat(date, 'yyyy-LL-dd')
      return luxon.toFormat('LLLL dd, yyyy')
    },
    getYear() {
      return DateTime.now().toFormat('yyyy')
    },
    calendarDate() {
      return DateTime.now().toFormat('yyyy-LL-dd')
    },
    colour(index) {
      return this.$store.getters.colour(index)
    },
    border(index) {
      if (this.user.profile.level == 6 || this.user.profile.level == 7 || this.user.profile.level == 10) return {'border': '3px solid #ffec3b'}
      if (this.colour(index))
        return {'border-left': 'thick solid ' + this.colour(index).hex['lighten-3']}
    },
  },
  watch: {
    user() {
      console.log('User Delta in Priorities.')
      this.loadBlueprint()
    },
  },
  computed: {
    colours() {
      return this.$store.getters.colours
    },
    targetGoalsCompleted() {
      let goals = []
      this.user.blueprint.forEach(group => {
        group.priorities.forEach(priority => {
          if (priority.goals) {
            priority.goals.forEach(goal => {
              if (goal.progress.target == this.getYear() && goal.progress.finish != null) {
                // deep copy goal
                let goalCopy = JSON.parse(JSON.stringify(goal))
                goalCopy.group = group
                goalCopy.priority = priority
                goals.push(goalCopy)
              }
            })
          }
        })
      })
      return goals
    },
    targetGoalsInProgress() {
      let goals = []
      this.user.blueprint.forEach(group => {
        group.priorities.forEach(priority => {
          if (priority.goals) {
            priority.goals.forEach(goal => {
              if (goal.progress.target == this.getYear() && goal.progress.finish == null) {
                // deep copy goal
                let goalCopy = JSON.parse(JSON.stringify(goal))
                goalCopy.group = group
                goalCopy.priority = priority
                goals.push(goalCopy)
              }
            })
          }
        })
      })
      return goals
    },
  }
}
</script>
<style lang="scss" scoped>
  .v-chip.highlight {
    outline: medium solid white;
  }
  .v-card.draggable {
    cursor: pointer;
  }
  .priority {
    .options {
      visibility: hidden;
    }
  }
  .priority:hover {
    .options {
      visibility: visible;
    }
  }
  .group-header {
    .options {
      visibility: hidden;
    }
  }
  .group-header:hover {
    .options {
      visibility: visible;
    }
  }
  .targetGoal {
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    padding: 10px;
  }
  .targetGoal.completed {
    background: #c8e7c9;
    border-left: 1px solid #aad3ac;
    border-right: 1px solid #aad3ac;
    border-top: 1px solid #aad3ac;
  }
  .targetGoal.first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .targetGoal.last {
    border-bottom: 1px solid #e3e3e3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .targetGoal.last.completed {
    border-bottom: 1px solid #aad3ac;
  }
</style>