<template>
  <div class="container">
    <h1>Admin</h1>
    <v-card>
      <v-card-text>
        
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { getFirestore, doc as test, setDoc, updateDoc, deleteDoc, addDoc, collection, getDocs } from 'firebase/firestore'
export default {
  name: 'Admin',
  data() {
    return {
      
    }
  },
  methods: {
    async migrate() {
      // const db = getFirestore()
      // const users = collection(db, 'users')
      // const pioneers = collection(db, 'pioneers')
      // const q = await getDocs(users)
      // let count = 0
      // let list = []
      // q.forEach((doc) => {
      //   if (doc.data().blueprint != undefined && doc.data().blueprint.length == 0) {
          // console.log(doc.id, ' => ', doc.data())
          // console.log(doc.data())
          // this.users.push(doc.data())
          // this.$forceUpdate()
          // count++
          // Add a document to pioneers collection with a defined id
          // let pioneerData = {
          //   id: doc.data().uid,
          //   email: doc.data().profile.email,
          //   name: doc.data().profile.name,
          //   avatar: doc.data().profile.avatar,
          // }
          // console.log(pioneerData.email, doc.data().blueprint.length)
          // // if (doc.data().profile.email == 'aaron@farrindustries.com') deleteDoc(doc.ref)

          // let x = test(db, "pioneers", pioneerData.email)
          // setDoc(x, pioneerData);
          
          // deleteDoc(doc.ref)
        // }
        // Add new record to pioneers collection
      // })
      // 



      // console.log(count, 'vs', q.size)
    }
  }
}
</script>
