<template>
  <v-sheet class="lesson-player d-flex flex-row" dark>
    <div class="flex-1" v-if="expanded">
      <video ref="video" class="w-100 d-block">
        <source :src="videos[currentTrackIndex].src" type="video/mp4">
        <!-- Add additional source tags for different video formats if needed -->
      </video>
    </div>
    <div v-else class="flex-1 pl-4 d-flex align-center">
      <!-- inline player -->
    </div>
    <div class="flex-1 py-2 px-4" v-if="expanded">
      <div class="overline grey--text align-center text-truncate">
        Now Playing
      </div>
      <div class="white--text lifeos-font-sans">
        Welcome video
      </div>
      <div class="mt-2">
        <v-btn @click="togglePlay()" :style="{'border': user.profile.level == 2 ? '3px solid #ffec3b':''}">
          <v-icon v-if="isPlaying">mdi-pause</v-icon>
          <v-icon v-else>mdi-play</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else class="flex-1 d-flex align-center">
      <!-- inline controls -->
    </div>
    <div class="chevron">
      <v-btn icon dark @click="expanded=!expanded"><v-icon>{{ expanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
    </div>
    <!-- <audio ref="audio" :src="currentTrack.src" :loop="loop" :volume="volume" @timeupdate="updateProgress" @loadedmetadata="trackLoaded" @ended="onEnded"></audio>

    <div v-if="expanded" class="expanded d-flex flex-row align-center">
      <div class="thumbnail d-none d-md-flex">
        <v-img :src="currentTrack.thumb"></v-img>
      </div>
      <div class="information d-flex flex-column flex-1 pa-2 overflow-hidden">
        <div class="overline px-2 grey--text align-center text-truncate">
          {{ currentTrack.title }} - {{ currentTrack.artist }}
        </div>
        <div class="d-flex flex-row align-center">
          <v-slider
            v-model="progress"
            @start="seeking = true"
            @end="seek"
            step="1"
            min="0"
            :max="duration"
            hide-details>
          </v-slider>
          <div class="caption white--text">{{ progress | formatTime }} / {{ duration | formatTime }}</div>
        </div>
        <div class="controls d-flex flex-row">
          <v-btn icon dark @click="previousTrack"><v-icon>mdi-skip-previous</v-icon></v-btn>
          <v-btn icon dark @click="togglePlay"><v-icon>{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon></v-btn>
          <v-btn icon dark @click="nextTrack"><v-icon>mdi-skip-next</v-icon></v-btn>
        </div>
      </div>
      <div class="chevron flex-end">
        <v-btn icon dark @click="expanded=!expanded"><v-icon>{{ expanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
      </div>
    </div>
    <div v-else class="compact d-flex flex-row align-center">
      <div class="controls">
        <v-btn icon dark @click="previousTrack"><v-icon>mdi-skip-previous</v-icon></v-btn>
        <v-btn icon dark @click="togglePlay"><v-icon>{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon></v-btn>
        <v-btn icon dark @click="nextTrack"><v-icon>mdi-skip-next</v-icon></v-btn>
      </div>
      <div class="information overline px-2 grey--text align-center text-truncate flex-1">
        {{ currentTrack.title }} - {{ currentTrack.artist }}
      </div>
      <div class="chevron flex-end">
        <v-btn icon dark @click="expanded=!expanded"><v-icon>{{ expanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
      </div>
    </div> -->
  </v-sheet>
</template>
<script>
import { getFirestore, doc, setDoc, collection } from 'firebase/firestore'
export default {
  name: 'LessonPlayer',
  props: ['user'],
  data () {
    return {
      videos: [
        { title: 'Getting Started', artist: 'Life OS', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fvideo%2Ftutorials%2Fwaterfall.mp4?alt=media&token=a8d27d42-65d6-437f-90e8-aed93506d40a', thumb: ''},
        { title: 'Getting Started', artist: 'Life OS', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fvideo%2Ftutorials%2Faurora-borealis.mp4?alt=media&token=4b533295-1292-4809-be9e-43c4c7e0ec72', thumb: ''},
      ],
      expanded: true,
      currentTrackIndex: 0,
      isPlaying: false,
      loop: false,
      volume: 1,
      progress: 0,
      duration: 0,
      seeking: false,
    }
  },
  methods: {
    trackLoaded() {
      this.duration = this.$refs.audio.duration
      if (this.isPlaying) this.$refs.audio.play()
    },
    togglePlay() {
      const video = this.$refs.video
      if (video.paused) {
        video.play()
        this.isPlaying = true
        // Mission 2
        if (this.user.profile.level == 2) {
          this.user.profile.missions.push('mission-2')
          this.user.profile.level = 3
          // 
          const db = getFirestore()
          const usersCollection = collection(db, 'users')
          const userRef = doc(usersCollection, this.user.uid);
          setDoc(userRef, this.user, { merge: true }).then(response => {
            console.log(response, 'SAVED!')
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        video.pause()
        this.isPlaying = false
      }
    },
    nextTrack() {
      this.currentTrackIndex = (this.currentTrackIndex + 1) % this.tracks.length
      this.$refs.audio.load()
    },
    previousTrack() {
      this.currentTrackIndex =
        (this.currentTrackIndex - 1 + this.tracks.length) % this.tracks.length
      this.$refs.audio.load()
    },
    onEnded() {
      this.progress = 0
      this.nextTrack()
    },
    seek() {
      const audio = this.$refs.audio
      audio.currentTime = this.progress
      this.seeking = false
    },
    updateProgress() {
      if (this.seeking) return false
      const audio = this.$refs.audio
      this.progress = audio.currentTime
    },
    setVolume() {
      this.$refs.audio.volume = this.volume
    },
  },
  filters: {
    formatTime: function (value) {
      if (!value) return '00:00';
      const seconds = parseInt(value % 60);
      const minutes = parseInt((value / 60) % 60);
      const hours = parseInt(value / 3600);
      if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      } else {
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
      }
    },
  },
  computed: {
    currentTrack() {
      return this.tracks[this.currentTrackIndex]
    }
  }
}
</script>