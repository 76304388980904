<template>
  <v-dialog v-model="visibility" max-width="960">
    <v-card>
      <v-progress-linear :value=100 :color="colour.hex['lighten-2']"></v-progress-linear>
      <v-card-title class="lifeos-dialog-title lifeos-font-serif d-flex align-center">
        <div v-if="!editTitle" @click="editTitle = true">{{ goal.title }}</div>
        <v-text-field 
          v-if="editTitle"
          v-model="goal.title"
          single-line
          label="Goal Title"
          autofocus
          dense
          hide-details
          append-outer-icon="mdi-keyboard-return"
          @click:append-outer="editTitle = false"
          @keydown.enter="editTitle = false" 
          @blur="editTitle = false" 
          class="lifeos-dialog-title"
          maxlength="48"
        ></v-text-field>
        <v-spacer></v-spacer>
        <!-- <v-btn icon @click="visibility = false"><v-icon>mdi-close</v-icon></v-btn> -->
      </v-card-title>
      <!-- <v-card-title>
        <span class="text-h6">{{ goal.title }}</span>
      </v-card-title> -->
      <!-- ------------ Action Wizard ------------ -->
      <v-card-text v-if="goal.wizard == true">
        <div class="d-flex flex-row text-h6">Configuration Wizard
          <v-spacer></v-spacer>
          <v-btn icon @click="goal.wizard = false"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-stepper v-model="step" vertical flat>
          <!-- Step 1 -->
          <v-stepper-step :complete="step > 1" step="1">
            First Action
          </v-stepper-step>
          <v-stepper-content step="1">
            <div class="text-h6 mb-4">What is the first action you should take to reach this goal</div>
            <v-text-field v-model="goal.actions[0].name" label="First Action" hint="Try to be as specific as possible" class="mb-4" maxlength="48"></v-text-field>
            <v-btn color="primary" @click="step = 2" :disabled="!goal.actions[0].name">
              Continue
            </v-btn>
          </v-stepper-content>
          <!-- Step 2 -->
          <v-stepper-step :complete="step > 2" step="2">
            Difficulty
          </v-stepper-step>
          <v-stepper-content step="2">
            <div class="text-h6 mb-4">Can this be completed in one sitting?</div>
            <v-radio-group v-model="goal.actions[0].difficulty" class="ml-4">
              <v-radio label="Yes, this is a straight-forward task" value="simple"></v-radio>
              <v-radio label="No, this is a complex task that will take multiple days" value="heroic"></v-radio>
            </v-radio-group>
            <v-btn color="primary" @click="step = (goal.actions[0].difficulty == 'heroic' ? 3 : 5)">
              Continue
            </v-btn>
            <v-btn text @click="step = 1">
              Back
            </v-btn>
          </v-stepper-content>
          <!-- Step 3 -->
          <v-stepper-step :complete="step > 3" step="3">
            Breaking it Down
          </v-stepper-step>
          <v-stepper-content step="3">
            <div class="text-h6 mb-4">What is your target for each day?</div>
            <div>For large actions, it is easy to lose motivation and for it to linger around longer than it ought to.</div>
            <div>To prevent this, we automatically split up large actions into more manageable pieces. So you just need to define a realstic target you can achieve each day.</div>
            <div class="font-italic mt-4 mb-6 text--gray grey--text">
              For example, if our goal is writing a book then our first action might be to write the opening paragraph. Which is no easy task.
              So, we might set our target as one sentence per day. <a href="https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Fimages%2FLOS%20Sub%20Action%20DIagram.png?alt=media&token=9b93bdad-381f-45e4-a3dc-aa2398667130" target="_blank">Diagram</a>.
            </div>
            <v-text-field v-model="goal.actions[0].target" label="Ideal Target" hint="Try to be as realistic as possible" class="mb-4" maxlength="48"></v-text-field>
            <v-btn color="primary" @click="step = 4">
              Continue
            </v-btn>
            <v-btn text @click="step = 2">
              Back
            </v-btn>
          </v-stepper-content>
          <!-- Step 4 -->
          <v-stepper-step :complete="step > 4" step="4">
            Too small to fail
          </v-stepper-step>
          <v-stepper-content step="4">
            <div class="text-h6 mb-4">What is the smallest step you can take?</div>
            <div>
              This step is optional, but is helpful for particularly troublesome tasks. 
              The idea is to keep momentum by setting a bare minimum you can achieve no matter what. 
              A task so small you cannot fail, like ridiculously small. 
              So on days when you cannot achieve your target, you still progress.
            </div>
            <div class="font-italic mt-4 mb-6 text--gray grey--text">
              Say our goal is meditate. Our target might be to meditate for 10 minutes each day. In that case, our minimum might be a single breath.
              So on those hectic days when we cannot find the time to meditate, we can at least take a moment for a single breath.
            </div>
            <v-text-field v-model="goal.actions[0].minimum" label="Minimum Target" hint="Make this ridiculously small" class="mb-4" maxlength="48"></v-text-field>
            <v-btn color="primary" @click="step = 5">
              Continue
            </v-btn>
            <v-btn text @click="step = 3">
              Back
            </v-btn>
          </v-stepper-content>
          <!-- Step 5 -->
          <v-stepper-step :complete="step > 5" step="5">
            Reward
          </v-stepper-step>
          <v-stepper-content step="5">
            <div class="text-h6 mb-4">What will you reward yourself with?</div>
            <div class="mb-6">This step is optional, but can be a powerful motivator.</div>
            <v-text-field v-model="goal.actions[0].reward" label="Reward" hint="Leave blank for no reward" class="mb-4" maxlength="48"></v-text-field>
            <v-btn color="primary" @click="goal.wizard = false">
              Complete
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <div v-else><!-- Wizard hidden -->
        <!-- ------------ Progress ------------ -->
        <!-- <v-card-text>
          <div class="text-h5 mb-4">Progress</div>
          <v-progress-linear :value="goal.progress.current" height="10" class="mb-4"></v-progress-linear>
        </v-card-text> -->
        <!-- ------------ Heroic Action ------------ -->
        <v-card-text v-if="goal.actions.length && goal.actions[0].difficulty == 'heroic' && goal.actions[0].complete == false">
          <div class="text-h5 mb-4">{{ goal.actions[0].name }} - Heroic Action</div>
          <div class="actions pa-4">
            <!-- ------ Progress ------ -->
            <div class="heroic align-center mb-3">
              <div class="d-flex">
                <div class="text-sm-h6 text-xs-subtitle1">Progress</div>
              </div>
              <div class="progress d-flex flex-row align-center flex-1 ml-4">
                <div class="box" @click="progressHeroicAction(goal); index < goal.actions[0].progress.current ? goal.actions[0].progress.current = index : goal.actions[0].progress.current = index+1" v-for="(progress, index) in goal.actions[0].progress.target" v-bind:key="index" :class="(index < goal.actions[0].progress.current ? 'complete ' : 'incomplete') + (goal.actions[0].progress.target > 30 ? ' continuous ' : ' finite ') + (index == goal.actions[0].progress.current-1 ? 'last' : '')" :style="{'background' : (index < goal.actions[0].progress.current) ? colour.hex['lighten-2'] : '#efefef'}"></div>
              </div>
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="300" offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <div class="d-flex flex-column">
                      <div class="d-flex align-center">Progress Target</div>
                      <v-slider v-model="goal.actions[0].progress.target" thumb-label hide-details dense max="100" min="1"></v-slider>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="goal.actions[0].progress.target = 10">
                      Use Defaults
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="menu = false">
                      Okay
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
            <!-- ------ Target ------ -->
            <div class="heroic target flex-sm-row flex-column mb-3" :style="{'border': user.profile.level == 11 ? '3px solid #ffec3b':''}">
              <div class="text-sm-h6 text-xs-subtitle1 mr-4">Target Effort</div>
              <div class="d-flex flex-1">
                <v-text-field 
                  v-if="heroic.target"
                  v-model="goal.actions[0].target"
                  single-line
                  label="Target"
                  hide-details
                  autofocus
                  dense
                  append-outer-icon="mdi-keyboard-return" 
                  @click:append-outer="heroic.target = false" 
                  @keydown.enter="heroic.target = false" 
                  @blur="heroic.target = false" 
                  maxlength="48"
                ></v-text-field>
                <div v-else class="overline flex-1" :class="goal.actions[0].target == '' ? 'font-italic font-weight-regular grey--text border' : 'text-h6 ' + colour.text['class']" @click="heroic.target = true" :style="goal.actions[0].target == '' ? 'border-bottom: 1px solid grey' : ''">{{ goal.actions[0].target ? goal.actions[0].target : 'ex. read a chapter' }}</div>
                <v-btn v-if="!heroic.target" icon small @click="heroic.target = true"><v-icon small>mdi-pencil</v-icon></v-btn>
              </div>
            </div>
            <!-- ------ Minimum ------ -->
            <div class="heroic minimum flex-sm-row flex-column mb-3">
              <div class="text-sm-h6 text-xs-subtitle1 mr-4">Minimum Effort</div>
              <div class="d-flex flex-1">
                <v-text-field 
                  v-if="heroic.minimum"
                  v-model="goal.actions[0].minimum"
                  single-line
                  label="Target"
                  hide-details
                  autofocus
                  dense
                  append-outer-icon="mdi-keyboard-return" 
                  @click:append-outer="heroic.minimum = false" 
                  @keydown.enter="heroic.minimum = false" 
                  @blur="heroic.minimum = false" 
                  maxlength="48"
                ></v-text-field>
                <div v-else class="overline flex-1" :class="goal.actions[0].minimum == '' ? 'font-italic font-weight-regular grey--text border' : 'text-h6 ' + colour.text['class']" @click="heroic.minimum = true" :style="goal.actions[0].minimum == '' ? 'border-bottom: 1px solid grey' : ''">{{ goal.actions[0].minimum ? goal.actions[0].minimum : 'ex. read a paragraph' }}</div>
                <v-btn v-if="!heroic.minimum" icon small @click="heroic.minimum = true"><v-icon small>mdi-pencil</v-icon></v-btn>
              </div>
            </div>
            <!-- ------ Reward ------ -->
            <div class="heroic reward flex-sm-row flex-column" :style="{'border': user.profile.level == 11 ? '3px solid #ffec3b':''}">
              <div class="text-sm-h6 text-xs-subtitle1 mr-4">Reward</div>
              <div class="d-flex flex-1">
                <v-text-field 
                  v-if="heroic.reward"
                  v-model="goal.actions[0].reward"
                  single-line
                  label="Target"
                  hide-details
                  autofocus
                  dense
                  append-outer-icon="mdi-keyboard-return" 
                  @click:append-outer="heroic.reward = false" 
                  @keydown.enter="heroic.reward = false" 
                  @blur="heroic.reward = false" 
                  maxlength="48"
                ></v-text-field>
                <div v-else class="overline flex-1" :class="goal.actions[0].reward == '' ? 'font-italic font-weight-regular grey--text border' : 'text-h6 ' + colour.text['class']" @click="heroic.reward = true" :style="goal.actions[0].reward == '' ? 'border-bottom: 1px solid grey' : ''">{{ goal.actions[0].reward ? goal.actions[0].reward : 'ex. sleep in' }}</div>
                <v-btn v-if="!heroic.reward" icon small @click="heroic.reward = true"><v-icon small>mdi-pencil</v-icon></v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
        <!-- ------------ Actions ------------ -->
        <v-card-text>
          <div class="text-h5 mb-4">Actions</div>
          <!-- ------------ No Actions ----------- -->
          <v-alert v-if="goal.actions.length == 0" dense text>
            <div class="d-flex flex-column flex-sm-row align-center">
              Add your first action with the wizard tool<v-spacer></v-spacer>
              <v-btn class="mt-4 mt-sm-0" color="primary" small @click="openWizard()">Open Wizard</v-btn>
            </div>
          </v-alert>
          <!-- ------------ Action List ------------ -->
          <div v-else>
            <draggable group="group" ghost-class="ghost" animation="200" class="actions" @start="dragging = true" @end="verifyActions" :list="goal.actions" delay='400' delay-on-touch-only='true'>
              <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
                <div v-for="(action, index) in goal.actions" :key="index" class="d-flex flex-row align-center" :class="(action.complete == 1 ? 'action completed ' : 'action pending ')" :style="{'background': index == 0 ? colour.hex['lighten-4'] + ' !important' : ''}">
                  <v-icon class="handle">mdi-drag</v-icon>
                  <div class="name">
                    <v-text-field v-if="action.edit" v-model="action.name" autofocus hide-details dense append-outer-icon="mdi-keyboard-return" @click:append-outer="saveAction(action)" @keydown.enter="saveAction(action)" @blur="saveAction(action)" placeholder="Enter action description" maxlength="48"></v-text-field>
                    <span v-if="!action.edit && action.name" class="text-overline">{{ action.name }}</span>
                    <span v-if="!action.edit && !action.name" class="text-overline grey--text font-italic">Untitled</span>
                  </div>
                  <div class="options">
                    <v-btn class="d-none d-md-block" icon @click="deleteAction(index)"><v-icon color="#757575">mdi-delete</v-icon></v-btn>
                    <v-btn class="d-none d-md-block" icon @click="action.edit = true"><v-icon color="#757575">mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="action.difficulty == 'simple'" class="d-none d-md-block" icon @click="toggleDifficulty(action)"><v-icon color="#757575">mdi-arm-flex</v-icon></v-btn>
                  </div>
                  <v-btn v-if="action.difficulty == 'heroic'" icon @click="toggleDifficulty(action)"><v-icon>mdi-arm-flex</v-icon></v-btn>
                  <v-btn icon @click="toggleAction(index)"><v-icon>{{ action.complete ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></v-btn>
                  <v-menu transition="slide-x-transition" bottom right min-width="200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" class="d-sm-flex d-md-none" :style="{'border': user.profile.level == 11 && index == 0 ? '3px solid #ffec3b':''}">
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <!-- 
                          Move Up - Rules
                          - Can't be the first in the list AND
                          - Can't be the first incomplete action
                        -->
                        <v-list-item v-if="index > 0 && index != firstCompletedAction" @click="move(index, -1)">
                          <v-list-item-icon>
                            <v-icon>mdi-chevron-up</v-icon>
                          </v-list-item-icon>
                          Move Up
                        </v-list-item>
                        <!--
                          Move Down - Rules:
                          - Can't be the last in the list AND
                          - Can't be the last incomplete action
                        -->
                        <v-list-item v-if="index < goal.actions.length-1 && index != firstCompletedAction-1" @click="move(index, 1)">
                          <v-list-item-icon>
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-list-item-icon>
                          Move Down
                        </v-list-item>
                        <v-list-item @click="toggleDifficulty(action)" :style="{'border': user.profile.level == 11 ? '3px solid #ffec3b':''}">
                          <v-list-item-icon>
                            <v-icon>mdi-arm-flex</v-icon>
                          </v-list-item-icon>
                          {{ action.difficulty == 'simple' ? 'Set Heroic' : 'Remove Heroic' }}
                        </v-list-item>
                        <v-list-item @click="action.edit = true">
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          Edit
                        </v-list-item>
                        <v-list-item @click="deleteAction(index)">
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          Delete
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </transition-group>
            </draggable>
          </div>
          <!-- ------------ Add Action ------------ -->
          <div class="lifeos-transparent-button my-2 px-2 py-1 text-overline" @click="addAction(true)" :style="{'border': user.profile.level == 8 ? '3px solid #ffec3b':''}">
            <v-icon small>mdi-plus</v-icon> Add Action
          </div>
        </v-card-text>
        <!-- ------------ Notes ------------ -->
        <v-card-text>
          <div class="text-h5 mb-4">Notes</div>
          <v-textarea outlined v-model="goal.notes" label="Notes" maxlength="1028"></v-textarea>
        </v-card-text>
        <!-- ------------ Settings ------------ -->
        <!-- <v-card-text>
          <div class="text-h5 mb-4">Settings</div>
          <v-select v-model="goal.type" :items="goalTypes" outlined label="Goal Type"></v-select>
          <v-select v-model="goal.frequency" :items="goalFrequencies" outlined label="Frequency"></v-select>
          <v-text-field v-model="goal.frequency" outlined label="Frequency" maxlength="48"></v-text-field>
        </v-card-text> -->
      </div><!-- Wizard hidden -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="visibility = false" text>Save & Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import Draggable from "vuedraggable"
  import { getFirestore, collection, getDocs, getDoc, setDoc, doc, updateDoc, addDoc, ref } from 'firebase/firestore'
  import { DateTime } from "luxon"
  export default {
    name: 'Goal',
    props: ['user', 'goal', 'colour'],
    components: {
      Draggable
    },
    data() {
      return {
        visibility: true,
        loading: true,
        goalTypes: [
          'standard',
          'discrete',
          'continuous',
          'passive'
        ],
        editTitle: false,
        step: 1,
        menu: false,
        heroic: {
          target: false,
          minimum: false,
          reward: false
        },
        dragging: false,
      }
    },
    created() {
      
    },
    methods: {
      addAction(edit = false) {
        // Close all other actions
        this.goal.actions.forEach(action => {
          action.edit = false
        })
        // Add new action
        // this.goal.actions.push({
        let action = {
          name: '',
          difficulty: 'simple',
          target: '',
          minimum: '',
          reward: '',
          notes: '',
          progress: {
            current: 0,
            target: 10,
            start: this.calendarDate(),
            finish: null,
            last: null, // last effort toward action
            // streak: 0,
            selected: null
          },
          edit: edit,
          complete: false
        }
        // insert before first completed action
        let index = this.findFirstCompletedAction()
        if (index == -1) {
          this.goal.actions.push(action)
        } else {
          this.goal.actions.splice(index,0,action)
        }
      },
      findFirstCompletedAction() {
        return this.goal.actions.findIndex( a => a.complete == true )
      },
      saveAction(action) {
        action.edit = false
      },
      toggleDifficulty(action) {
        action.difficulty == 'simple' ? action.difficulty = 'heroic' : action.difficulty = 'simple'
      },
      toggleAction(index) {
        // move action before the first completed action
        let firstCompleted = this.findFirstCompletedAction()
        if (this.goal.actions[index].complete == false) {
          this.goal.actions[index].complete = true
          this.goal.actions[index].progress.finish = this.calendarDate()
          this.goal.progress.last = this.calendarDate()
          // ------------ Trigger Reward ------------
          if (this.goal.actions[index].reward != '') {
            // console.log('Reward: ' + this.goal.actions[index].reward)
            this.$emit('reward', this.goal.actions[index].reward)
          }
          // ------------ Reorder Action ------------
          if (firstCompleted == -1) {
            this.goal.actions.push(this.goal.actions[index])
            this.deleteAction(index)
          } else {
            const element = this.goal.actions.splice(index, 1)[0];
            this.goal.actions.splice(firstCompleted-1, 0, element);
          }
        } else {
          this.goal.actions[index].complete = false
          this.goal.actions[index].progress.finish = null
          const element = this.goal.actions.splice(index, 1)[0];
          this.goal.actions.splice(firstCompleted, 0, element);
        }
      },
      deleteAction(index) {
        this.goal.actions.splice(index,1)
      },
      verifyActions() {
        // ------------ Validate actions ------------
        // Ensure no incomplete actions are below the first completed action
        let completeActions = this.goal.actions.filter( a => a.complete == true )
        let incompleteActions = this.goal.actions.filter( a => a.complete == false )
        this.goal.actions = incompleteActions.concat(completeActions)
        // 
        this.dragging = false
      },
      openWizard() {
        this.goal.wizard = true
        this.addAction()
      },
      move(index, delta) {
        // If direction is up, swap with previous
        let buffer = this.goal.actions[index]
        this.goal.actions[index] = this.goal.actions[index + delta]
        this.goal.actions[index + delta] = buffer
        this.$forceUpdate()
      },
      progressHeroicAction(goal) {
        let action = goal.actions[0]
        action.progress.last = this.calendarDate()
      },
      calendarDate() {
        return DateTime.now().toFormat('yyyy-LL-dd')
      },
    },
    watch: {
      visibility() {
        if (!this.visibility) this.$emit('close')
      }
    },
    computed: {
      firstCompletedAction() {
        return this.goal.actions.findIndex( a => a.complete == true )
      },
    }
  }
</script>
<style lang="scss" scoped>
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
    border: none;
    // background: #efefef;
    // border: 2px solid #9e9e9e !important;
  } 
  .actions {
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    overflow: hidden;
  }
  .heroic.actions {
      padding: 15px;
  }
  .action {
    border-bottom: 1px solid #9e9e9e;
    padding: 4px 8px;
    padding-left: 24px;
    min-height: 35px;
    .handle {
      cursor: move;
      display: none;
    }
    .name {
      flex: 1;
    }
    .options {
      cursor: move;
      display: none;
    }
  }
  .action:hover {
    padding-left: 0;
    .handle {
      display: block;
    }
    .options {
      display: flex;
      flex-direction: row;
    }
  }
  .action:last-child {
    border-bottom: none;
  }
  .action.pending {
    .v-btn {
      color: black;
    }
  }
  .action.completed {
    background: #efefef;
    .v-btn {
      color: #9e9e9e;
    }
    .name {
      color: #9e9e9e;
    }
    .text-overline {
      text-decoration: line-through;
    }
  }
  // --- Progress Bar --- //
  .box {
    flex: 1;
    min-height: 25px;
    max-block-size: 25px;
    background: #efefef;
    box-sizing: border-box;
  }
  .box.finite {
    margin-right: 2px;
    border-radius: 2px;
  }
  .box.finite.complete.white {
    border: 1px solid grey !important;
  }
  .box.continuous.complete.white {
    border-top: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
  }
  .box.continuous.complete.white:first-child {
    border-left: 1px solid grey !important;
  }
  .box.continuous.complete.white.last {
    border-right: 1px solid grey !important;
  }
  .box.continuous:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .box.continuous:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  //
  .heroic {
    display: flex;
    flex-direction: row;
  }
  .v-input.v-input--hide-details {
    margin: 0;
    padding: 0;
  }
</style>