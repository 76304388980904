import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAvMEcDzPgxGmfpMRFo3ECxVDsijMQBYoM",
  authDomain: "lifeos.app",
  databaseURL: "https://life-os-app.firebaseio.com",
  projectId: "life-os-app",
  storageBucket: "life-os-app.appspot.com",
  messagingSenderId: "693106696905",
  appId: "1:693106696905:web:2748e290fd854d44"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);