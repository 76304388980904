<template>
  <v-sheet class="lofi-player" dark>
    <audio ref="audio" :src="currentTrack.src" :loop="loop" :volume="volume" @timeupdate="updateProgress" @loadedmetadata="trackLoaded" @ended="onEnded"></audio>
    <div v-if="expanded" class="expanded d-flex flex-row align-center">
      <div class="thumbnail d-none d-md-flex">
        <v-img :src="currentTrack.thumb"></v-img>
      </div>
      <div class="information d-flex flex-column flex-1 pa-2 overflow-hidden">
        <div class="overline px-2 grey--text align-center text-truncate">
          {{ currentTrack.title }} - {{ currentTrack.artist }}
        </div>
        <div class="d-flex flex-row align-center">
          <v-slider
            v-model="progress"
            @start="seeking = true"
            @end="seek"
            step="1"
            min="0"
            :max="duration"
            hide-details>
          </v-slider>
          <div class="caption white--text">{{ progress | formatTime }} / {{ duration | formatTime }}</div>
        </div>
        <div class="controls d-flex flex-row">
          <v-btn icon dark @click="previousTrack"><v-icon>mdi-skip-previous</v-icon></v-btn>
          <v-btn icon dark @click="togglePlay"><v-icon>{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon></v-btn>
          <v-btn icon dark @click="nextTrack"><v-icon>mdi-skip-next</v-icon></v-btn>
        </div>
      </div>
      <div class="chevron flex-end">
        <v-btn icon dark @click="expanded=!expanded"><v-icon>{{ expanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
      </div>
    </div>
    <div v-else class="compact d-flex flex-row align-center">
      <div class="controls">
        <v-btn icon dark @click="previousTrack"><v-icon>mdi-skip-previous</v-icon></v-btn>
        <v-btn icon dark @click="togglePlay"><v-icon>{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon></v-btn>
        <v-btn icon dark @click="nextTrack"><v-icon>mdi-skip-next</v-icon></v-btn>
      </div>
      <div class="information overline px-2 grey--text align-center text-truncate flex-1">
        {{ currentTrack.title }} - {{ currentTrack.artist }}
      </div>
      <div class="chevron flex-end">
        <v-btn icon dark @click="expanded=!expanded"><v-icon>{{ expanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
      </div>
    </div>
  </v-sheet>
</template>
<script>
export default {
  name: 'LofiPlayer',
  data () {
    return {
      tracks: [
        { title: 'a rainy afternoon in sweden', artist: 'Cozy Nordic', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Fa-rainy-afternoon-in-sweden.mp3?alt=media&token=3a9d0e35-a489-46f1-a6bc-8735bd996ea1', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Fa-rainy-afternoon-in-sweden.jpg?alt=media&token=563ad047-9e16-4749-96f1-cab8df4858da'},
        { title: 'Rainy Jazz Cafe', artist: 'Coffee Shop Vibes', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Frainy-jazz-cafe.mp3?alt=media&token=f0c2d271-9b8c-4eb6-8016-42ffa22966ca', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Frainy-jazz-cafe.jpg?alt=media&token=9712abea-a14a-40e5-b80c-40c3766e8d3f'},
        { title: 'Afternoon Jazz', artist: 'Lofi Girl Records', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Fafternoon-jazz-lofi.mp3?alt=media&token=0ae42e11-4731-4639-b0d6-1a32e06f04f3', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Fafternoon-jazz-lofi.jpg?alt=media&token=43626946-a07e-44b3-998a-08976d42d581'},
        { title: 'Soothing Breeze', artist: 'Lofi Girl Records', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Fsoothing-breeze-lofi.mp3?alt=media&token=220d8709-2226-4c97-be99-e598738a7817', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Fsoothing-breeze-lofi.jpg?alt=media&token=3a140c23-5e6a-45ab-83ab-7ae86f4a38b2'},
        { title: 'Morning Coffee', artist: 'Lofi Girl Records', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2FMorningCoffee.mp3?alt=media&token=b5187930-f1ce-4698-a2f1-28d6d547cbf7', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Flofigirl.jpg?alt=media&token=623c60d4-7aea-430d-97f1-bfe4792723f6'},
        { title: 'Flow Of Time ☯︎ Japanese LoFi HipHop Mix 時間の流れ', artist: '', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2FFlowOfTime.mp3?alt=media&token=50b1b01b-ee64-44bf-ab5d-96a96ee3c010', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2FFlowOfTime.jpg?alt=media&token=37bf6baf-0928-4ffa-9397-c5c22b6f5a04'},
        { title: 'Chill Drive - Lofi hip hop mix', 'artist': '', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2FChillDrive.mp3?alt=media&token=55e91a52-fe7d-490d-a213-80975fe24c45', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2FChillDrive.jpg?alt=media&token=6dbb9e18-aebc-402d-9a0b-568cc919d827'},
        { title: 'Follow Your Heart', artist: 'Studio Ghibli', src: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Ffollow-your-heart-lofi.mp3?alt=media&token=2b6e0de9-815d-4554-9df5-ca9a243bc4d6', thumb: 'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Fmusic%2Ffollow-your-heart-lofi.jpg?alt=media&token=2e67b696-7e2a-4b8e-b4d3-24e87ca0ee7a'},
        // { title: '', artist: '', src: '', thumb: ''},
      ],
      expanded: false,
      currentTrackIndex: 0,
      isPlaying: false,
      loop: false,
      volume: 1,
      progress: 0,
      duration: 0,
      seeking: false,
    }
  },
  methods: {
    trackLoaded() {
      this.duration = this.$refs.audio.duration
      if (this.isPlaying) this.$refs.audio.play()
    },
    togglePlay() {
      const audio = this.$refs.audio
      if (audio.paused) {
        audio.play()
        this.isPlaying = true
      } else {
        audio.pause()
        this.isPlaying = false
      }
    },
    nextTrack() {
      this.currentTrackIndex = (this.currentTrackIndex + 1) % this.tracks.length
      this.$refs.audio.load()
    },
    previousTrack() {
      this.currentTrackIndex =
        (this.currentTrackIndex - 1 + this.tracks.length) % this.tracks.length
      this.$refs.audio.load()
    },
    onEnded() {
      this.progress = 0
      this.nextTrack()
    },
    seek() {
      const audio = this.$refs.audio
      audio.currentTime = this.progress
      this.seeking = false
    },
    updateProgress() {
      if (this.seeking) return false
      const audio = this.$refs.audio
      this.progress = audio.currentTime
    },
    setVolume() {
      this.$refs.audio.volume = this.volume
    },
  },
  filters: {
    formatTime: function (value) {
      if (!value) return '00:00';
      const seconds = parseInt(value % 60);
      const minutes = parseInt((value / 60) % 60);
      const hours = parseInt(value / 3600);
      if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      } else {
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
      }
    },
  },
  computed: {
    currentTrack() {
      return this.tracks[this.currentTrackIndex]
    }
  }
}
</script>