<template>
  <v-navigation-drawer v-model="localDrawer" app disable-resize-watcher>
    <div v-if="!user" class="d-flex justify-center">
      <img src="@/resources/images/logos/logo@2x.png" dark style="width: 75%" />
    </div>
    <!-- ------ Unauthenticated ------ -->
    <div class="d-flex px-3 flex-column" v-if="!user">
      <v-btn block text @click="go('Login')">Login</v-btn>
      <v-btn block light @click="go('Signup')" color="primary">Get Started</v-btn>
    </div>
    <v-divider v-if="!user" class="mt-3"></v-divider>
    <!-- ------ Authenticated ------ -->
    <div class="d-flex" v-if="user">
      <v-list-item>
        <v-list-item-avatar>
          <img :src="user.profile.avatar" alt="Profile Image">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.profile.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.profile.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-divider v-if="user"></v-divider>
    <!-- ------ Menu ------ -->
    <v-list v-if="user">
      <div v-for="(section,sectionId) in sections" :key="sectionId">
        <!-- ------ Subsections ------ -->
        <v-list-group v-if="section.subsections" :value="sectionId + 1 == selectedSection" :prepend-icon="section.icon">
          <template v-slot:activator>
            <v-list-item-title>{{ section.title }}</v-list-item-title>
          </template>
          <v-list-item v-for="(subsection, subsectionId) in section.subsections" :key="subsectionId" link @click="go(subsection.route)" :class="(subsectionId + 1 == selectedSubSection ? 'item-selected ' : '') + (subsection.level > user.profile.level ? 'd-none' : '')" class="subitem">
            <v-list-item-title>{{ subsection.title }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>{{ subsection.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <!-- ------ Standard Link ------ -->
        <v-list-item v-else @click="go('Dashboard')" :class="sectionId + 1 == selectedSection ? 'item-selected' : ''">
          <v-list-item-icon>
            <v-icon>{{ section.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ section.title }}</v-list-item-title>
        </v-list-item>
      </div>
      <v-divider v-if="user"></v-divider>
      <v-list-item v-if="user" @click="logout()">
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  props: ['user', 'drawer', 'sections'],
  data() {
    return {
      localDrawer: false,
      values: [false,false,false,false],
      selectedSection: 0,
      selectedSubSection: 0,
    }
  },
  created() {
    // this.updateMenu()
  },
  created() {
    this.routeChange()
  },
  methods: {
    logout() {
      this.$emit('logout')
    },
    routeChange() {
      this.selectedSection = 0
      this.selectedSubSection = 0
      this.sections.forEach((section,index) => {
        if (section.subsections !== undefined) {
          section.subsections.forEach((subsection,subindex) => {
            if (this.$route.name == subsection.route) {
              this.selectedSection = index + 1
              this.selectedSubSection = subindex + 1
            }
          })
        } else {
          if (this.$route.name == section.route) {
            this.selectedSection = index + 1
          }
        }
      })
    }
  },
  watch: {
    $route() {
      this.routeChange()
    },
    drawer() {
      this.localDrawer = this.drawer
    },
    localDrawer() {
      if (!this.localDrawer) {
        this.$emit('close')
      }
    },
    logout() {
      this.$emit('logout')
    }
  }
}
</script>

<style lang="scss" scoped>
  .subitem {
    background-color: whitesmoke;
  }
  .item-selected {
    border-left: 2px solid black;
  }
</style>