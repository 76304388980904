<template>
  <div class="container">
    <v-card class="d-md-flex">
      <v-img class="white--text align-center text-center flex-1 image-row hidden-sm-and-down" src="https://source.unsplash.com/random/?nature" light>
        <img width="100" src="@/resources/images/logos/icon-white.png"><br>
        Let's Go.
      </v-img>
      <v-img class="white--text align-center text-center flex-1 image-column hidden-md-and-up" src="https://source.unsplash.com/random/?nature" light>
        <img width="100" src="@/resources/images/logos/icon-white.png"><br>
        Let's Go.
      </v-img>
      <div class="flex-1 text-center dark">
        <v-card-title class="justify-center lifeos-font-serif bold-title">{{  $route.path == '/login' ? 'Login' : 'Signup' }}</v-card-title>
        <v-card-text>
          <Authenticate />
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import Authenticate from '@/components/Authenticate'
export default {
  name: 'Hello',
  components: { Authenticate },
}
</script>
<style lang="scss" scoped>
  .image-row {
    border-top-left-radius: inherit !important;
    border-top-right-radius: unset !important;
    border-bottom-left-radius: inherit !important;
    max-height: 452px;
  }
  .image-column {
    border-top-left-radius: inherit !important;
    border-top-right-radius: inherit !important;
    border-bottom-left-radius: unset !important;
    max-height: 200px;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    color: #2196f3;
    text-decoration: dotted;
  }
</style>