<template>
  <v-dialog max-width="960" v-model="visibility">
    <v-card v-if="priority">
      <v-progress-linear :value=100 :color="colour.hex['lighten-4']"></v-progress-linear>
      <!-- ------------ Hero Image ------------ -->
      <v-img v-if="priority.cover" :src="priority.cover" height="200px" class="elevation-1"></v-img>
      <!-- ------------ Title ------------ -->
      <v-card-title class="lifeos-dialog-title d-flex flex-column align-start">
        <div class="d-flex w100 flex-wrap align-center">
          <v-chip class="ma-2 lighten-2 font-weight-regular" :class="colour.class">
            {{ category }}
          </v-chip>
          <v-icon class="mr-2">{{ priority.icon }}</v-icon> <span>{{ priority.title }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="editQuote = true" class="lifeos-dialog-title-button d-md-none" small text><v-icon small left>mdi-format-quote-close</v-icon> Set Quote</v-btn>
          <v-btn icon @click="visibility = false"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <!-- ---------------------- Quote ---------------------- -->
        <v-text-field 
          v-if="editQuote"
          v-model="priority.quote"
          single-line
          label="Quote"
          hide-details
          autofocus
          dense
          append-outer-icon="mdi-keyboard-return"
          @click:append-outer="editQuote = false"
          @keydown.enter="editQuote = false" 
          @blur="editQuote = false" 
          outlined
          filled
          placeholder="An inspiring quote"
          class="mb-4" 
          maxlength="144"
        ></v-text-field>
        <div v-if="priority.quote && !editQuote" class="d-flex">
          <span class="font-italic quote">{{ priority.quote }}</span>
          <v-spacer></v-spacer>
          <v-btn class="options" icon @click="setQuote('')"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <!-- ---------------------- Goal List ---------------------- -->
        <div class="goal-list">
          <!-- ---------------------- No Focused Goal ---------------------- -->
          <v-alert v-if="priority.goals.length == 0 || (priority.goals.length && !priority.goals[0].focus)" dense text type="info">
            You have not focused on a goal
          </v-alert>
          <!-- ---------------------- Focused Goal ---------------------- -->
          <div v-if="priority.goals.length && priority.goals[0].focus && !priority.goals[0].complete" class="goal focus d-flex flex-row align-center" :style="focusGoalStyle">
            <div class="handle" style="visibility: hidden">
              <v-icon>mdi-drag</v-icon>
            </div>
            <div class="icon mr-2">
              <v-icon :color="colour.text.dark">mdi-eye</v-icon>
            </div>
            <div class="name d-flex align-center flex-column flex-sm-row flex-1">
              <strong class="mx-2">Focused Goal</strong>
              <v-text-field 
                v-if="priority.goals[0].edit" 
                outlined 
                filled 
                v-model="priority.goals[0].title" 
                autofocus 
                hide-details 
                dense 
                append-outer-icon="mdi-keyboard-return" 
                @click:append-outer="saveGoal(priority.goals[0])" 
                @keydown.enter="saveGoal(priority.goals[0])" 
                @blur="saveGoal(priority.goals[0])" 
                placeholder="Type a goal..." 
                :dark="colour.text.dark == 'white'"
                maxlength="48"></v-text-field>
              <div v-else>{{ priority.goals[0].title ? priority.goals[0].title : 'Untitled' }}</div>
              <div v-if="priority.goals[0].progress.target" class="font-italic">&nbsp;({{ priority.goals[0].progress.target }})</div>
            </div>
            <div class="actions d-none flex-row d-sm-flex">
              <v-btn icon class="d-none d-md-flex" @click="toggleTarget(0)"><v-icon :color="colour.text.dark">{{ priority.goals[0].progress.target ? 'mdi-bullseye-arrow' : 'mdi-bullseye' }}</v-icon></v-btn>
              <v-btn icon class="d-md-flex" @click="blurGoal(0)"><v-icon :color="colour.text.dark">mdi-eye-off</v-icon></v-btn>
              <v-btn icon class="d-md-flex" @click="toggleGoal(0)"><v-icon :color="colour.text.dark">mdi-check</v-icon></v-btn>
            </div>
            <v-menu transition="slide-x-transition" bottom right min-width="230">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :style="{'border': level == 10 ? '3px solid #ffec3b':''}">
                  <v-icon :color="colour.text.dark">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item @click="toggleGoal(0)" :style="{'border': level == 10 ? '3px solid #ffec3b':''}">
                    <v-list-item-icon>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-icon>
                    Complete
                  </v-list-item>
                  <v-list-item @click="blurGoal(0)">
                    <v-list-item-icon>
                      <v-icon>mdi-eye-off</v-icon>
                    </v-list-item-icon>
                    Unfocus Goal
                  </v-list-item>
                  <v-list-item @click="toggleTarget(0)">
                    <v-list-item-icon>
                      <v-icon>{{ priority.goals[0].progress.target ? 'mdi-bullseye' : 'mdi-bullseye-arrow' }}</v-icon>
                    </v-list-item-icon>
                    {{ (priority.goals[0].progress.target ? 'Unset ' : 'Set ') + getYear() }} Target
                  </v-list-item>
                  <v-list-item @click="editGoal(priority.goals[0])">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    Rename
                  </v-list-item>
                  <v-list-item @click="deleteGoal(0)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    Delete
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <!-- ---------------------- Unfocused Goals ---------------------- -->
          <draggable class="goals text-disable-select" animation="200" group="group" @start="dragging = true" @end="validateGoals" :list="priority.goals" delay='400' delay-on-touch-only='true'>
            <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
              <div class="goal-container" v-for="(goal, goalIndex) in priority.goals" :key="goalIndex">
                <div v-if="!goal.focus" class="goal d-flex flex-row align-center" :style="goalStyle(goal, goalIndex, colour)">
                  <div class="handle">
                    <v-icon>mdi-drag</v-icon>
                  </div>
                  <div class="icon mr-2">
                    <v-icon v-if="goal.completed">mdi-flag-checkered</v-icon>
                    <v-icon v-else>mdi-flag</v-icon>
                  </div>
                  <div class="name d-flex align-center flex-column flex-sm-row flex-1">
                    <strong class="mx-2">Goal</strong>
                    <v-text-field 
                      v-if="goal.edit" 
                      class="w-100" 
                      outlined 
                      filled 
                      v-model="goal.title" 
                      autofocus 
                      hide-details 
                      dense 
                      append-outer-icon="mdi-keyboard-return" 
                      @click:append-outer="saveGoal(goal)" 
                      @keydown.enter="saveGoal(goal)" 
                      @blur="saveGoal(goal)" 
                      placeholder="Type a goal..."
                      maxlength="48"></v-text-field>
                    <div v-else>{{ goal.title ? goal.title : 'Untitled' }}</div>
                    <div v-if="goal.progress.target" class="grey--text font-italic">&nbsp;({{ goal.progress.target }})</div>
                  </div>
                  <div class="actions d-none flex-row d-sm-flex">
                    <v-btn icon v-if="!goal.completed" class="d-none d-md-flex" @click="toggleTarget(goalIndex)"><v-icon>{{ goal.progress.target ? 'mdi-bullseye-arrow' : 'mdi-bullseye' }}</v-icon></v-btn>
                    <v-btn icon v-if="!goal.completed" class="d-none d-md-flex" @click="focusGoal(goalIndex)"><v-icon>mdi-eye</v-icon></v-btn>
                    <v-btn icon @click="toggleGoal(goalIndex)"><v-icon :color="colour.hex.medium">{{ goal.completed ? 'mdi-refresh' : 'mdi-check' }}</v-icon></v-btn>
                  </div>
                  <v-menu transition="slide-x-transition" bottom right min-width="200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :style="{'border': level == 7 ? '3px solid #ffec3b':''}">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item-group>
                        <v-list-item @click="toggleGoal(goalIndex)">
                          <v-list-item-icon>
                            <v-icon>{{ goal.completed ? 'mdi-refresh' : 'mdi-check' }}</v-icon>
                          </v-list-item-icon>
                          {{ goal.completed ? 'Reactivate' : 'Complete' }}
                        </v-list-item>
                        <v-list-item v-if="!goal.completed" @click="focusGoal(goalIndex)" :style="{'border': level == 7 ? '3px solid #ffec3b':''}">
                          <v-list-item-icon>
                            <v-icon>mdi-eye</v-icon>
                          </v-list-item-icon>
                          Focus Goal
                        </v-list-item>
                        <v-list-item @click="toggleTarget(goalIndex)">
                          <v-list-item-icon>
                            <v-icon>{{ goal.progress.target ? 'mdi-bullseye' : 'mdi-bullseye-arrow' }}</v-icon>
                          </v-list-item-icon>
                          {{ goal.progress.target ? 'Unset ' : 'Set ' + getYear() }} Target
                        </v-list-item>
                        <v-list-item @click="editGoal(goal)" v-if="!goal.completed">
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          Rename
                        </v-list-item>
                        <v-list-item @click="deleteGoal(goalIndex)">
                          <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          Delete
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <!-- ------ New Goal ------ -->
        <v-btn v-if="level > 5" @click="addGoal()" text block class="overline align-left" color="grey darken-1" :style="{'border': level == 6 ? '3px solid #ffec3b':''}">
          <v-icon small left>mdi-plus</v-icon> New Goal
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="visibility = false" text>Done</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Draggable from "vuedraggable"
import { getFirestore, collection, getDocs, getDoc, setDoc, doc, updateDoc, addDoc, ref } from 'firebase/firestore'
import { DateTime } from "luxon"
export default {
  name: 'Priority',
  props: ['blueprint', 'priority', 'level', 'group', 'category'],
  components: {
    Draggable
  },
  data() {
    return {
      visibility: true,
      editQuote: false,
      optionOpen: false,
      dragging: false,
      newQuote: '',
      newCoverURL: '',
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      // Nothing to do
    },
    toggleGoal(index) {
      if (this.priority.goals[index].completed) {
        this.priority.goals[index].completed = false
        this.priority.goals[index].progress.finish = null
      } else {
        this.priority.goals[index].completed = true
        this.priority.goals[index].progress.finish = this.calendarDate()
        if (this.priority.goals[index].focus) this.blurGoal(index)
      }
      this.validateGoals()
      this.$forceUpdate()
    },
    saveGoal(goal) {
      goal.edit = false
      this.$forceUpdate()
    },
    editGoal(goal) {
      goal.edit = true
      this.$forceUpdate()
    },
    deleteGoal(index) {
      if (this.priority.goals[index].focus) {
        this.priority.goals.splice(index, 1)
        // this.blurGoal(index) // This function calls this.$forceUpdate()
      } else {
        this.priority.goals.splice(index, 1)
      }
    },
    focusGoal(index) {
      if (this.priority.goals[0].focus) this.priority.goals[0].focus = false
      this.priority.goals.unshift(this.priority.goals.splice(index, 1)[0]) // Move the focused goal to the top
      this.priority.goals[0].focus = true
    },
    blurGoal(index) {
      if (this.priority.goals[0].focus) this.priority.goals[0].focus = false
      // if (this.priority.goals[index].focus) this.priority.goals[index].focus = false
      this.$forceUpdate()
    },
    addGoal() {
      // Close all other actions
      this.priority.goals.forEach(goal => {
        goal.edit = false
      })
      // Add new goal
      let goal = {
        title: '',
        actions: [],
        focus: false,
        complete: false,
        edit: true,
        super: false,
        progress: {
          start: this.calendarDate(),
          last: null,
          finish: null
        }
      }
      // insert before first completed action
        let index = this.firstCompletedGoal
        if (index == -1) {
          this.priority.goals.push(goal)
        } else {
          this.priority.goals.splice(index,0,goal)
        }
    },
    toggleTarget(index) {
      if (this.priority.goals[index].progress.target == undefined || this.priority.goals[index].progress.target == null) {
        this.priority.goals[index].progress.target = this.getYear()
      } else {
        this.priority.goals[index].progress.target = null
      }
      this.$forceUpdate()
    },
    validateGoals() {
      // ------------ Validate Goals ------------
      // Ensure no incomplete goals are below completed goals
      let incomplete = this.priority.goals.filter( a => a.completed == false || a.completed == undefined )
      let complete = this.priority.goals.filter( a => a.completed == true )
      this.priority.goals = [].concat(incomplete, complete)
      //
      this.dragging = false
    },
    changeCover(url) {
      this.optionOpen = false
      this.priority.cover = url ? url : this.newCoverURL
      this.newCoverURL = ''
    },
    setQuote() {
      this.optionOpen = false
      this.priority.quote = this.newQuote
      this.newQuote = ''
    },
    goalStyle(goal, goalIndex, colour) {
      // ------------ Check for Focus ------------
      if (goal.focus) return this.focusGoalStyle
      // ------------ Blurred Styles ------------
      let style = {}
      // Check if completed
      if (goal.completed) {
        style.background = '#efefef'
        style.color = '#9e9e9e'
      } else { // Incomplete
        style['border-left'] = '8px solid ' + colour.hex['darken-2'] + ' !important' 
      }
      // Check if last active goal
      if (goalIndex == this.firstCompletedGoal-1) {
        style['border-bottom-left-radius'] = '4px'
        style['border-bottom-right-radius'] = '4px'
        style['margin-bottom'] = '10px'
      }
      // Check if first completed goal
      if (goalIndex == this.firstCompletedGoal) {
        style['border-top-left-radius'] = '4px'
        style['border-top-right-radius'] = '4px'
        style['border-top'] = '1px solid grey'
      }
      // Check if first unfocused goal
      if ((goalIndex == 0 && this.priority.goals[0].focus == false) || 
          (goalIndex == 1 && this.priority.goals[0].focus == true)
      ) {
        style['border-top-left-radius'] = '4px'
        style['border-top-right-radius'] = '4px'
        style['border-top'] = '1px solid grey'
      }
      // Check if last goal
      if (goalIndex == this.priority.goals.length-1) {
        style['border-bottom-left-radius'] = '4px'
        style['border-bottom-right-radius'] = '4px'
      }
      return style
    },
    getYear() {
      return DateTime.now().toFormat('yyyy')
    },
    calendarDate() {
      return DateTime.now().toFormat('yyyy-LL-dd')
    },
  },
  computed: {
    colour() {
      return this.$store.getters.colour(this.group.colour)
    },
    focusGoalStyle() {
      return {
        'background': this.colour.hex['darken-2'] + ' !important',
        'color': this.colour.text.dark,
        'border-left': '8px solid ' + this.colour.hex['lighten-2'] + ' !important',
      }
    },
    firstBlurredGoal() {
      return this.priority.goals.findIndex( a => a.focus == false )
    },
    firstCompletedGoal() {
      return this.priority.goals.findIndex( a => a.completed == true )
    },
    focusedGoal() {
      return this.priority.goals.filter( a => a.focus == true )
    },
  },
  watch: {
    visibility(visible) {
      if (!visible) this.$emit('close')
    },
    priority(priority) {
      console.log('Priority changed!')
      this.initialize()
    },
  },
}
</script>
<style lang="scss" scoped>
  .goal-complete {
    background: #f1f1ef;
    color: #787774;
  }
  .v-card.draggable {
    cursor: pointer;
  }
  .quote {
    max-width: 100%;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    caret-color: rgb(55, 53, 47);
    padding: 3px 2px;
    font-family: Lyon-Text, Georgia, ui-serif, serif;
    font-weight: 600;
    font-size: 1.875em;
    line-height: 1.3;
    color: black;
    margin-bottom: 12px;
  }
  // ------------ Goal ------------
  .goals {
    
  }
  .goal {
    border-right: 1px solid grey;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
    background: white;
    padding: 10px;
    border-left-width: 8px;
  }
  .goal:hover .options {
    display: flex;
  }
  .goal.focus {
    border: 'none';
    margin-bottom: 10px;
    border-radius: 4px;
  }
  .handle {
    visibility: hidden;
  }
  .goal:hover .handle {
    visibility: visible;
  }
  .actions {
    text-decoration: none !important;
    visibility: hidden;
  }
  .goal:hover .actions {
    visibility: visible;
  }
  .lifeos-dialog-title:hover {
    .lifeos-dialog-title-button {
      display: block !important;
    }
  }
</style>