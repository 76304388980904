<template>
  <div class="terms-of-service container">
    <v-card>
      <v-card-title>Terms of Service</v-card-title>
      <v-card-text>
        <div class="text-caption text-right">Last updated: January, 2023</div>
        <br><br>
        <section>
          <p>
            Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the website, mobile apps, voice assistants, and/or virtual agents operated by Life OS, Ltd. ("us", "we", or "our").
          </p>
          <p>
            Your access to and use of this web site, our mobile apps, our voice assistants, and/or our virtual agents ("Services") constitutes acceptance of these Terms of Service. Your access to and use of Services constitutes acceptance of the Terms of Service Applicable to Online Services set out in these Terms of Service. These Terms apply to all visitors, users and others who access or use our Services.
          </p>
          <p class="text-subtitle-2">
            By accessing or using our Services you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access our Services.
          </p>
          <p>
            We reserve the right to revise and update these Terms of Service without notice. Please review the Terms of Service posted on this web site periodically for any updates. Your continued use of our Services constitutes acceptance of these changes.
          </p>
        </section>
        <v-divider class="my-3"></v-divider>
        <section>
          <div class="text-h6 mb-3">Copyright</div>
          <p>
            This Site and our Services are operated by Life OS. All information, graphics and other materials contained on this site and applications are protected by the Canadian laws of copyright. No one may copy, redistribute, reproduce or republish in any form any material located on this Site and our Services without the written authorization of Life OS. Life OS grants to you a limited licence to print and copy information contained on this web site for your personal, non-commercial use. Life OS may revoke this limited licence at any time.
          </p>
        </section>
        <v-divider class="my-3"></v-divider>
        <section>
          <div class="text-h6 mb-3">Terms Applicable to All Online Services</div>
          <p>
            As part of its commitment to seek to improve the services made available to its customers, Life OS will provide through this web site and Services access to Online Services. Life OS may withdraw any Online Services at any time upon reasonable notice either through this Website or in another manner deemed by Life OS to be appropriate in the circumstances. Life OS may terminate your use of any Online Services if you have used the services in any way that is contrary to law, contrary to these Terms of Use or that disrupts the use of the Online Services by others.
          </p>
          <p>
            The Disclaimer and Limitation of Liability contained in these Terms of Use will also apply to the benefit of any third parties whose information is accessible through any Online Services.
          </p>
          <p>
            Your use of Online Services will be governed by the laws of the Province of Ontario and the federal laws of Canada.
          </p>
        </section>
        <v-divider class="my-3"></v-divider>
        <section>
          <div class="text-h6 mb-3">Contact Us</div>
          <p>
            If you have any questions about these Terms, please contact us.
          </p>
        </section>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'Terms',
  components: {
    
  }
}
</script>