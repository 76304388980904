<template>
  <!-- ====================== Step 2: Progression Phase ====================== -->
  <div class="progression-phase">
    <!-- ---------------------- Banner Bar ---------------------- -->
    <div class="banner mb-2">
      You've committed to these actions for today. Nothing left to do except get after it and win the day!
    </div>
    <v-alert v-if="user && user.daily && user.daily.notes && user.daily.notes.text && user.daily.notes.date == calendarDateYesterday()" type="info" class="mb-3 pa-2" icon="mdi-format-quote-close">
      {{ user.daily.notes.text }}
    </v-alert>
    <!-- ---------------------- Main Section ---------------------- -->
    <div class="d-flex flex-column flex-sm-row">
      <div class="main flex-2">
        <v-sheet class="list">
          <!-- ------------ Actions ------------ -->
          <div v-for="(group, groupIndex) in user.blueprint" :key="'group' + groupIndex">
            <div v-for="(priority, priorityIndex) in group.priorities" :key="'priority' + priorityIndex">
              <div v-for="(goal, goalIndex) in priority.goals" :key="'goal' + goalIndex">
                <div v-if="goal.focus">
                  <div v-for="(action, actionIndex) in goal.actions" :key="'action' + actionIndex">
                    <div v-if="action.progress.selected == calendarDate()" class="pa-3 d-flex flex-row align-center item" :style="{'border-left': '3px solid ' + colour(group.colour).hex['lighten-3']}" :class="action.complete || action.progress.step == calendarDate() ? 'complete' : 'incomplete'">
                      <div class="d-flex flex-column flex-1">
                        <div class="d-flex flex-row align-center lineage mb-2">
                          <div>{{ group.title }}</div>
                          <v-icon x-small>mdi-menu-right</v-icon>
                          <div>{{ priority.title }}</div>
                          <v-icon x-small>mdi-menu-right</v-icon>
                          <div>{{ goal.title }}</div>
                        </div>
                        <div class="overline d-flex align-md-center flex-column flex-md-row" :class="{ 'normal': $vuetify.breakpoint.smAndUp, 'small': $vuetify.breakpoint.xsOnly }">
                          <!-- 3 scenarios: Simple, Heroic, Heroic with Target -->
                          <div v-if="action.difficulty == 'simple'" :style="{'color': colour(group.colour).hex['darken-2']}">{{ action.name }}</div>
                          <div v-if="action.difficulty == 'heroic'">
                            <div v-if="action.target" class="d-flex flex-column flex-md-row align-md-center">
                              <div class="weak overline mr-1">{{ action.name }}:</div>
                              <div class="mt-1 mt-md-0" :style="{'color': colour(group.colour).hex['darken-2']}">{{ action.target }}</div>
                            </div>
                            <div v-else :style="{'color': colour(group.colour).hex['darken-2']}">{{ action.name }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-row align-center">
                        <v-progress-circular :size="25" v-if="action.difficulty == 'heroic'" :value="100*(action.progress.current/action.progress.target)" :color="colour(group.colour).hex['darken-2']" rotate="-90">
                          <v-btn icon small v-if="action.progress.current == action.progress.target && !action.complete" @click="toggleAction(action, goal)" :color="colour(group.colour).hex['darken-2']">
                            <v-icon small>mdi-check</v-icon>
                          </v-btn>
                          <v-btn icon small dark v-if="action.progress.current == action.progress.target && action.complete" :style="{'background': colour(group.colour).hex['darken-2'], 'opacity': 1}" @click="toggleAction(action, goal)">
                            <v-icon small>mdi-check</v-icon>
                          </v-btn>
                        </v-progress-circular>
                        <v-btn icon v-if="action.difficulty == 'simple'" @click="toggleAction(action, goal)" :style="{'border': user.profile.level == 15 ? '3px solid #ffec3b':''}">
                          <v-icon v-if="action.progress.finish == calendarDate()" :color="colour(group.colour).hex['darken-2']">mdi-checkbox-marked</v-icon>
                          <v-icon v-else :color="colour(group.colour).hex['darken-2']">mdi-checkbox-blank-outline</v-icon>
                        </v-btn>
                        <v-btn icon v-if="action.difficulty == 'heroic'" @click="toggleActionStep(action)" :style="{'border': user.profile.level == 15 ? '3px solid #ffec3b':''}">
                          <v-icon v-if="action.progress.step == calendarDate()" :color="colour(group.colour).hex['darken-2']">mdi-checkbox-marked</v-icon>
                          <v-icon v-else :color="colour(group.colour).hex['darken-2']">mdi-checkbox-blank-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ------------ Todos ------------ -->
          <div v-for="(todo, todoIndex) in user.daily.todos" :key="todoIndex">
            <div v-if="todo.progress.selected == calendarDate()" class="pa-3 d-flex flex-row align-center item" :style="{'border-left': '3px solid grey'}" :class="todo.complete ? 'complete' : 'incomplete'">
              <div class="d-flex flex-column flex-1">
                <div class="d-flex flex-row align-center lineage mb-2">
                  <!-- <v-chip x-small>Todo</v-chip> -->
                  <div x-small>Todo</div>
                </div>
                <div class="overline grey--text text--darken-2" :class="{ 'normal': $vuetify.breakpoint.smAndUp, 'small': $vuetify.breakpoint.xsOnly }">{{ todo.title }}</div>
              </div>
              <div class="d-flex flex-row">
                <v-btn icon @click="toggleTodo(todo)">
                  <v-icon v-if="todo.progress.finish">mdi-checkbox-marked</v-icon>
                  <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-sheet>
      </div>
      <!-- ---------------------- Widgets ---------------------- -->
      <div dark class="sidebar flex-1 mt-2 mt-sm-0">
        <BalanceTimer :user="user" @update="update"></BalanceTimer>
      </div>
    </div>
    <!-- ---------------------- Actions Bar ---------------------- -->
    <div class="blackbar mt-2 d-flex flex-column">
      <div class="overline">Daily Target</div>
      <div class="d-flex flex-row">
        <div v-for="(i, index) in numberOfCommittedActionsToday" :key="index" class="d-flex flex-1 justify-end" style="margin-right: -14px">
          <!-- <v-icon v-if="numberOfCompletedActionsToday < user.daily.target.minimum && index == user.daily.target.minimum -1" color="white">mdi-target</v-icon> -->
          <!-- <v-icon v-if="numberOfCompletedActionsToday >= user.daily.target.minimum && numberOfCompletedActionsToday < user.daily.target.dps && index == user.daily.target.dps -1" color="white">mdi-target-variant</v-icon> -->
          <!-- <v-icon v-if="numberOfCompletedActionsToday >= user.daily.target.dps && numberOfCommittedActionsToday > user.daily.target.dps && index == numberOfCommittedActionsToday -1" color="white">mdi-target</v-icon> -->
          <v-icon v-if="index == user.daily.target.minimum -1 && user.daily.target.minimum != user.daily.target.dps" color="white">mdi-target</v-icon>
          <v-icon v-if="index == user.daily.target.dps -1" color="white">mdi-target-variant</v-icon>
        </div>
        <div class="ml-2" style="min-width: 40px"></div>
      </div>
      <div class="d-flex flex-row align-center">
        <v-progress-linear :value="100 * (numberOfCompletedActionsToday / numberOfCommittedActionsToday)" :color="targetColour(numberOfCompletedActionsToday, numberOfCommittedActionsToday)" height="20" rounded></v-progress-linear>
        <div class="ml-2 justify-center align-center" style="text-align: center; min-width: 40px">{{ numberOfCompletedActionsToday }} / {{ numberOfCommittedActionsToday }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { DateTime } from "luxon"
  import BalanceTimer from '@/widgets/BalanceTimer.vue'
  export default {
    name: 'DailyProgress',
    props: ['user'],
    components: {
      BalanceTimer
    },
    data() {
      return {

      }
    },
    created() {
      this.user.daily.progress.widgets = this.user.daily.progress.widgets || {}
    },
    methods: {
      calendarDate() {
        return DateTime.now().toFormat('yyyy-LL-dd')
      },
      calendarDateYesterday() {
        return DateTime.now().minus({days: 1}).toFormat('yyyy-LL-dd')
      },
      toggleTodo(todo) {
        if (todo.progress.finish == this.calendarDate()) {
          todo.progress.finish = null
          todo.complete = false
        } else {
          todo.progress.finish = this.calendarDate()
          todo.complete = true
        }
        this.update()
      },
      toggleAction(action, goal) {
        const firstCompleted = goal.actions.findIndex( a => a.complete == true )
        const index = goal.actions.findIndex( a => a == action )
        if (action.complete == false) {
          action.complete = true
          action.progress.previous = action.progress.last
          action.progress.finish = this.calendarDate()
          action.progress.last = this.calendarDate()
          goal.progress.last = this.calendarDate()
          // ------------ Reorder Action ------------
          goal.actions.splice(index,1) // delete the element
          if (firstCompleted == -1) {
            goal.actions.push(action) // add to end
          } else {
            goal.actions.splice(firstCompleted-1, 0, action)
          }
        } else {
          action.complete = false
          action.progress.finish = null
          action.progress.last = action.progress.previous
          goal.actions.splice(index, 1)[0] // delete the element
          goal.actions.unshift(action) // add to start
        }
        this.update()
      },
      toggleActionStep(action) {
        if (!action.progress.step || action.progress.step != this.calendarDate()) {
          action.progress.previous = action.progress.last
          action.progress.step = this.calendarDate()
          action.progress.last = this.calendarDate()
          if (action.progress.current < action.progress.target) action.progress.current++
        } else {
          delete action.progress.step
          if (action.progress.previous == undefined) action.progress.previous = action.progress.start
          action.progress.last = action.progress.previous
          if (action.progress.current) action.progress.current--
        }
        this.update()
      },
      targetColour(completed, committed) {
        let colour = 'grey'
        if (completed > 0) colour = 'orange'
        if (completed >= this.user.daily.target.minimum) colour = 'yellow'
        if (completed >= this.user.daily.target.dps) colour = 'green'
        if (completed == committed && committed > 0) colour = 'green'
        if (completed == committed && committed > this.user.daily.target.dps) colour = 'purple'
        return colour
      },
      update() {
        this.$forceUpdate()
        this.$emit('update')
      },
      colour(index) {
        return this.$store.getters.colour(index)
      }
    },
    computed: {
      numberOfCompletedActionsToday() {
        if (!this.user) return false
        let count = 0
        this.user.blueprint.forEach(group=>{
          group.priorities.forEach(priority=>{
            priority.goals.forEach(goal=>{
              goal.actions.forEach(action=>{
                if (action.progress.selected == this.calendarDate() && (action.progress.finish == this.calendarDate() || action.progress.step == this.calendarDate())) {
                  count++
                }
              })
            })
          })
        })
        this.user.daily.todos.forEach(todo=>{
          if (todo.progress.selected == this.calendarDate() && todo.progress.finish) count++
        })
        return count
      },
      numberOfCommittedActionsToday() {
        if (!this.user) return false
        let count = 0
        this.user.blueprint.forEach(group=>{
          group.priorities.forEach(priority=>{
            priority.goals.forEach(goal=>{
              goal.actions.forEach(action=>{
                if (action.progress.selected == this.calendarDate()) {
                  count++
                }
              })
            })
          })
        })
        this.user.daily.todos.forEach(todo=>{
          if (todo.progress.selected == this.calendarDate()) count++
        })
        return count
      }
    }
  }
</script>
<style lang="scss" scoped>
  .list .item {
    border-bottom: 1px solid #eee;
    padding: 5px 10px;
  }
  .list .item .lineage {
    color: grey;
    font-size: 10px;
  }
  .complete .overline {
    text-decoration: line-through;
  }
  .normal.overline {
    font-weight: 600 !important;
    line-height: 1;
    font-size: 16px !important;
  }
  .small.overline {
    font-weight: 600 !important;
    line-height: 1;
    font-size: 13px !important;
  }
  .weak.overline {
    line-height: 1;
  }
  .target.widget {
    background: white;
    border-radius: 5px;
  }
  .complete {
    opacity: 0.50;
    background: #efefef;
  }
  .blackbar {
    background-color: #000000;
    color: white;
    padding: 15px;
    border-radius: 5px;
  }
</style>