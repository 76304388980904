<template>
  <v-sheet class="mission-player px-4 py-3" dark>
    <div v-if="missions && missions.length" class="d-flex flex-column flex-sm-row align-center">
      <v-icon color="grey" class="lighten-2 mr-4" x-large>mdi-progress-check</v-icon>
      <div class="flex-1 d-flex flex-column my-3 my-sm-0">
        <div class="overline grey--text font-weight-bold">Current Mission</div>
        <div class="body-2">
          {{ missions[0].description }}
        </div>
        <!-- <div class="caption grey--text" v-if="missionInProgress.target">
          ({{  missionInProgress.progress }} / {{  missionInProgress.target }})
        </div> -->
      </div>
      <v-btn @click="go(missions[0].route)">{{ missions[0].route }}</v-btn>
      <!-- <div class="caption grey--text">
        {{ missions[0].target }}
      </div> -->
    </div>
  </v-sheet>
</template>
<script>
import { getFirestore, collection, getDocs } from 'firebase/firestore'
export default {
  name: 'MissionPlayer',
  props: ['user'],
  data: () => ({
    missions: [],
  }),
  created() {
    this.loadMissions()
  },
  methods: {
    loadMissions() {
      let db = getFirestore()
      let missionRef = collection(db, 'missions')
      getDocs(missionRef).then( querySnapshot => {
        let missions = []
        querySnapshot.forEach( doc => {
          let mission = doc.data()
          mission.id = doc.id
          if (this.user.profile.missions.indexOf(mission.id) == -1) {
            if (mission.level <= this.user.profile.level) {
              missions.push(mission)
            }
          }
        })
        this.missions = missions
        this.$forceUpdate()
      })
    },
  },
  watch: {
    user() {
      this.loadMissions()
    },
  }
}
</script>
<style>
  .mission--progress .v-snack__content {
    border-left: 6px solid #2296f3;
  }
  .mission--complete .v-snack__content {
    border-left: 6px solid green;
  }
</style>
