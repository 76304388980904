import Vue from 'vue'
import VueRouter from 'vue-router'

import Hello from '@/views/public/Hello.vue'
import Landing from '@/views/public/Landing.vue'
import Privacy from '@/views/public/Privacy.vue'
import Terms from '@/views/public/Terms.vue'

import Dashboard from '@/views/protected/Dashboard.vue'
import Settings from '@/views/protected/Settings.vue'
import Welcome from '@/views/protected/Welcome.vue'

import Balance from '@/views/protected/tools/Balance.vue'

// import Masterpiece from '@/views/protected/tools/Masterpiece.vue'
import Blueprint from '@/views/protected/tools/Blueprint.vue'
import Goals from '@/views/protected/tools/Goals.vue'
import Progress from '@/views/protected/tools/Progress.vue'
// import Timey from '@/views/protected/tools/Timey.vue'
// import Progress from '@/views/protected/tools/Progress.vue'
// import GPT from '@/views/protected/tools/GPT.vue'

import Lost from '@/views/public/404.vue'

import Admin from '@/views/protected/Admin.vue'

import { getAuth } from 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  // ---------------------- PUBLIC ROUTES ----------------------
  {
    path: '/login',
    name: 'Login',
    component: Hello,
    meta: {
      scope: 'public'
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Hello,
    meta: {
      scope: 'public'
    }
  },
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      scope: 'public'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      scope: 'public'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      scope: 'public'
    }
  },
  // ---------------------- PROTECTED ROUTES ----------------------
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      scope: 'protected'
    }
  },
  {
    path: '/account/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      scope: 'protected'
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      scope: 'protected'
    }
  },
  {
    path: '/tools/balance',
    name: 'Balance',
    component: Balance,
    meta: {
      scope: 'protected'
    }
  },
  {
    path: '/tools/progress',
    name: 'Progress',
    component: Progress,
    meta: {
      scope: 'protected'
    }
  },
  {
    path: '/tools/goals',
    name: 'Goals',
    component: Goals,
    meta: {
      scope: 'protected'
    }
  },
  // {
  //   path: '/tools/gpt',
  //   name: 'GPT',
  //   component: GPT,
  //   meta: {
  //     scope: 'protected'
  //   }
  // },
  // {
  //   path: '/tools/masterpiece',
  //   name: 'Masterpiece',
  //   component: Masterpiece,
  //   meta: {
  //     scope: 'protected'
  //   }
  // },
  {
    path: '/tools/blueprint',
    name: 'Blueprint',
    component: Blueprint,
    meta: {
      scope: 'protected'
    }
  },
  // {
  //   path: '/tools/timey',
  //   name: 'Timey',
  //   component: Timey,
  //   meta: {
  //     scope: 'protected'
  //   }
  // },
  // {
  //   path: '/tools/progress',
  //   name: 'Progress',
  //   component: Progress,
  //   meta: {
  //     scope: 'protected'
  //   }
  // },
  // ---------------------- ADMIN ROUTES ----------------------
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      scope: 'admin'
    }
  },
  // ---------------------- SYSTEM ROUTES ----------------------
  {
    path: '*',
    name: '404', 
    component: Lost,
    meta: {
      scope: 'public'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve( (to,from,next) => {
  if (to.meta.scope == 'public') {
    // ---------------------- Public ----------------------
    next()
  } else {
    // ---------------------- Non-Public ----------------------
    let user = getAuth().currentUser
    if (to.meta.scope == 'protected' && user) {
      next()
    } else if (to.meta.scope == 'admin' && user && user.uid == 'sAqvGtakoQgQ7TNA2ResMKpNhV13') {
      next()
    } else {
      next({name: 'Hello'})
    }
  }
})

export default router
