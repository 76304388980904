<template>
  <div class="focus container">
    <div class="d-flex flex-row align-center mb-4">
      <div class="lifeos-heading lifeos-font-serif">Focus</div>
      <v-spacer></v-spacer>
      <v-btn class="d-none d-md-block" icon @click="printerDialog = true"><v-icon>mdi-cards-outline</v-icon></v-btn>
      <v-btn icon @click="helpDialog = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
    </div>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <div class="d-flex flex-row flex-wrap">
      <div v-for="(goal,goalIndex) in goals" :key="goalIndex" class="lifeos-box d-flex flex-column" :class="isSleeping(goal) ? 'sleeping' : ''">
        <v-card class="mx-2 mb-4 flex-grow-1 d-flex flex-column" v-if="goal.actions != undefined" :class="isSleeping(goal) ? 'sleeping' : ''">
          <v-progress-linear :color="colour(goal.group).hex['lighten-2']" :value="getCompletedPercentage(goal.actions)"></v-progress-linear>
          <v-card-title style="padding: 5px 5px 5px 16px" @click="openGoal(goal)">
            <div class="d-flex flex-row align-center w-100">
              <div class="flex-1 text-truncate subtitle-1 font-weight-bold" style="width: 100px;">{{ goal.title }}</div>
              <v-icon v-if="goal.frequency && goal.frequency.period" class="mr-2">mdi-calendar-today-outline</v-icon>
              <v-icon v-if="calculateAge(goal) > 7 && !isSleeping(goal)" color="red">mdi-alert</v-icon>
              <v-icon v-else-if="calculateAge(goal) > 3 && !isSleeping(goal)" color="orange">mdi-alert-circle</v-icon>
              <v-menu transition="slide-x-transition" bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" class="options" :style="{'border': user.profile.level == 11 ? '3px solid #ffec3b':''}">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group>
                    <v-list-item @click="openGoal(goal)" :style="{'border': user.profile.level == 11 ? '3px solid #ffec3b':''}">
                      <v-list-item-icon>
                        <v-icon>mdi-cog-outline</v-icon>
                      </v-list-item-icon>
                      Configure
                    </v-list-item>
                    <!-- <v-list-item v-if="!isSleeping(goal)" @click="snooze(goal, 1)">
                      <v-list-item-icon>
                        <v-icon>mdi-weather-night</v-icon>
                      </v-list-item-icon>
                      Snooze 1 day
                    </v-list-item> -->
                    <v-list-item v-if="!isSleeping(goal)" @click="snooze(goal, 7)">
                      <v-list-item-icon>
                        <v-icon>mdi-weather-night</v-icon>
                      </v-list-item-icon>
                      Snooze 1 week
                    </v-list-item>
                    <v-list-item v-else @click="wake(goal)">
                      <v-list-item-icon>
                        <v-icon>mdi-alarm</v-icon>
                      </v-list-item-icon>
                      Wake Up
                    </v-list-item>
                    <v-list-item v-if="goal.frequency && goal.frequency.period" @click="setFrequency(goal, 0)">
                      <v-list-item-icon>
                        <v-icon>mdi-calendar-today-outline</v-icon>
                      </v-list-item-icon>
                      Remove as daily
                    </v-list-item>
                    <v-list-item v-else @click="setFrequency(goal, 1)">
                      <v-list-item-icon>
                        <v-icon>mdi-calendar-today-outline</v-icon>
                      </v-list-item-icon>
                      Set as daily
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </v-card-title>
          <v-card-text class="flex-1">
            <!-- ------------ Action Configuration Message ------------ -->
            <v-alert outlined v-if="isSleeping(goal)" color="purple" border="left" icon="mdi-weather-night">
              Snoozing until {{ snoozeDay(goal) }}
            </v-alert>
            <v-alert v-else-if="goal.actions == null || goal.actions.length == 0" dense text type="info" icon="mdi-information-outline" border="left">
              <div>No actions added yet</div>
              <div class="d-flex flex-row">
                <div class="flex-1"></div>
                <v-btn @click="openGoal(goal)" small color="blue" outlined class="mt-2" :style="{'border': user.profile.level == 8 ? '3px solid #ffec3b':''}">
                  Add Actions
                </v-btn>
              </div>
            </v-alert>
            <v-alert v-else-if="goal.actions.length && goal.actions[0].complete" dense text type="success" icon="mdi-check" border="left">
              All actions completed
            </v-alert>
            <div v-else-if="goal.actions.length && !goal.actions[0].complete">
              <div class="caption">Next Action:</div>
              <div class="d-flex flex-row align-center">
                <div class="overline flex-1" :style="{'color': colour(goal.group).hex['darken-2'] }" style="font-size: 14px !important; letter-spacing: 1.5px !important; font-weight: 600;">{{ goal.actions[0].name }}</div>
                <v-btn icon @click="completeAction(goal); saveBlueprint()" :style="{'border': user.profile.level == 9 ? '3px solid #ffec3b':''}"><v-icon>mdi-check</v-icon></v-btn>
              </div>
            </div>
            <!-- Heroic Action Progress Bar -->
            <div v-if="goal.actions.length && goal.actions[0].difficulty == 'heroic' && !isSleeping(goal)">
              <div class="progress d-flex flex-row align-center mb-2">
                <div class="box" v-for="(progress, index) in goal.actions[0].progress.target" v-bind:key="index" :class="(index < goal.actions[0].progress.current ? 'complete' : 'incomplete') + (goal.actions[0].progress.target > 30 ? ' continuous ' : ' finite ') + (index == goal.actions[0].progress.current-1 ? 'last' : '')" :style="{'background' : (index < goal.actions[0].progress.current) ? colour(goal.group).hex['lighten-2'] : '#efefef'}">
                </div>
                <v-btn @click="progressHeroicAction(goal)" class="ml-2" x-small icon :disabled="goal.actions[0].progress.current >= goal.actions[0].progress.target" :style="{'border': user.profile.level == 12 ? '3px solid #ffec3b':''}"><v-icon x-small>mdi-plus</v-icon></v-btn>
              </div>
              <div>
                <div v-if="goal.actions[0].target" class="caption">Target Effort: <span class="font-weight-bold">{{ goal.actions[0].target }}</span></div>
                <div v-if="goal.actions[0].minimum" class="caption grey--text">Target Minimum: <span class="font-weight-bold">{{ goal.actions[0].minimum }}</span></div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="px-3 mt-0" @click="openGoal(goal)">
            <v-chip small :class="colour(goal.group).class" class="lighten-3">
              {{ user.blueprint[goal.group].title }}
            </v-chip>
            <v-spacer></v-spacer>
            <span class="text-overline" style="font-size: 10px !important;">{{ user.blueprint[goal.group].priorities[goal.priority].title }}</span>
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <!-- ------ Goal Dialog ------ -->
    <Goal v-if="goalDialog" :user="user" :goal="goal" :colour="colour(goal.group)" @close="goalDialog = false; saveBlueprint()" @save="saveBlueprint" @reward="triggerReward"></Goal>
    <!-- ------ Printer Dialog ------ -->
    <v-dialog v-model="printerDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <div class="d-flex flex-column h-100 white--bg" background="white">
        <div class="toolbar d-flex justify-end" style="border-bottom: 1px solid black">
          <v-btn :disabled="printerTab == 0" icon @click="printerTab--"><v-icon>mdi-chevron-left</v-icon></v-btn>
          <v-btn :disabled="printerTab == goals.length-1" icon @click="printerTab++"><v-icon>mdi-chevron-right</v-icon></v-btn>
          <v-btn icon @click="printerDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <div class="d-flex justify-center align-center h-100"  v-if="user.blueprint[goals[printerTab].group].title">
          <div class="hero-card">
            <div class="hero-card-inner d-flex flex-column flex-1 pa-6">
              <div class="hero-card-section flex-1 grey--text d-flex align-center flex-column justify-center">
                <div class="text-h6 text-uppercase font-weight-bold my-2" style="font-size: 2rem !important">
                  {{ user.blueprint[goals[printerTab].group].title }}
                </div>
                <div class="text-h6 text-uppercase font-weight-bold my-2" style="font-size: 3rem !important">
                  {{ user.blueprint[goals[printerTab].group].priorities[goals[printerTab].priority].title }}
                </div>
              </div>
              <div v-if="goals[printerTab].actions.length && goals[printerTab].actions[0].difficulty == 'heroic'" class="hero-card-section flex-1 grey--text d-flex align-center flex-column justify-center">
                <div v-if="goals[printerTab].actions[0].minimum" class="text-h6 text-uppercase font-weight-bold my-2" style="font-size: 2rem !important">
                  {{ goals[printerTab].actions[0].minimum }}
                </div>
                <div v-if="goals[printerTab].actions[0].target" class="text-h6 text-uppercase font-weight-bold my-2" style="font-size: 2.5rem !important">
                  {{ goals[printerTab].actions[0].target }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
    <!-- ------ Help Dialog ------ -->
    <v-dialog v-model="helpDialog" max-width="560">
      <v-card>
        <v-card-title>Help</v-card-title>
        <v-card-text>
          <!-- With the Priorities tool, you organized your priorities and set some goals for each. 
          You then focused on the most effective goals which now appear in this list.
          <br><br>
          The Focus tool allows you to determine the actions which will help you complete your goal and track your progress along the way.
          <br><br>
          How goals are organized in Life OS:
          <ul>
            <li><strong>Active Goals:</strong> Goals which require actions to be undertaken.</li>
              <ul>
                <li><strong>Discrete Goals:</strong> A goal with a clearly defined actions.<br><em>For example: "Run a 10k marathon"</em></li>
                <li><strong>Continuous Goals:</strong> A goal which is ongoing.<br><em>For example: "Exercise 3 times a week"</em></li>
                <li><strong>Generic Goals:</strong> A discrete goal without clearly defined actions.<br><em>For example: "Work on book"</em></li>
              </ul>
            <li><strong>Passive Goals:</strong> Goals which require events to occur. For example: "Donate $20 whenever asked"</li>
          </ul> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="helpDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reward.notification" max-width="560" transition="fab-transition">
      <v-card>
        <v-progress-linear indeterminate :color="reward.colour"></v-progress-linear>
        <div class="d-flex flex-row align-center pa-4">
          <div class="reward-icon">
            <v-btn icon dark :color="reward.colour" x-large class="mr-4"><v-icon x-large>mdi-party-popper</v-icon></v-btn>
          </div>
          <div class="reward-text d-flex flex-column flex-1">
            <div class="text-h6" :class="reward.colour + '--text'">Reward Unlocked!</div>
            <div class="body-2 mb-4">Congratulations! You've completed a heroic action and unlocked your reward! Hard work pays off.</div>
            <div class="text-h5">{{ reward.description }}</div>
          </div>
          <div class="reward-action">
            <v-btn icon @click="reward.notification=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// ---------------------- Release 1.0.8 ----------------------
// 1) Add minimum action
// 2) Add target action
// 3) Add actions list
import { getFirestore, collection, getDocs, doc, updateDoc, addDoc, ref } from 'firebase/firestore'
import { DateTime } from "luxon"
import Goal from '@/components/Goal.vue'
import Draggable from "vuedraggable"
export default {
  name: 'Goals',
  props: ['user'],
  components: {
    Draggable,
    Goal
  },
  data() {
    return {
      loading: true,
      goalDialog: false,
      printerDialog: false,
      helpDialog: false,
      printerTab: 0,
      development: window.location.host.includes('localhost'),
      reward: {
        notification: false,
        description: '',
        colour: 'green'
      },
      // ------ Focus Variables ------
      goals: [],
      goal: null
    }
  },
  created() {
    if (this.user) this.initialize()
  },
  methods: {
    initialize() {
      this.loading = true
      if (this.user && this.user.blueprint) {
        this.goals = []
        this.user.blueprint.forEach((group, groupIndex) => { // Groups
          group.priorities.forEach((priority, priorityIndex) => { // Priorities
            priority.goals.forEach((goal, goalIndex) => { // Goals
              goal.group = groupIndex
              goal.priority = priorityIndex
              if (goal.focus) this.goals.push(goal)
            })
          })
        })
      }
      this.loading = false
    },
    openGoal(goal) {
      this.goal = goal
      this.goalDialog = true
    },
    completeAction(goal) {
      // ---------------------- Complete Action ----------------------
      // move action before the first completed action
      let firstCompleted = goal.actions.findIndex( action => action.complete == true )
      goal.actions[0].complete = true
      goal.actions[0].progress.finish = this.calendarDate()
      goal.progress.last = this.calendarDate()
      // ---------------------- Reward ----------------------
      if (goal.actions[0].difficulty == 'heroic' && goal.actions[0].reward) {
        this.triggerReward(goal.actions[0].reward)
      }
      // ---------------------- Rearrange Actions ----------------------
      if (firstCompleted == -1) {
        // Move action to bottom of action list
        goal.actions.push(goal.actions[0])
        goal.actions.splice(0, 1) // delete first element
      } else {
        // Move action before first completed action
        const element = goal.actions.splice(0, 1)[0]
        goal.actions.splice(firstCompleted-1, 0, element)
      }
    },
    saveBlueprint() {
      // ---------------------- Save Changes ----------------------
      if (this.user && this.user.uid) {
        const db = getFirestore()
        const userRef = doc(db, 'users', this.user.uid)
        updateDoc(userRef, {
          // refresh: Date.now(),
          blueprint: this.user.blueprint
        }).then(() => {
          console.log('Saved blueprint', this.user.blueprint)
        }).catch(error => {
          console.log('Error saving blueprint', error)
        })
      }
    },
    getCompletedPercentage(actions) {
      if (actions == undefined) return 0
      if (actions.length == 0) return 0
      let totalValue = 0      
      // Value per action
      let valuePerAction = 100 / actions.length
      // Add completed actions
      let countCompletedActions = actions.filter(action => action.complete == true).length
      if (countCompletedActions == actions.length) return totalValue
      totalValue = countCompletedActions * valuePerAction
      // Add partial actions
      if (actions[0].difficulty == 'heroic') {
        totalValue += (actions[0].progress.current / actions[0].progress.target) * valuePerAction
      }
      return totalValue
    },
    triggerReward(rewardDescription) { 
      this.reward = {
        notification: true,
        description: rewardDescription,
        colour: 'green'
      }
    },
    progressHeroicAction(goal) {
      let action = goal.actions[0]
      if (action.progress.current < action.progress.target) {
        action.progress.current++
        action.progress.last = this.calendarDate()
        action.progress.step = this.calendarDate()
        this.saveBlueprint()
      }
    },
    calendarDate() {
      return DateTime.now().toFormat('yyyy-LL-dd')
    },
    colour(groupIndex) {
      let colourIndex = this.user.blueprint[groupIndex].colour
      return this.$store.getters.colour(colourIndex)
    },
    calculateAge(goal) {
      let start = DateTime.now()
      let goalStart = goal.progress.start ? DateTime.fromFormat(goal.progress.start, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
      let goalLast = goal.progress.last ? DateTime.fromFormat(goal.progress.last, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
      let finish = DateTime.max(goalStart, goalLast) // Find the most recent start date using Luxon's max() function
      if (goal.actions[0] && goal.actions[0].progress) {
        let actionStart = goal.actions[0].progress.start ? DateTime.fromFormat(goal.actions[0].progress.start, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
        let actionLast = goal.actions[0].progress.last ? DateTime.fromFormat(goal.actions[0].progress.last, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
        let actionStep = goal.actions[0].progress.step ? DateTime.fromFormat(goal.actions[0].progress.step, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
        finish = DateTime.max(goalStart, goalLast, actionStart, actionLast, actionStep) // Find the most recent start date using Luxon's max() function
      }
      return Math.abs(Math.floor(start.diff(finish).as('days')))
    },
    snooze(goal, duration) {
      if (goal.progress.snooze == undefined) {
        goal.progress.snooze = {
          until: null,
          count: 0
        }
      }
      goal.progress.snooze.until = DateTime.now().plus({ days: duration }).toFormat('yyyy-LL-dd')
      goal.progress.snooze.count++
      this.$emit('save')
      this.$forceUpdate()
    },
    wake(goal) {
      delete goal.progress.snooze
      this.$emit('save')
      this.$forceUpdate()
    },
    isSleeping(goal) {
      if (goal.progress.snooze == undefined) return false
      let snooze = DateTime.fromFormat(goal.progress.snooze.until, 'yyyy-LL-dd')
      let now = DateTime.now()
      if (now < snooze) return true
      delete goal.progress.snooze
      this.$emit('save')
      return false
    },
    snoozeDay(goal) {
      if (goal.progress.snooze == undefined) return false
      let snooze = DateTime.fromFormat(goal.progress.snooze.until, 'yyyy-LL-dd')
      return snooze.toFormat('MMMM d')
    },
    setFrequency(goal, period) {
      if (goal.frequency == undefined) goal.frequency = {}
      goal.frequency.start = this.calendarDate()
      goal.frequency.period = period
      if (goal.period == 0) goal.frequency = {}
      this.$emit('save')
    },
  },
  computed: {
    colours() {
      return this.$store.getters.colours
    }
  },
  watch: {
    user: {
      handler() {
        this.initialize()
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .v-chip.highlight {
    outline: medium solid white;
  }
  .v-card.draggable {
    cursor: pointer;
  }
  // --- Progress Bar --- //
  .box {
    flex: 1;
    min-height: 25px;
    max-block-size: 25px;
    background: #efefef;
    box-sizing: border-box;
  }
  .box.finite {
    margin-right: 2px;
    border-radius: 2px;
  }
  .box.finite.complete.white {
    border: 1px solid grey !important;
  }
  .box.continuous.complete.white {
    border-top: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
  }
  .box.continuous.complete.white:first-child {
    border-left: 1px solid grey !important;
  }
  .box.continuous.complete.white.last {
    border-right: 1px solid grey !important;
  }
  .box.continuous:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .box.continuous:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .box:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .hero-card {
    border: 1px solid black;
    width: 75%;
    padding: 15px;
    min-height: 600px;
    max-height: 600px;
    display: flex;
    .hero-card-inner {
      border: 15px solid grey;
    }
    .box {
      flex: 1;
      min-height: 25px;
      box-sizing: border-box;
      border: 6px solid black;
      max-height: 100px;
    }
    .box:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
  .sleeping {
    opacity: 0.60;
    order: 2;
  }
  .hidden {
    display: none !important;
  }
  .white--bg {
    background-color: white;
  }
  // .border-black { color: black; }
  // .border-white { color: white; }
  // .border-grey { color: grey; }
  // .border-brown { color: brown; }
  // .border-orange { color: orange; }
  // .border-yellow { color: yellow; }
  // .border-green { color: green; }
  // .border-blue { color: blue; }
  // .border-purple { color: purple; }
  // .border-pink { color: pink; }
  // .border-red { color: red; }
  @media print {
    body {
        zoom: 50%; /* Scales down the content to 50% */
        transform: scale(0.5); /* Scales down the content to 50% */
        -webkit-transform: scale(0.5); /* Safari and Chrome */
        -moz-transform: scale(0.5); /* Firefox */
    }
    .hero-card {
      height: 40% !important;
      min-height: 40% !important;
      max-height: 40% !important;
      width: 80% !important;
    }
    .toolbar {
      visibility: hidden;
    }
}
</style>