<template>
  <div class="design-phase">
    <div class="placeholder" v-intersect="onIntersect"></div><!-- This is what triggers the box to pop out -->
    <div class="mt-4 mb-4 d-flex flex-row align-center">
      <div class="flex-1">
        Craft your masterpiece day by selecting the most important actions that can be achieved today.
      </div>
      <v-btn icon @click="resetSelected()"><v-icon>mdi-refresh</v-icon></v-btn>
    </div>
    <v-alert v-if="user && user.daily && user.daily.notes && user.daily.notes.text && user.daily.notes.date == calendarDateYesterday()" type="info" class="mb-3 pa-2" icon="mdi-format-quote-close">
      {{ user.daily.notes.text }}
    </v-alert>
    <!-- ---------------------- Action List ---------------------- -->
    <v-card>
      <div class="list">
        <div v-for="(group, groupIndex) in user.blueprint" :key="'group' + groupIndex">
          <div v-for="(priority, priorityIndex) in group.priorities" :key="'priority' + priorityIndex">
            <div v-for="(goal, goalIndex) in priority.goals" :key="'goal' + goalIndex">
              <template v-if="goal.focus && !isSleeping(goal)">
                <div v-if="goal.actions.length && goal.actions[0].progress.finish == null" class="d-flex flex-row align-center item pa-1" :style="{'border-left': 'thick solid ' + colour(group.colour).hex['lighten-3']}" :class="goal.actions[0].progress.selected == calendarDate() ? 'selected' : ''">
                  <v-chip small class="ma-2 mr-0 d-none d-sm-flex lighten-3" :color="colour(group.colour).class">
                    {{ priority.title }}
                  </v-chip>
                  <div class="ml-3 flex-1 overline text-truncate">
                    <span :style="{'color': colour(group.colour).hex['darken-3']}">{{ goal.title }}: </span>
                    {{ goal.actions[0].name }}
                  </div>
                  <!-- <v-chip small color="grey">
                    {{ calculateAge(goal) }}
                  </v-chip> -->
                  <div v-if="calculateAge(goal) > 7">
                    <v-chip small color="red lighten-5" class="ma-2 d-none d-sm-flex" text-color="red" append-icon="mdi-star">
                      {{ calculateAge(goal) }} days old
                    </v-chip>
                    <v-icon class="d-flex d-sm-none" color="red">mdi-alert</v-icon>
                  </div>
                  <div v-else-if="calculateAge(goal) > 3">
                    <v-chip small color="orange lighten-5" class="ma-2 d-none d-sm-flex" text-color="orange" append-icon="mdi-star">
                      {{ calculateAge(goal) }} days old
                    </v-chip>
                    <v-icon class="d-flex d-sm-none" color="orange">mdi-alert-circle</v-icon>
                  </div>
                  <!-- <v-btn icon v-if="goal.actions[0].progress.selected != calendarDate()"><v-icon>mdi-sleep</v-icon></v-btn> -->
                  <v-btn icon :style="{'border': user.profile.level == 13 ? '3px solid #ffec3b !important':''}">
                    <v-icon v-if="goal.actions[0].progress.selected == calendarDate()" @click="goal.actions[0].progress.selected=null; $forceUpdate()" :color="colour(group.colour).hex['darken-2']">mdi-clipboard-check</v-icon>
                    <v-icon v-else @click="goal.actions[0].progress.selected = calendarDate(); $forceUpdate()">mdi-clipboard-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <!-- ---------------------- Todo List ---------------------- -->  
    <v-card>
      <div class="list mt-2">
        <draggable group="group" ghost-class="ghost" animation="200" @start="dragging = true" @end="dragging = false; $emit('update')" :list="user.daily.todos" delay='400' delay-on-touch-only='true'>
          <transition-group type="transition" :name="!dragging ? 'flip-list' : null">
            <div v-for="(todo, todoIndex) in user.daily.todos" :key="todoIndex">
              <div v-if="!todo.complete || (todo.complete == true && todo.progress.finish == calendarDate())" class="d-flex flex-row align-center item todo" :class="todo.progress.selected == calendarDate() ? 'selected' : ''">
                <v-icon class="ml-1">mdi-drag</v-icon>
                <v-chip small class="ma-1 d-none d-sm-flex" color="#e3e3e3">
                  todo
                </v-chip>
                <div v-if="todo.edit == true" class="flex-1 flex-row ml-1">
                  <v-text-field 
                    v-model="todo.title"
                    single-line
                    label="Todo Description"
                    hide-details
                    autofocus
                    dense
                    append-outer-icon="mdi-delete"
                    @click:append-outer="deleteTodo(todoIndex)"
                    append-icon="mdi-keyboard-return"
                    @click:append="saveTodo(todo)"
                    @keydown.enter="saveTodo(todo)" 
                    @blur="blurTodo(todo)"
                    outlined
                    filled
                    placeholder="Todo Description"
                    class="py-2"
                    maxlength="48"
                  ></v-text-field>
                </div>
                <div v-else class="flex-1 ml-1 text-truncate" @click="focusTodo(todo)">
                  <span v-if="todo.title" class="overline">{{ todo.title }}</span>
                  <span v-else class="body-2 font-italic grey--text">Untitled</span>
                </div>
                <template v-if="calculateDeltaDays(todo.progress.start, null) > 7">
                  <v-chip small color="red lighten-5" class="ma-2 d-none d-sm-flex" text-color="red" append-icon="mdi-star">
                    {{ calculateDeltaDays(todo.progress.start, null) }} days old
                  </v-chip>
                  <v-icon class="d-flex d-sm-none" color="red">mdi-alert</v-icon>
                </template>
                <template v-else-if="calculateDeltaDays(todo.progress.start, null) > 3">
                  <v-chip small color="orange lighten-5" class="ma-2 d-none d-sm-flex" text-color="orange" append-icon="mdi-star">
                    {{ calculateDeltaDays(todo.progress.start, null) }} days old
                  </v-chip>
                  <v-icon class="d-flex d-sm-none" color="orange">mdi-alert-circle</v-icon>
                </template>
                <!-- <v-btn icon v-if="!todo.edit">
                  <v-icon @click="todo.edit = true; $forceUpdate()">mdi-pencil</v-icon>
                </v-btn> -->
                <v-btn icon>
                  <v-icon v-if="todo.progress.selected == calendarDate()" @click="todo.progress.selected=null; $forceUpdate()">mdi-clipboard-check</v-icon>
                  <v-icon v-else @click="todo.progress.selected = calendarDate(); $forceUpdate()">mdi-clipboard-outline</v-icon>
                </v-btn>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </v-card>
    <div @click="createTodo()" class="lifeos-transparent-button mt-2 px-2 py-1 text-overline">
      <v-icon small>mdi-plus</v-icon> New Todo
    </div>
    <div class="blackbar mt-2">
      <div class="overline">Daily Target</div>
      <div class="d-flex flex-row">
        <div v-for="(i, index) in user.daily.target.dps" :key="index" class="d-flex flex-1 justify-end" style="margin-right: -14px">
          <v-icon v-if="index == user.daily.target.minimum -1 && numberOfActionsSelected <= user.daily.target.minimum" color="white">mdi-target</v-icon>
          <v-icon v-if="index == user.daily.target.dps -1 && numberOfActionsSelected > user.daily.target.minimum && numberOfActionsSelected < user.daily.target.dps" color="white">mdi-target</v-icon>
        </div>
        <div class="ml-2" style="min-width: 40px"></div>
      </div>
      <div class="d-flex flex-row align-center">
        <v-progress-linear :value="100 * (numberOfActionsSelected / user.daily.target.dps)" :color="targetColour()" height="20" rounded></v-progress-linear>
        <div class="ml-2 justify-center align-center" style="text-align: center; min-width: 40px">{{ numberOfActionsSelected }} / {{ user.daily.target.dps }}</div>
      </div>
      <div v-if="numberOfActionsSelected > user.daily.target.dps + 1" class="d-flex mt-1 caption">
        Be careful not to commit to too many actions. Trust in the targets you've set. Consistency is not just about pulling yourself up when you need a boost but also slowing down when you are overexerting.
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon"
import Draggable from "vuedraggable"
export default {
  name: 'DailyDesign',
  props: ['user'],
  components: {
    Draggable
  },
  data() {
    return {
      isBlackboxVisible: false,
      dragging: false,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      console.log('initialize')
      this.user.blueprint.forEach(group=>{
        group.priorities.forEach(priority=>{
          priority.goals.forEach(goal=>{
            if (goal.focus && !this.isSleeping(goal) && goal.frequency && goal.frequency.period == 1) {
              if (goal.actions.length && goal.actions[0].progress.finish == null) {
                goal.actions[0].progress.selected = this.calendarDate()
              }
            }
          })
        })
      })
    },
    onIntersect(entries) {
      const [entry] = entries
      if (entry.isIntersecting) {
        this.isBlackboxVisible = false
      } else {
        this.isBlackboxVisible = true
      }
    },
    resetSelected() {
      this.user.blueprint.forEach(group=>{
        group.priorities.forEach(priority=>{
          priority.goals.forEach(goal=>{
            goal.actions.forEach((action,actionIndex)=>{
              if (goal.focus && actionIndex == 0) action.progress.selected = null
            })
          })
        })
      })
      this.user.daily.todos.forEach(todo=>{
        todo.progress.selected = null
      })
      this.$forceUpdate()
    },
    calculateDeltaDays(last, start) {
      start = last ? last : start
      if (!start) return 0
      start = DateTime.fromFormat(start, 'yyyy-LL-dd')
      return Math.abs(Math.floor(start.diffNow().as('days')))
    },
    calculateAge(goal) {
      let start = DateTime.now()
      let goalStart = goal.progress.start ? DateTime.fromFormat(goal.progress.start, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
      let goalLast = goal.progress.last ? DateTime.fromFormat(goal.progress.last, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
      let actionStart = goal.actions[0].progress.start ? DateTime.fromFormat(goal.actions[0].progress.start, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
      let actionLast = goal.actions[0].progress.last ? DateTime.fromFormat(goal.actions[0].progress.last, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
      let actionStep = goal.actions[0].progress.step ? DateTime.fromFormat(goal.actions[0].progress.step, 'yyyy-LL-dd') : DateTime.fromFormat('2000-01-01', 'yyyy-LL-dd')
      // Find the most recent start date using Luxon's max() function
      let finish = DateTime.max(goalStart, goalLast, actionStart, actionLast, actionStep)
      // 
      return Math.abs(Math.floor(start.diff(finish).as('days')))
    },
    calendarDate() {
      return DateTime.now().toFormat('yyyy-LL-dd')
    },
    calendarDateYesterday() {
      return DateTime.now().minus({days: 1}).toFormat('yyyy-LL-dd')
    },
    targetColour() {
      let colour = 'grey'
      if (this.numberOfActionsSelected > 0) colour = 'orange'
      if (this.numberOfActionsSelected >= this.user.daily.target.minimum) colour = 'yellow'
      if (this.numberOfActionsSelected >= this.user.daily.target.dps) colour = 'green'
      if (this.numberOfActionsSelected > this.user.daily.target.dps + 1) colour = 'red'
      return colour
    },
    createTodo() {
      if (!this.user.daily.todos) this.user.daily.todos = []
      this.user.daily.todos.push({
        title: '',
        complete: false,
        progress: {
          start: this.calendarDate(), 
          finish: null,
          selected: null,
        },
        edit: true,
      })
      this.$forceUpdate()
      this.$emit('update')
    },
    saveTodo(todo) {
      console.log("Saving todo", todo)
      todo.edit = false
      this.$forceUpdate()
      this.$emit('update')
    },
    blurTodo(todo) {
      // if (todo.edit == true) {
      //   todo.edit = false
      //   this.$forceUpdate()
      //   this.$emit('update') 
      // }
    },
    focusTodo(todo) {
      this.user.daily.todos.forEach( item => {
        item.edit = false
      })
      todo.edit = true
    },
    deleteTodo(index) {
      this.user.daily.todos.splice(index, 1)
      this.$forceUpdate()
      this.$emit('update')
    },
    colour(index) {
      return this.$store.getters.colour(index)
    },
    isSleeping(goal) {
      if (goal.progress.snooze == undefined) return false
      let snooze = DateTime.fromFormat(goal.progress.snooze.until, 'yyyy-LL-dd')
      let now = DateTime.now()
      if (now < snooze) return true
      delete goal.progress.snooze
      this.$emit('save')
      return false
    },
  },
  computed: {
    todos() {
      if (!this.user) return false
      if (this.user.daily.todos == undefined) this.user.daily.todos = []
      return this.user.daily.todos.filter(todo=>{
        if (todo.complete) return false
        return true
      })
    },
    numberOfActionsSelected() {
      if (!this.user) return false
      let count = 0
      this.user.blueprint.forEach(group=>{
        group.priorities.forEach(priority=>{
          priority.goals.forEach(goal=>{
            goal.actions.forEach(action=>{
              if (action.progress.selected == this.calendarDate()) count++
            })
          })
        })
      })
      this.user.daily.todos.forEach(todo=>{
        if (todo.progress.selected == this.calendarDate()) count++
      })
      return count
    }
  },
  watch: {
    user() {
      this.initialize()
    }
  }
}
</script>
<style lang="scss" scoped>
  .blackbox {
    padding: 15px;
    border-radius: 5px;
    background-color: #000000;
    color: #ffffff;
    z-index: 1;
    .overline {
      line-height: normal;
      margin-bottom: 10px;
    }
  }
  .blackbar {
    background-color: #000000;
    color: white;
    padding: 15px;
    border-radius: 5px;
  }
  .item {
    border-bottom:1px solid #e3e3e3;
    background: white;
  }
  .item.selected {
    background: #ffffd5;
  }
  .todo.item {
    border-left: 3px solid #e3e3e3;
  }
  .list {
    border: 1px solid #e3e3e3;
    // border-radius: 5px;
    overflow: hidden;
  }
</style>
