<template>
  <div v-if="user" class="dashboard container">
    <div class="d-flex flex-row align-center mb-4">
      <div class="lifeos-heading lifeos-font-serif">Dashboard</div>
      <v-spacer></v-spacer>
    </div>
    <v-card dark class="mb-4 pa-2 d-flex flex-row" v-if="user.profile.roles.founder">
      <div class="flex-1 d-flex flex-row align-center">
        <v-btn icon><v-icon>mdi-crown</v-icon></v-btn>
        <div class="lifeos-font-serif ml-2">Founding Member</div>
      </div>
      <v-btn outlined :disabled="true">Founder's Forum</v-btn>
    </v-card>
    <Changelog v-if="user.profile.level == 20" />
    <!-- ---------------------- Tour ---------------------- -->
    <Tour v-if="user && user.profile && user.profile.level < 20" :user="user" @save="save" />
    <!-- ---------------------- Missions ---------------------- -->
    <v-card v-if="user && user.profile && user.profile.level < 20" class="mt-4">
      <v-card-title class="text-h5">Missions</v-card-title>
      <v-card-text>
        <Missions user="user" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Missions from '@/components/Missions'
import Tour from '@/components/Tour'
import Changelog from '@/components/Changelog'
export default {
  name: 'Dashboard',
  props: ['user'],
  components: {
    Missions,
    Tour,
    Changelog
  },
  methods: {
    save() {
      this.$emit('save')
    }
  }
}
</script>