<template>
  <v-list nav dense shaped>
    <v-list-item-group color="primary">
      <div class="text-h6 pb-2">New in v{{ log[1].version }} </div>
      <v-list-item v-for="(feature, i) in log[1].features" :key="i">
        <v-list-item-icon>
          <v-icon v-if="feature.toLowerCase().search('bug:') > -1">mdi-bug</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{feature}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <v-divider class="my-3"></v-divider>
    <v-list-item-group color="primary">
      <div class="text-h6 pb-2">Coming in v{{ log[0].version }} </div>
      <v-list-item v-for="(feature, i) in log[0].features" :key="i">
        <v-list-item-icon>
          <v-icon v-if="feature.toLowerCase().search('bug:') > -1">mdi-bug</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{feature}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  name: 'Changelog',
  data() {
    return {
      log: [
      {
        version: '1.4.7',
        features: [
          'Founder onboarding'
        ]
      },
      {
        version: '1.4.6',
        features: [
          'Major rebuild of mission tutorial system',
        ]
      },
      {
        version: '1.3.6',
        features: [
          'Daily: Debrief - Message',
          'Daily: Debrief - Metrics',
          'Daily: Debrief - Notification',
        ]
      },
      {
        version: '1.3.5', // Daily
        features: [
          'Daily: Debrief - Target',
          'Daily: Debrief - DPS',
          'Daily: Balance - Remove tones'
        ]
      },
      {
        version: '1.3.4', // Daily - Sunday
        features: [
          'Daily: Debrief - Scaffold',  
          'Daily: Balance - Tone upon balance',
          'Daily: Debrief - ChatGPT',
        ]
      },
      {
        version: '1.3.3', // Daily - Saturday
        features: [
          '✓ - Bug: Skipping to briefing instead of progress',
          '✓ - Bug: Design: Showing age of last action, not age of last goal',
          '✓ - Daily: Notifications - Enforce phone number format',
          '✓ - Daily: Notifications - Verify phone number',
        ]
      },
      {
        version: '1.3.2', // Daily
        features: [
          'Daily: Notificaitons - Twilio',  
          'Daily: Notifications - Scaffold',
          'Daily: Notifications - Frontend config update',
          'Daily: Notifications - Function',
        ]
      },
      {
          version: '1.3.1', // Daily
          features: [
            'Daily: Progress - Bug fixes: save step',
            'Daily: Progress - Balance widget: database driven',
            'Daily: Progress - Balance widget: chime',
            'Daily: Progress - Balance widget: basic config',
          ]
        },
        {
          version: '1.3.0', // Daily
          features: [
            'Daily: Progress - Focus, widget, timer',
            'Daily: Progress - Balance Widget',
            'Bug fixes'
          ]
        },
        {
          version: '1.2.9', // Daily
          features: [
            'Daily: Responsive improvements',
            'Daily: Progress - Todo list completion bug',
            'Daily: Progress - Ghost completed actions',
        ]},
        {
          version: '1.2.8', // Daily
          features: [
            'Daily: Progress - Working action list',
            'Daily: Basic navigation',
        ]},
        {
          version: '1.2.7', // Daily
          features: [
            'Daily: Todo list',
            'Daily: Responsive improvements',
            'Player: Responsive improvements',
        ]},
        {
          version: '1.0.9', // Focus
          features: [
            'Focus: Redesign focus/goal/action list UI',
            'Focus: Redesign focus/action list UI',
            'Bug: Move completed goal to bottom of list',
          ]
        },
        {
          version: '1.0.8', // Focus
          features: [
            'Focus: Add minimum action',
            'Focus: Add target action',
            'Focus: Add actions list',
          ]
        },
        {
          version: '1.0.3', // Bugs!
          features: [
            'Bug: Sign up error',
            'Bug: ',
            'Bug: ',
            'Bug: ',
          ]
        },
        {
          version: '1.0.0', // Masterpiece Template
          features: [
            'Masterpiece template editor - new template',
            'Masterpiece template editor - save template',
            'Masterpiece day - load template',
            'Bug: ',
          ]
        },
        {
          version: '0.9.9', // Masterpiece Template
          features: [
            'Masterpiece scaffolding',
            'Masterpiece template editor - core',
            'Masterpiece template editor - blocks',
            'Masterpiece template editor - schedule',
          ]
        },
        {
          version: '0.9.5', // Action
          features: [
            'Action list',
            'Bug: Focus dialog - Cannot read properties of undefined (reading visible)',
            'Bug: Cannot delete priority group',
            'Bug: Toggle focus + complete oddities',
          ]
        },
        {
          version: '0.9.4', // Goal
          features: [
            'Add progress scaffolding',
            'Progress configuration',
            'Migrate Focus data to Blueprint',
            'Bug: Delete priority group'
          ]
        },
        {
          version: '0.9.3', // Focus
          features: [
            'Migrate Focus data to Blueprint',
            'Complete overhaul of Priority data',
            'Opening Goal dialog',
          ]
        },
        {
          version: '0.9.2', // Focus
          features: [
            'Open Focus Panel',
            'Load Focus on click',
            'Restructure Data'
          ]
        },
        {
          version: '0.9.1', // Focus
          features: [
            'Bug: Cannot delete goal',
            'Fetch Focus',
            'Focus Cards'
          ]
        },
        {
          version: '0.9.0', // Vision
          features: [
            'Bug: Completing an unfocused goal clears focus',
            'Vision statement design',
            'Vision statement edit',
            'Cover art change'
          ]
        },
        {
          version: '0.8.9',
          features: [
            'Remove skeleton',
            'Cover art placeholder',
            'Bug: Error subsections on welcome page',
            'Bug: Redirect from landing when authenticated'
          ]
        },
        {
          version: '0.8.8',
          features: [
            'Focused goal options',
            'Bug: Deleting focused goal, focuses next',
            'Bug: Landing page redirect when logged in',
            'Goals: drag and drop'
          ]
        },
        {
          version: '0.8.7',
          features: [
            'Bug: Priority loading issue on mobile',
            'Change from goals to sub-priorities',
            'Add focus buttons',
            'Highlight focus'
          ],
        },
        {
          version: '0.8.6',
          features: [
            'Bug: Persistent skeleton loader',
            'Commit security rules',
            'responsive drag and drop',
            'change the submenu to appear only on logged in',
          ],
        },
        {
          version: '0.8.5',
          features: [
            'Security rules',
            'Submenu hiding?',
            'Complete goal',
            'Goal rename',
          ]
        },
        {
          version: '0.8.4',
          features: [
            'Add Goal',
            'Remove goal',
            'Cover art placeholder',
            'Increase heading size'
          ]
        },
        {
          version: '0.8.3',
          features: [
            'Load priority view',
            'Save colour change',
            'LOS flex system',
            'Responsive feedback',
          ]
        },
        {
          version: '0.8.2',
          features: [
            'Priorities drag and drop',
            'Group drag and drop',
            'Open priority view',
          ]
        },
        {
          version: '0.8.1',
          features: [
            'Save priorities to Firestore',
            'Load priorities from Firestore',
            'Add priority to group',
            'Delete priority',
            'Bug: Error in render: "TypeError: Cannot read properties of null (reading priorities)',
          ]
        },
        {
          version: '0.8.0',
          features: [
            'Sketch priorities layout',
            'Create priority group',
            'Default groups',
            'Migrate legacy accounts',
            'Delete priority group',
          ]
        },
        {
          version: '0.7.9',
          features: [
            'Rebuilt from the ground up',
            'Introduced founding members',
          ]
        },
      ]
    }
  }
}
</script>