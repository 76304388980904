<template>
  <div class="daily container" v-if="user && user.daily">
    <!-- ------ Heading ------ -->
    <div class="d-flex flex-row  align-center mb-2">
      <div class="lifeos-heading lifeos-font-serif" :class="{ 'normal': $vuetify.breakpoint.smAndUp, 'small': $vuetify.breakpoint.xsOnly }">{{ today() }}</div>
      <!-- ------ Options ------ -->
      <v-btn icon @click="configDialog = true" :style="{'border': user.profile.level == 16 ? '3px solid #ffec3b':''}"><v-icon>mdi-cog-outline</v-icon></v-btn>
      <v-btn icon @click="helpDialog = true"><v-icon>mdi-help-circle-outline</v-icon></v-btn>
    </div>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <!-- <pre>{{ user.daily }}</pre> -->
    
    <!-- ====================== Step 1: Designing Phase ====================== -->
    <DailyDesign v-if="step == 1" :user="user" @update="update"></DailyDesign>
    <!-- ====================== Step 2: Progression Phase ====================== -->
    <DailyProgress v-if="step == 2" :user="user" @update="update"></DailyProgress>
    <!-- ====================== Step 3: Debriefing Phase ====================== -->
    <DailyDebrief v-if="step == 3" :user="user" @update="update"></DailyDebrief>

    <!-- ------ Step Navigation ------ -->
    <div class="d-flex flex-row mt-3">
      <v-btn v-if="step > 1" text @click="changeStep(-1)">Previous</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="step == 1" @click="changeStep(2)" color="primary" :style="{'border': user.profile.level == 13 ? '3px solid #ffec3b !important':''}">Commit</v-btn>
      <v-btn v-if="step == 2 && user.profile.level > 14" @click="changeStep(3)" color="primary" :style="{'border': user.profile.level == 15 ? '3px solid #ffec3b !important':''}">Reflect</v-btn>
    </div>

    <!-- ------ Help Dialog ------ -->
    <v-dialog v-model="helpDialog" max-width="560">
      <v-card>
        <v-card-title>Help</v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="helpDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------ Config Dialog ------ -->
    <v-dialog v-model="configDialog" max-width="560" persistent>
      <v-card>
        <v-card-title>
          Configuration
          <v-spacer></v-spacer>
          <v-btn icon @click="update(); configDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- {{ user.daily.target }} -->
          <div class="overline">Targets</div>
          <v-list-item-title class="font-weight-bold black--text">Minimum Actions per Day</v-list-item-title>
          <v-slider
            v-model="user.daily.target.minimum"
            min="0"
            :max="user.daily.target.dps"
            thumb-label
            track-color="grey lighten-3"
          ></v-slider>
          <v-list-item-title class="font-weight-bold black--text">Target Actions per Day</v-list-item-title>
          <v-slider
            v-model="user.daily.target.dps"
            min="1"
            max="10"
            thumb-label
            @change="$forceUpdate()"
            track-color="grey lighten-3"
            :track-fill-color="user.daily.target.dps > 5 ? 'orange darken-2' : 'black'"
            :thumb-color="user.daily.target.dps > 5 ? 'orange darken-2' : 'black'"
            hide-details
          ></v-slider>
          <v-alert type="warning" v-if="user.daily.target.dps > 5" class="mt-2">
            Be careful about setting targets which are too ambitious. Completing 3 to 5 actions per day is considered high performance.
          </v-alert>
        </v-card-text>
        <!-- Configure Notifications -->
        <v-card-text>
          <!-- {{ user.daily.notifications }} -->
          <div class="overline">Notifications</div>
          <v-list>
            <v-list-item-group class="no-active-highlight">
              <!-- Morning Notification -->
              <v-list-item>
                <v-btn icon class="mr-2" @click="user.daily.notifications.morning.enabled = !user.daily.notifications.morning.enabled; $forceUpdate()">
                  <v-icon v-if="user.daily.notifications.morning.enabled">mdi-checkbox-marked</v-icon>
                  <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                </v-btn>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Morning Notification</v-list-item-title>
                  <v-list-item-subtitle class="text--primary">
                    Remind me in the morning to create my masterpiece day
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-center">
                  <v-menu ref="morningMenu" transition="slide-x-transition" :close-on-content-click="false" :nudge-width="200" :nudge-top="-40" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon v-if="user.daily.notifications.morning.enabled" color="green lighten-1">mdi-timer-check-outline</v-icon>
                        <v-icon v-else color="grey lighten-1">mdi-timer-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-time-picker color="black" format="ampm" :allowed-minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]" v-model="user.daily.notifications.morning.time"></v-time-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$refs.morningMenu.isActive = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-list-item-action-text>{{ displayTime(user.daily.notifications.morning.time) }}</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <!-- Afternoon Notification -->
              <v-list-item>
                <v-btn icon class="mr-2" @click="user.daily.notifications.afternoon.enabled = !user.daily.notifications.afternoon.enabled; $forceUpdate()">
                  <v-icon v-if="user.daily.notifications.afternoon.enabled">mdi-checkbox-marked</v-icon>
                  <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                </v-btn>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Afternoon Notification</v-list-item-title>
                  <v-list-item-subtitle class="text--primary">
                    Remind me if I am not on track to complete my masterpiece day
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-center">
                  <v-menu ref="afternoonMenu" transition="slide-x-transition" :close-on-content-click="false" :nudge-width="200" :nudge-top="-40" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon v-if="user.daily.notifications.afternoon.enabled" color="green lighten-1">mdi-timer-check-outline</v-icon>
                        <v-icon v-else color="grey lighten-1">mdi-timer-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-time-picker color="black" format="ampm" :allowed-minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]" v-model="user.daily.notifications.afternoon.time"></v-time-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$refs.afternoonMenu.isActive = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-list-item-action-text>{{ displayTime(user.daily.notifications.afternoon.time) }}</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <!-- Evening Notification -->
              <v-list-item>
                <v-btn icon class="mr-2" @click="user.daily.notifications.evening.enabled = !user.daily.notifications.evening.enabled; $forceUpdate()">
                  <v-icon v-if="user.daily.notifications.evening.enabled">mdi-checkbox-marked</v-icon>
                  <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                </v-btn>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Evening Notification</v-list-item-title>
                  <v-list-item-subtitle class="text--primary">
                    Reminder to debrief and reflect on my day
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-center">
                  <v-menu ref="eveningMenu" transition="slide-x-transition" :close-on-content-click="false" :nudge-width="200" :nudge-top="-40" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon v-if="user.daily.notifications.evening.enabled" color="green lighten-1">mdi-timer-check-outline</v-icon>
                        <v-icon v-else color="grey lighten-1">mdi-timer-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-time-picker color="black" format="ampm" :allowed-minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]" v-model="user.daily.notifications.evening.time"></v-time-picker>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$refs.eveningMenu.isActive = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-list-item-action-text>{{ displayTime(user.daily.notifications.evening.time) }}</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list-item-group>
          </v-list>
          <div class="overline">Phone Number</div>
          <div v-if="!user.profile.phone.number && !editingPhoneNumber">
            <div>In order to receive notifications you'll need to add and verify a phone number.</div>
            <v-btn outlined class="mt-2" @click="editingPhoneNumber=true">Add Phone Number</v-btn>
          </div>
          <div v-if="editingPhoneNumber">
            <div>Please enter a valid North American phone number:</div>
            <v-text-field 
              outlined autofocus hide-details
              v-model="phoneNumberField"  
              prefix="+1 " 
              v-mask="'###-###-####'" 
              class="mt-2"   
              append-outer-icon="mdi-close" 
              @click:append-outer="removePhoneNumber()" 
              append-icon="mdi-keyboard-return" 
              @input="phoneNumberError = false"
              @click:append="saveNewPhoneNumber()"
              @keydown.enter="saveNewPhoneNumber()" 
              :error="phoneNumberError"
              maxlength="48">
            </v-text-field>
            <v-chip color="red" dark v-if="phoneNumberError && phoneNumberErrorMessage" class="mt-2">{{ phoneNumberErrorMessage }}</v-chip>
          </div>
          <div v-if="user.profile.phone.number && !editingPhoneNumber">
            <div>Notifications will be sent to this number (once verified)</div>
            <div class="d-flex flex-row align-center">
              <div class="text-h6 flex-1" @click="editingPhoneNumber=true">{{ formattedPhoneNumber }}</div>
              <v-chip v-if="user.profile.phone.verified" color="green" dark>Verified</v-chip>
              <v-chip v-else color="orange" outlined>Unverified</v-chip>
            </div>
            <!-- <v-btn @click="editingPhoneNumber=true" class="mt-2" outlined>Change Phone Number</v-btn> -->
            <v-alert v-if="!user.profile.phone.verified && !sendingVerificationCodeError" type="warning" prominent border="left" class="mt-3">
              <div class="font-weight-bold">Verification Required</div>
              <div v-if="!awaitingPin">
                <div>In order to subscribe to SMS notifications we first need to verify your phone number.</div>
                <div class="d-flex flex-row justify-end mt-2">
                  <v-btn text dark outlined :disabled="sendingVerificationCode" :loading="sendingVerificationCode" @click="sendVerificationCode()">Send Verification Code</v-btn>
                </div>
              </div>
              <div v-else>
                <!-- Awaiting PIN -->
                <div class="mb-3">A 6-digit verification code has been sent to {{ formattedPhoneNumber }}. Enter it below to verify:</div>
                <v-otp-input v-model="userPin" light color="orange darken-4" class="mt-3" length="6" @finish="verifyUserPin"></v-otp-input>
                <v-chip v-if="invalidPinError" tag color="orange darken-3">The code you entered is invalid</v-chip>
              </div>
            </v-alert>
            <v-alert v-if="sendingVerificationCodeError" type="error" class="mt-3">
              <div class="font-weight-bold">Verification Error</div>
              <div>{{ sendingVerificationCodeError }}</div>
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="saveConfig()">Save & Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </div>
</template>
<script>
  import { DateTime } from "luxon"
  import {mask} from 'vue-the-mask'
  import axios from 'axios'
  import DailyDesign from "@/components/DailyDesign.vue"
  import DailyProgress from "@/components/DailyProgress.vue"
  import DailyDebrief from "@/components/DailyDebrief.vue"
  export default {
    name: 'Progress',
    props: ['user'],
    components: {
      DailyDesign,
      DailyProgress,
      DailyDebrief,
    },
    directives: {mask},
    data() {
      return {
        loading: false,
        step: 0,
        helpDialog: false,
        configDialog: false,

        editingPhoneNumber: false,
        newPhoneNumber: '',
        phoneNumberError: false,
        phoneNumberErrorMessage: '',
        sendingVerificationCode: false,
        sendingVerificationCodeError: false,
        verificationPin: null,
        userPin: null,
        awaitingPin: false,
        invalidPinError: false,
        invalidPinAttempts: 0,
      }
    },
    created() {
      // ------------ Initialize ------------
      this.initialize()
    },
    methods: {
      initialize() {
        if (this.user && this.user.daily == undefined) {
          this.user.daily = {
            todos: [],
            design: {
              last: null, // user.daily.design.last
            },
            progress: {
              last: null, // user.daily.progress.last
              widgets: {}
            },
            debrief: {
              last: null, // user.daily.debrief.last
            },
            notifications: {
              sms: null,
              verified: false,
              morning: {
                enabled: false,
                time: '07:00',
              },
              afternoon: {
                enabled: false,
                time: '14:00',
              },
              evening: {
                enabled: false,
                time: '21:00',
              },
            },
            target: {
              minimum: 0,
              dps: 3,
              last: null,
            },
          }
        }
        // ------------ Set Phone ------------
        // this.maskedPhoneNumber = this.user.profile.phone.number
        // ------------ Determine Step ------------
        if (this.user && this.step == 0) {
          console.log('Determining step', this.user.daily)
          this.step = 1
          if (this.user && this.user.daily.design.last == this.calendarDate()) this.step = 2
          if (this.user && this.user.daily.progress.last == this.calendarDate()) this.step = 3
          console.log('Step: ' + this.step)
        }
        this.loading = false
      },
      calendarDate() {
        return DateTime.now().toFormat('yyyy-LL-dd')
      },
      today() {
        return DateTime.now().toFormat('cccc, LLLL d') // Tuesday March 8 or 'DDDD' includes year
      },
      displayTime(time) {
        if (time.length == 4) time = '0' + time; // Add leading zero, if needed
        return DateTime.fromFormat(time, 'HH:mm').toFormat('h:mm a ZZZZ')
      },
      update() {
        this.$emit('save')
      },
      changeStep(step) {
        switch (step) {
          case 2: 
            this.user.daily.design.last = this.calendarDate()
            if (this.user.daily.progress.last == this.calendarDate()) this.user.daily.progress.last = null
            // this.user.daily.progress.last = null
            this.step = 2
            this.update()
            break;
          case 3:
            this.user.daily.progress.last = this.calendarDate()
            this.step = 3
            this.update()
            break;
          case -1: // Previous step
            this.step--
            if (this.step == 2) this.user.daily.progress.last = null
            if (this.step == 1) this.user.daily.design.last = null
            this.update()
            break;
        }
      },
      saveConfig() {
        this.configDialog = false
        this.editingPhoneNumber = false
        this.update()
        if(this.user.profile.level == 16 && this.user.profile.phone.number) {
          this.user.profile.level = 17
          this.user.profile.missions.push('mission-g')
          this.update()
          this.go('Dashboard')
        }
      },
      saveNewPhoneNumber() {
        if (this.newPhoneNumber == this.user.profile.phone.number) {
          this.editingPhoneNumber = false
          return
        }
        if (this.newPhoneNumber) {
          this.phoneNumberError = false
          this.phoneNumberErrorMessage = ''
          this.editingPhoneNumber = false
          this.user.profile.phone.number = this.newPhoneNumber
          this.user.profile.phone.verified = false
        } else {
          this.phoneNumberError = true
          this.phoneNumberErrorMessage = 'Please enter a valid US or CA phone number'
        }
      },
      removePhoneNumber() {
        this.editingPhoneNumber = false
        this.user.profile.phone.number = null
        this.user.profile.phone.verified = false
        this.update()
      },
      async sendVerificationCode() {
        this.sendingVerificationCode = true
        this.sendingVerificationCodeError = false
        // Generate verification code
        this.verificationPin = Math.floor(100000 + Math.random() * 900000)
        // Send verification code
        const endpoint = 'https://us-central1-life-os-app.cloudfunctions.net/sendVerificationCode'
        try {
          const response = await axios.post(endpoint, {
            phoneNumber: this.user.profile.phone.number,
            verificationCode: this.verificationPin,
          });
          if (response.data.success) {
            this.awaitingPin = true
          } else {
            this.sendingVerificationCodeError = "We've encountered an error attempting to send you the verification code via SMS. Please try again later. (2)"
          }
        } catch (error) {
          this.sendingVerificationCodeError = "We've encountered an error attempting to send you the verification code via SMS. Please try again later. (1)"
        }
      },
      verifyUserPin() {
        this.invalidPinError = false
        if (this.userPin == this.verificationPin) {
          this.sendingVerificationCode = false
          this.awaitingPin = false
          this.user.profile.phone.verified = true
          this.update()
        } else {
          this.invalidPinError = true
          this.invalidPinAttempts++
          if (this.invalidPinAttempts >= 3) {
            this.invalidPinAttempts = 0
            this.sendingVerificationCodeError = 'Too many attempts. Please try again later.'
            this.verificationPin = null
          }
        }
      },
    },
    watch: {
      user() {
        this.initialize()
      },
    },
    computed: {
      formattedPhoneNumber() {
        return this.user.profile.phone.number ? this.user.profile.phone.number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4') : ''
      },
      phoneNumberField: {
        get() {
          // get last 10 digits of phone number
          return this.user.profile.phone.number ? this.user.profile.phone.number.slice(-10) : ''
        },
        set(value) {
          // remove all non-numeric characters
          this.newPhoneNumber = null
          if (value.length == 12)
            this.newPhoneNumber = '+1' + value.replace(/\D/g,'')
        }
      }
    }
  }
</script>
<style scoped>
  .no-active-highlight .theme--light.v-list-item--active::before {
    opacity: 0;
  }
  .no-active-highlight .v-list-item--active:hover::before {
    opacity: 0.04 !important;
  }
  .v-slider__tick--filled {
    background-color: black !important;
  }
</style>