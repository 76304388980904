<template>
  <div class="balance widget ml-0 ml-sm-2">
    <audio ref="balance" @ended="soundEnded"></audio>
    <div class="widget header d-flex flex-row align-center pl-2 mb-1" style="border-bottom: 1px solid lightgrey">
      <div class="caption flex-1">Balance Timer</div>
      <div class="controls">
        <v-btn icon @click="configDialog = true">
          <v-icon small>mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="clock" class="widget body pa-2">
      <div class="timers d-flex flex-row">
        <div class="yin-timer flex-1 text-center">
          <v-progress-circular rotate="-90" :value="yinSessionPercentage" size="75" :color="yin.colour">
            <span class="caption black--text">{{ yinTime }}</span>
          </v-progress-circular>
          <div class="controls">
            <v-btn icon>
              <v-icon v-if="yin.active" @click="pause('yin')">mdi-pause</v-icon>
              <v-icon v-else @click="play('yin')">mdi-play</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon @click="stop('yin')" :disabled="!yin.active">mdi-stop</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="yang-timer flex-1 text-center">
          <v-progress-circular rotate="-90" :value="yangSessionPercentage" size="75" :color="yang.colour">
            <span class="caption black--text">{{ yangTime }}</span>
          </v-progress-circular>
          <div class="controls">
            <v-btn icon>
              <v-icon v-if="yang.active" @click="pause('yang')">mdi-pause</v-icon>
              <v-icon v-else @click="play('yang')">mdi-play</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon @click="stop('yang')" :disabled="!yang.active">mdi-stop</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row my-2">
        <div class="flex-1 text-center">
          <v-btn icon><!-- @click="yang.elapsed.total = 0; yin.elapsed.total = yin.target">-->
            <v-icon :color="yin.colour">
              {{ yin.icon }}
            </v-icon>
          </v-btn>
        </div>
        <div class="flex-1 text-center">
          <v-btn icon><!--@click="yin.elapsed.total = 0; yang.elapsed.total = yang.target">-->
            <v-icon :color="yang.colour">
              {{ yang.icon }}
            </v-icon>
            </v-btn>
        </div>
      </div>
      <div class="balance beam d-flex flex-row">
        <v-progress-linear class="flex-1" :value="yinQi"  :color="yin.colour" reverse></v-progress-linear>
        <v-progress-linear class="flex-1" :value="yangQi" :color="yang.colour"></v-progress-linear>
      </div>
      <div class="text-center mt-2 caption">
        <v-btn icon @click="balance()"><v-icon>mdi-yin-yang</v-icon></v-btn><br>
        <!-- <div>{{ yangQi.toFixed(0) }}</div> -->
      </div>
      <!-- New Elements -->
      <!-- <div class="balance beam d-flex flex-row">
        <v-progress-linear class="flex-1" reverse :value="yinQi" color="yellow"></v-progress-linear>
        <v-progress-linear class="flex-1"         :value="yangQi" color="green"></v-progress-linear>
      </div> -->
    </div>
    <v-dialog v-model="configDialog" max-width="560" persistent>
      <v-card>
        <v-card-title>Balance Configuration</v-card-title>
        <v-card-text>
          <div class="overline">Yin Timer</div>
          <div class="d-flex flex-row">
            <div v-for="(category, index) in categories" :key="'category'+index">
              <v-btn v-if="category.name != 'Yang'" @click="changeCategory('yin', index)" icon :disabled="category.icon == yang.icon" :style="{'border': yin.icon == category.icon ? '1px solid black' : 'none' }">
                <v-icon :color="category.colour">{{ category.icon }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex flex-row align-center">
            <v-icon>mdi-timer-outline</v-icon>
            <v-slider v-model="yin.target" step="300" min="300" max="7200" hide-details></v-slider>
            <div class="caption">{{ yin.target / 60 }} mins.</div>
          </div>
          <v-divider class="my-4"></v-divider>
          <div class="overline">Yang Timer</div>
          <div class="d-flex flex-row">
            <div v-for="(category, index) in categories" :key="'category'+index">
              <v-btn v-if="category.name != 'Yin'" @click="changeCategory('yang', index)" icon :disabled="category.icon == yin.icon" :style="{'border': yang.icon == category.icon ? '1px solid black' : 'none' }">
                <v-icon :color="category.colour">{{ category.icon }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex flex-row align-center">
            <v-icon>mdi-timer-outline</v-icon>
            <v-slider v-model="yang.target" step="300" min="300" max="7200" hide-details></v-slider>
            <div class="caption">{{ yang.target / 60 }} mins.</div>
          </div>
          <!-- <v-divider class="my-4"></v-divider>
          <div class="overline"><v-icon class="mr-2">mdi-volume-source</v-icon>Sound Effect</div>
          <v-btn>
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="configDialog = false; save()" text>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { DateTime } from "luxon"
export default {
  name: 'BalanceTimer',
  props: ['user'],
  data() {
    return {
      configDialog: false,
      clock: null,
      yin: {
        active: false,
        target: 7200,
        elapsed: {
          current: 0,
          session: 0,
          total: 0
        },
        moment: null,
        icon: 'mdi-circle-outline',
        colour: 'black',
        sound: 1
      },
      yang: {
        active: false,
        target: 3600,
        elapsed: {
          current: 0,
          session: 0,
          total: 0
        },
        moment: null,
        icon: 'mdi-circle-slice-8',
        colour: 'black',
        sound: 1
      },
      sounds: [
        'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Feffects%2Fnotification1.mp3?alt=media&token=2b4d2e72-690c-4c55-b810-f82d1cb3af6f',
        'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Feffects%2Fnotification2.mp3?alt=media&token=dabbc758-522f-4459-83f3-1287326729fb',
        'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Feffects%2Fnotification3.mp3?alt=media&token=da95c3c3-3379-4310-9614-73249a2bb22f',
        'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Feffects%2Fnotification4.mp3?alt=media&token=0d042170-3aee-4b74-bdbe-11182b0a5746',
        'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Feffects%2FChinese-gong-hit.mp3?alt=media&token=ab37f9c4-4ce2-4ce2-a33f-dfb5bff4047c',
        'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Feffects%2FChinese-gong-sound.mp3?alt=media&token=f149b20c-73e0-4b4f-8ca3-8f93414a82a6',
        'https://firebasestorage.googleapis.com/v0/b/life-os-app.appspot.com/o/resources%2Faudio%2Feffects%2FMeditation-Bell.mp3?alt=media&token=7b4e8ecc-9328-4168-b58a-cc741d268a65'
      ],
      categories: [
        {
          name: 'Yin',
          icon: 'mdi-circle-outline',
          colour: 'black',
        },
        {
          name: 'Yang',
          icon: 'mdi-circle-slice-8',
          colour: 'black',
        },
        {
          name: 'Mind',
          icon: 'mdi-brain',
          colour: 'pink lighten-3',
        },
        {
          name: 'Body',
          icon: 'mdi-run',
          colour: 'blue darken-2',
        },
        {
          name: 'Spirit',
          icon: 'mdi-meditation',
          colour: 'yellow darken-3',
        },
        // {
        //   name: 'Judo',
        //   icon: 'mdi-kabaddi',
        //   colour: 'red',
        // },
        {
          name: 'Family',
          // icon: 'mdi-mother-nurse',
          icon: 'mdi-human-male-female-child',
          colour: 'green',
        },
        // {
        //   Name: 'Wood',
        //   icon: 'mdi mdi-pine-tree-variant-outline',
        //   colour: 'green',
        // },
        {
          Name: 'Fire',
          icon: 'mdi mdi-fire',
          colour: 'red',
        },
        // {
        //   Name: 'Earth',
        //   icon: 'mdi mdi-earth',
        //   colour: 'yellow darken-3',
        // },
        // {
        //   Name: 'Metal',
        //   icon: 'mdi mdi-hammer',
        //   colour: 'blue-grey lighten-2',
        // },
        // {
        //   Name: 'Water',
        //   icon: 'mdi mdi-water-outline',
        //   colour: 'black',
        // },
      ]
    }
  },
  created() {
    // ------------ Initialize the timer ------------
    this.clock = setInterval(() => {
      this.tick()
    }, 1000)
    // ------------ Initialize the data ------------
    this.initialize()
  },
  // ------------ Destroy the timer ------------
  beforeDestroy() {
    clearInterval(this.clock)
  },
  methods: {
    initialize() {
      // delete this.user.daily.progress.widgets.balance
      // ------------ Create widget data if it doesnt exist ------------
      if (this.user && this.user.daily.progress.widgets.balance == null) {
        this.user.daily.progress.widgets.balance = {
          yin: this.yin,
          yang: this.yang
        }
        this.save()
      }
      // ------------ Attempt to load widget data ------------
      if (this.user && this.user.daily.progress.widgets.balance) {
        this.yin = this.user.daily.progress.widgets.balance.yin
        this.yang = this.user.daily.progress.widgets.balance.yang
      }
    },
    play(timerName) {
      if (timerName == 'yin') {
        this.stop('yang', false)
        this.yin.active = true
        this.yin.moment = DateTime.local().toISO()
      }
      if (timerName == 'yang') {
        this.stop('yin', false)
        this.yang.active = true
        this.yang.moment = DateTime.local().toISO()
      }
      this.save()
    },
    pause(timerName) {
      if (timerName == 'yin') {
        this.yin.active = false
        this.yin.elapsed.session += this.yin.elapsed.current
        this.yin.elapsed.current = 0
      }
      if (timerName == 'yang') {
        this.yang.active = false
        this.yang.elapsed.session += this.yang.elapsed.current
        this.yang.elapsed.current = 0
      }
      this.save()
    },
    stop(timerName, save=true) {
      if (timerName == 'yin') {
        this.yin.active = false
        this.yin.elapsed.session += this.yin.elapsed.current
        this.yin.elapsed.total += this.yin.elapsed.session
        this.yin.elapsed.current = 0
        this.yin.elapsed.session = 0
        this.yin.moment = null
      }
      if (timerName == 'yang') {
        this.yang.active = false
        this.yang.elapsed.session += this.yang.elapsed.current
        this.yang.elapsed.total += this.yang.elapsed.session
        this.yang.elapsed.current = 0
        this.yang.elapsed.session = 0
        this.yang.moment = null
      }
      if (save) this.save()
    },
    tick() {
      if (this.yin.active) {
        this.yin.elapsed.current = Math.round(DateTime.local().diff(DateTime.fromISO(this.yin.moment), 'seconds').seconds)
        if ((this.yin.elapsed.session + this.yin.elapsed.current) % this.yin.target == 0) {
          let gong = (this.yin.elapsed.session + this.yin.elapsed.current) / this.yin.target
          if (gong == 1 || gong == 2) {
            this.$refs.balance.src = this.sounds[0]
            this.$refs.balance.play()
          } 
        // else if(gong > 2) {
        //     this.$refs.balance.src = this.sounds[0]
        //     this.$refs.balance.play()
        //   }
        }
        //
        // if (this.yinQi == 0) {
        //   this.$refs.balance.src = this.sounds[6]
        //   this.$refs.balance.play()
        // }
      }
      if (this.yang.active) {
        this.yang.elapsed.current = Math.round(DateTime.local().diff(DateTime.fromISO(this.yang.moment), 'seconds').seconds)
        if (this.yang.elapsed.session + this.yang.elapsed.current % this.yang.target == 0) {
          let gong = (this.yang.elapsed.session + this.yang.elapsed.current) / this.yang.target
          if (gong == 1 || gong == 2) {
            this.$refs.balance.src = this.sounds[0]
            this.$refs.balance.play()
          }
        // else if(gong > 2) {
        //     this.$refs.balance.src = this.sounds[0]
        //     this.$refs.balance.play()
          // }
        }
      }
    },
    save() {
      // ------------ Create the timer ------------
      this.user.daily.progress.widgets.balance = {
        yin: this.yin,
        yang: this.yang
      }
      this.$emit('update')
    },
    formatTime: function (value) {
      const sign = value < 0 ? "-" : "";
      const absSeconds = Math.abs(value);
      const hours = Math.floor(absSeconds / 3600);
      const minutes = Math.floor((absSeconds - (hours * 3600)) / 60);
      const remainingSeconds = absSeconds - (hours * 3600) - (minutes * 60);
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
      return `${sign}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    soundEnded() {
      this.$refs.balance.pause()
    },
    changeCategory(type, index) {
      if (type == 'yin') {
        this.yin.icon = this.categories[index].icon
        this.yin.colour = this.categories[index].colour
      }
      if (type == 'yang') {
        this.yang.icon = this.categories[index].icon
        this.yang.colour = this.categories[index].colour
      }
    },
    balance() {
      this.yin.active = false
      this.yang.active = false
      this.yin.elapsed.session = 0
      this.yang.elapsed.session = 0
      this.yin.elapsed.current = 0
      this.yang.elapsed.current = 0
      // this.yang.target = 10
      // this.yin.target = 10
      this.yin.moment = null
      this.yang.moment = null
      this.yin.elapsed.total = 0
      this.yang.elapsed.total = 0
      this.$refs.balance.src = this.sounds[4]
      this.$refs.balance.play()
      this.$emit('update')
    }
  },
  computed: {
    yinSessionPercentage() {
      return 100 * ((this.yin.target - (this.yin.elapsed.current + this.yin.elapsed.session)) / this.yin.target)
    },
    yangSessionPercentage() {
      return 100 * ((this.yang.target - (this.yang.elapsed.current + this.yang.elapsed.session)) / this.yang.target)
    },
    yinQi() {
      // First we calculate the ratio of the opposite timer
      const ratio = this.yin.target / this.yang.target
      const yinAmount = this.yin.elapsed.total + this.yin.elapsed.session + this.yin.elapsed.current
      const yangAmount = this.yang.elapsed.total + this.yang.elapsed.session + this.yang.elapsed.current
      const counterBalance = yangAmount * ratio // adjusted for the ratio
      const delta = yinAmount - counterBalance
      const magnitude = delta / this.yin.target 
      return (magnitude * 100) / 2
    },
    yangQi() {
      const ratio = this.yang.target / this.yin.target
      const yinAmount = this.yin.elapsed.total + this.yin.elapsed.session + this.yin.elapsed.current
      const yangAmount = this.yang.elapsed.total + this.yang.elapsed.session + this.yang.elapsed.current
      const counterBalance = yinAmount * ratio // adjusted for the ratio
      const delta = yangAmount - counterBalance
      const magnitude = delta / this.yang.target 
      return (magnitude * 100) / 2
    },
    yinTime() {
      return this.formatTime(this.yin.target - this.yin.elapsed.current - this.yin.elapsed.session)
    },
    yangTime() {
      return this.formatTime(this.yang.target - this.yang.elapsed.current - this.yang.elapsed.session)
    },
  },
  filters: {
    // formatTime: function (value) {
    //   const sign = value < 0 ? "-" : "";
    //   const absSeconds = Math.abs(value);
    //   const hours = Math.floor(absSeconds / 3600);
    //   const minutes = Math.floor((absSeconds - (hours * 3600)) / 60);
    //   const remainingSeconds = absSeconds - (hours * 3600) - (minutes * 60);
    //   const formattedHours = hours.toString().padStart(2, "0");
    //   const formattedMinutes = minutes.toString().padStart(2, "0");
    //   const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
    //   return `${sign}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    // },
  },
  watch: {
    user() {
      this.initialize()
    }
  }
}
</script>
<style scoped>
  .balance.widget {
    border-radius: 5px;
    background: white;
  }
</style>