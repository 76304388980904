<template>
  <v-sheet class="feedback-player pa-4" :color="feedback.sent ? 'green' : feedback.sent === null ? 'red' : ''" dark>
    <div v-if="feedback.sent">
      <div class="d-flex flex-column flex-sm-row">
        <div class="overline d-flex align-center"><v-icon class="mr-2">mdi-check-circle-outline</v-icon>Feedback</div>
        <div class="flex-1 mt-3 mt-sm-0 ml-0 ml-sm-3 font-italic d-flex align-center">Thank you for your feedback!</div>
        <div class="mt-4 mt-sm-0" @click="feedback.sent = false"><v-btn outlined>More Feedback</v-btn></div>
      </div>
    </div>
    <div v-if="feedback.sent === null" class="pa-2">
      <div class="d-flex flex-column flex-sm-row">
        <div class="overline d-flex align-center"><v-icon class="mr-2">mdi-cancel</v-icon>Feedback</div>
        <div class="flex-1 mt-3 mt-sm-0 ml-0 ml-sm-3 font-italic d-flex align-center">There was a problem sending your feedback</div>
        <div class="mt-4 mt-sm-0" @click="feedback.sent = false"><v-btn outlined>Try Again</v-btn></div>
      </div>
    </div>
    <div v-if="feedback.sent === false" class="overline grey--text">{{ feedback.types[this.feedback.type].name }}</div>    
    <div v-if="feedback.sent === false" class="d-flex flex-column flex-sm-row align-center">
      <v-textarea v-model="feedback.text" outlined hide-details class="w100" maxlength="244" :disabled="sending"></v-textarea>
      <div class="ml-0 mt-4 ml-sm-4 mt-sm-0 d-flex flex-row flex-sm-column align-center">
        <v-btn-toggle v-model="feedback.type">
          <v-btn v-for="(type,index) in feedback.types" :key="'feedback' + index" :disabled="sending">
            <v-icon>{{ type.icon }}</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-btn large class="ml-4 ml-sm-0 mt-0 mt-sm-4" :loading="sending" @click="sendFeedback()">Send</v-btn>
      </div>
    </div>
  </v-sheet>
</template>
<script>
import axios from 'axios'
export default {
  name: 'FeedbackPlayer',
  props: ['email'],
  data () {
    return {
      feedback: {
        text: '',
        type: 0,
        types: [
          {name: 'General comments', icon: 'mdi-chat-outline'},
          {name: 'Report a bug', icon: 'mdi-bug-outline'},
          {name: 'Share an idea', icon: 'mdi-lightbulb-on-outline'},
          {name: 'Ask a question', icon: 'mdi-help'},
        ],
        sent: false,
      },
      sending: false,
    }
  },
  methods: {
    sendFeedback() {
      if (this.feedback.text.length > 0) {
        this.sending = true
        let url = 'https://us-central1-life-os-app.cloudfunctions.net/sendEmail'
        axios.post(url, {
          email: 'feedback@lifeos.app',
          subject: 'Life OS Feedback Received',
          text: '',
          html: 'We received the following "' + this.feedback.types[this.feedback.type].name + '" from ' + this.email + ' on page ' + this.$route.path + '<br><br><strong>' + this.feedback.text + '</strong>',
          tag: 'Feedback',
        }).then( response => {
          this.sending = false
          this.feedback.text = ''
          this.feedback.type = 0
          this.feedback.sent = true
        }).catch( error => {
          this.sending = false
          this.feedback.sent = null
        })
      }
    }
  },
}
</script>
