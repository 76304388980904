<template>
  <v-layout column>
    <div class="hero" style="padding-top: 100px">
      <v-layout column class="align-center">
        <v-container class="text-center">
          <h1 class="lifeos-font-serif d-flex d-sm-none justify-center">Operating System for Life</h1>
          <h1 class="lifeos-font-serif heroic-title d-none d-sm-flex justify-center">Operating System for Life</h1>
          <div class="text-h5 my-2 lifeos-font-sans">
            Intuitive tools and inspiring people to help you along your heroic journey
          </div>
          <v-btn x-large rounded color="primary" class="px-5 mt-4" @click="go('Welcome')">Get Started</v-btn>
          <h4 class="caption mt-2">(it's completely free, seriously)</h4>
        </v-container>
      </v-layout>
      <v-img src="@/resources/images/heroes/hiker2.png" light></v-img>
      <v-sheet color="black text-center py-4 title font-weight-light" dark>
        <!-- <div class="py-2">We believe technology can help humans flourish.</div>
        <div class="py-2">We create intuitive tools and connect you with inspiring people to help you along your heroic journey.</div> -->
        <div class="py-2">There is no angle or program to buy into. Simply use the tools that work for you.</div>
        <!-- Built from passion for our fellow humans, Life OS is a completely free set of tools to help optimize your life. -->
        <v-container>
        <div class="d-flex flex-column flex-sm-row">
          <v-card v-for="feature in features" :key="feature.title" class="d-flex flex-column mx-2">
            <v-card-title class="justify-center thin-grey-line">{{ feature.title }}</v-card-title>
            <v-card-text class="flex-1">
              {{ feature.description }}
            </v-card-text>
          </v-card>
        </div>
      </v-container>
      </v-sheet>
    </div>
    <!-- ------------ Blocks ------------ -->
    <div class="d-flex" v-for="(block, index) in blocks" :key="'block' + index" :class="(index % 2 == 0 ? 'flex-row' : 'flex-row-reverse')">
      <!-- <div class="flex-1 wip"> -->
        <v-img class="white--text align-center text-center flex-1 image-row" :src="block.image" light max-height="300">
          <!-- <img width="100" src="@/resources/images/logos/icon-white.png"><br>
          Let's Go. -->
        </v-img>
      <!-- </div> -->
      <v-sheet class="flex-1" color="grey lighten-2 px-2 py-4">
        <h2 class="lifeos-font-serif text-center">{{ block.title }}</h2>
        <div class="lifeos-font-sans pa-3">
          {{ block.description }}
        </div>
      </v-sheet>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'Landing',
  data() {
    return {
      features: [
        {
          title: 'Priorities',
          description: 'Helping you determine priorities in your life so that you can focus on the things that matter.',
          icon: 'mdi-format-list-bulleted-type',
          link: 'Features',
        },
        {
          title: 'Goals',
          description: 'Set productive goals based on your priorities to keep you on your optimal path.',
          icon: 'mdi-calendar-clock',
          link: 'Features',
        },
        {
          title: 'Progress',
          description: 'Intelligent daily planner to help you win each day while keeping focus on what truly matters.',
          icon: 'mdi-calendar-star',
          link: 'Features',
        },
        {
          title: 'Inspiration',
          description: 'You\'re not alone. Meet amazing people like yourself to stay motivated and accountable.',
          icon: 'mdi-account-multiple',
          link: 'Features',
        },
      ],
      blocks: [
        {
          title: 'Clarity of Priorities',
          image: 'https://source.unsplash.com/random/?nature&a=1',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc.'
        },
        {
          title: 'Focus on Goals',
          image: 'https://source.unsplash.com/random/?nature&b=1',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc.'
        },
        {
          title: 'Daily Progress',
          image: 'https://source.unsplash.com/random/?nature&c=1',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc.'
        },
        {
          title: 'Be Inspired',
          image: 'https://source.unsplash.com/random/?nature&d=1',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc. Sed euismod, diam vitae aliquam tincidunt, nunc nisl ultricies nunc, nec aliquam nisl nisl vitae nunc.'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .h100 {
    height: 100%;
  }
  .hero {
    background-color: #AFD2EC;
  }
  .hero-head {
    height: 160px;
    background: #AFD2EC;
  }
  .hero-tail {
    padding: 40px 0;
    background: #000; /* #230545; */
  }
  .thin-grey-line {
    border-bottom: 1px solid #353535
  }
</style>